import React from 'react'
import { DatePicker } from "antd";
import "moment/locale/ka";

const locale = {
    lang: {
      locale: "ka",
      placeholder: "აირჩიეთ თარიღი",
      yearFormat: "YYYY",
      dateFormat: "D M YYYY",
      dayFormat: "D",
      dateTimeFormat: "D M YYYY HH:mm:ss",
    },
  };
const YearMonthPicker = () => {
    return (
        <DatePicker  locale={locale} picker="month" placeholder="წელი / თვე"/>
    )
}

export default YearMonthPicker
