import React from 'react'
// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Footer from "../Lcomponents/Footer/Footer";
import Slider from "../Lcomponents/Slider/Slider";
import Contactt from "../Lcomponents/Contact/Contact";

const Contact = () => {
    return (
        <div>
        <Navbar />
            <Menu />
            <Slider/>
            <Contactt />
            <Footer/>
        </div>
    )
}

export default Contact
