import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 450px;
  transition: 400ms;
  max-width: 100%;
  height: 450px;
  height: ${(p) => (p.forgotPassword ? "590px" : "450px")};
  box-shadow: 0px 0px 2px 0px rgba(127, 132, 138, 1);
  background-color: rgba(255, 255, 255, 0.456);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  @media (max-width: 716px) {
    width: 500px;
  }
  @media (max-width: 523px) {
    width: 400px;
  }
  @media (max-width: 480px) {
    .ant-steps-horizontal.ant-steps-label-horizontal {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: row;
      .ant-steps-item-wait
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title::after {
        display: none;
      }
    }
    .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical)
      .ant-steps-item {
      /* padding-top: 4px; */
      width: 30px;
    }
    .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical)
      .ant-steps-item {
      /* padding-top: 4px; */
      width: 0px;
    }
    @media (max-width: 480px) {
      .ant-steps-horizontal.ant-steps-label-horizontal
        > .ant-steps-item:not(:last-child)
        > .ant-steps-item-container
        > .ant-steps-item-tail {
        display: none;
      }
    }

    .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation)
      .ant-steps-item
      .ant-steps-item-icon {
      position: relative;
      margin-left: 10px;
    }
    .ant-select-selection-search-input {
      padding: 20px;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: none;
      border-right-width: 1px !important;
    }
  }
  @media (max-width: 421px) {
    width: 340px;
  }
  @media (max-width: 350px) {
    width: 310px;
    margin-right: 10px;
    margin-left: 10px;
    .ant-radio-group {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      -webkit-font-feature-settings: "tnum";
      font-feature-settings: "tnum", "tnum";
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      font-size: 0;
      line-height: unset;
      /* flex-direction: column; */
      /* align-items: center; */
    }
  }
`;

export const DisplayFlex = styled.div`
  display: flex;
  justify-content: ${(p) => (p.center ? "center" : "space-between")};
  align-items: center;
  font-family: "BOG 2018";
`;

export const StyledPasswordForgotContainer = styled.div`
  width: 255px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "BOG 2018";
  .ant-checkbox-wrapper {
    span {
      font-size: 12px;
      color: #00418f;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00418f;
    border-color: #00418f;
  }
`;
export const StyledFormContainer = styled.div`
  width: 400px;
  max-width: 100%;
  @media (max-width: 523px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .step__box {
    display: "flex";
    align-items: "center";
    justify-content: "center";
    flex-direction: "column";
    padding-top: "40px";
    padding-bottom: "20px";
    width: 100%;

    .notification {
      p {
        font-size: 13px;
        color: #7f848a;
        line-height: 25px;
        font-family: "BOG 2018";
        margin-top: -15px;
        strong {
          color: #7f848a;
        }
      }
    }
  }
  .radio__buttons {
    padding-top: 57px;
    padding-bottom: 57px;
  }
  label {
    font-family: "BOG 2018";
    font-size: 17px;
    color: #2885ed;
    font-weight: 400;
  }

  input {
    &::placeholder {
      font-family: "BOG 2018";
      font-size: 12px;
    }
  }
  .ant-radio-wrapper {
    span {
      font-family: "BOG 2017 Headline";
      font-size: 10px;
      color: #00418f;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #00418f !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #00418f;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #00418f;
  }
  .hcIWff label {
    font-family: "BOG 2018";
    font-size: 17px;
    color: #00418f;
    font-weight: 400;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background: #00418f;
    border-color: #00418f;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: green;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #00418f;
  }
  .ant-steps-item-title {
    font-family: "BOG 2017 Headline";
    font-size: 13px;
  }
  .steps-action {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .step__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ant-message-notice-content {
    font-family: "BOG 2018";
  }
  .ant-message-custom-content .ant-message-success {
    span {
      font-family: "BOG 2018";
    }
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    font-size: 12px;
  }
  .ant-btn {
    font-family: "BOG 2017 Headline";
    font-weight: 400;
    background-color: #eb0028;
    color: #fff;
    border-radius: 5px;
    position: relative;
    transition: 500ms;
    box-shadow: 0px 4px 5px 0px rgba(255, 165, 158, 1);
    border: 0;
    cursor: pointer;
    font-size: 13px;
    width: 150px;
    height: 35px;
    margin: 8px;
    margin-top: 20px;
    @media (max-width: 350px) {
      width: 100px;
    }
    &:hover {
      transition: 500ms;
      opacity: 0.9;
    }
    &:focus {
      outline: none;
    }
  }
`;

export const StyledButton = styled.button`
  font-family: "BOG 2017 Headline";
  font-weight: 400;
  background-color: #eb0028;
  color: #fff;
  width: 40%;
  margin-top: 15px;
  border-radius: 5px;
  position: relative;
  height: 35px;
  transition: 500ms;
  box-shadow: 0px 4px 5px 0px rgba(255, 165, 158, 1);
  border: 0;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    transition: 500ms;
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }
`;
export const StyledFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const StyledFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  position: relative;
  justify-content: ${(p) => (p.center ? "center" : "none")};
  align-items: ${(p) => (p.center ? "center" : "none")};
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: ${(p) =>
      p.isValid ? "2px solid #f1f2f8" : "2px solid rgb(247, 132, 126)"};
    border-radius: 6px;
    width: 270px;
    height: 37px;
  }
`;
export const StyledInput = styled.input`
  border: ${(p) =>
    p.isValid ? "2px solid rgb(247, 132, 126)" : "2px solid #f1f4f8"};
  width: 270px;
  padding: 10px;
  border-radius: 6px;
  font-size: 12px;
  &::placeholder {
    color: ${(p) => (p.isValid ? "rgb(247, 132, 126)" : "#ccc")};
    font-family: "BOG 2018";
  }
  &:focus {
    outline-color: #00418f;
  }
`;
export const StyledShowPassword = styled.button`
  background: transparent;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: 0;
  cursor: pointer;
  i {
    color: ${(p) => (p.showPassword ? "#ea0027" : "#282828")};
  }
  &:focus {
    outline: none;
  }
`;
export const StyledLabel = styled.span`
  font-family: "BOG 2017 Headline";
  color: #004592;
  padding-bottom: 5px;
  font-size: 12px;
`;
export const StyledButtonIcon = styled.div`
  position: absolute;
  right: 2px;
  top: 3px;
  width: 40px;
  height: 40px;
  background-color: #01478f;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  .anticon-login {
    font-size: 20px;
  }
`;

export const StyledPasswordForgot = styled.span`
  font-size: 12px;
  color: #ccc;
  cursor: pointer;
`;
export const StyledFormTitle = styled.h3`
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: "BOG 2017 Headline";
  color: #00418f;
  font-weight: 400;
  font-size: 14px;
`;
export const StyledFormSwitch = styled.span`
  margin-top: 20px;
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  color: #00418f;
  font-weight: 400;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const StyledPinBox = styled.div`
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 5px;
  padding: 1px;
  margin-top: ${(p) => (p.forgotPassword ? "0px" : "20px")};
  margin-bottom: ${(p) => (p.forgotPassword ? "15px" : "20px")};
  width: ${(p) => (p.modal ? "230px" : "230px")};
  background-color: #fff;

  .pin {
    background-color: #00418f;
    color: #fff;
    border: 0;
    padding: 8px;
    border-radius: 5px;
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    width: ${(p) => (p.modal ? "150px" : "150px")};
    opacity: ${(p) => (p.disableTimer ? "0.7" : "1")};
    cursor: pointer;
    margin-bottom: 2px;
    margin-left: 2px;
  }
  .pin__input {
    border: 0;
    padding: 0px;
    background-color: transparent;
    width: 60px;
    font-size: 20px;
    margin-left: 5px;
    text-align: center;
    &::placeholder {
      color: #ccc;
    }
    &:focus {
      outline: none;
    }
  }
`;

export const StyledBirthDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledBirthDayInput = styled.input`
  width: 80px;
  border: ${(p) =>
    p.isValid ? "2px solid rgb(247, 132, 126)" : "2px solid #f1f4f8"};
  padding: 10px;
  border-radius: 6px;
  font-size: 12px;
  &::placeholder {
    color: ${(p) => (p.isValid ? "rgb(247, 132, 126)" : "#ccc")};
    font-family: "BOG 2018";
  }
  &:focus {
    outline-color: #00418f;
  }
`;
