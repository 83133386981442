import React, { useState } from "react";

import { Form, message, Steps } from "antd";
import { getSteps } from "./getSteps";
import {
  StyledContainer,
  StyledStepWrapper,
  StyledButton,
} from "./styles/NumberPortingStyle";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

const { Step } = Steps;
const pinOptions = ["მობილურის ნომერი", "ელ.ფოსტა"];

const NumberPorting = () => {
  const [inputs, setInput] = useState([]);
  const [active, setActive] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const [disableTimer, setDisableTimer] = useState(false);
  const [pinNotification, setPinNotification] = useState(false);
  const [legalForm, setLegalForm] = useState(false);
  const [current, setCurrent] = useState(0);
  const [modalForm, setModalForm] = useState({
    legalForm: legalForm,
    identificationCode: "",
    companyName: "",
    personalNumber: "",
    fullName: "",
    email: "",
    contactNumber: "",
    address: "",
    subscriberNumber: "",
    operator: "",
    filePdf: "",
  });
  // ფორმის არჩევა 1) ფიზიკური პირი 2) იურიდიული პირი
  const [radio, setRadio] = useState({
    value1: "1",
    value2: "2",
  });

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo && alert("error"));
  };

  // ინპუტის დამატება
  const getInput = () => {
    setInput([
      ...inputs,
      {
        id: inputs.reduce((a, c) => (a = c.id), 0) + 1,
        name: `number-${inputs.length + 1}`,
      },
    ]);
    setActive(inputs.length + 1);
  };

  // ინპუტის წაშლა
  const removeInput = (id) => {
    setInput(inputs.filter((input) => input.id !== id));
  };

  function handleChange(e) {
    console.log(modalForm);
    setModalForm({
      ...modalForm,
      [e.target.name]: e.target.value,
    });
  }

  const handleSubscriberChange = (value) => {
    setModalForm({
      ...modalForm,
      subscriberNumber: value,
    });
  };
  // ფიზიკური ან იურიდიული პირის რეგისტრაციის ფორმის არჩევა
  const radioHandler = (e) => {
    setRadio({
      value1: e.target.value,
    });
    if (e.target.value === "მობილურის ნომერი") {
      setPinNotification(1);
    } else if (e.target.value === "ელ.ფოსტა") {
      setPinNotification(2);
    }
  };

  const showTimerHandler = () => {
    setShowTimer(!showTimer);
    if (showTimer !== true) {
      setDisableTimer(true);
      setTimeout(() => {
        setShowTimer(false);
        setDisableTimer(false);
      }, 180000);
    }
    message.success("PIN კოდი გამოგზავნილია!");
  };

  // სტეპის ერთი ნაბიჯით უკან
  const prev = () => {
    const currentStep = current - 1;
    setCurrent(currentStep);
  };

  const next = () => {
    const currentStep = current + 1;
    setCurrent(currentStep);
  };
  const steps = getSteps({
    legalForm,
    handleChange,
    getInput,
    removeInput,
    inputs,
    radio,
    pinOptions,
    radioHandler,
    showTimerHandler,
    showTimer,
    disableTimer,
    setLegalForm,
    modalForm,
    handleSubscriberChange,
  });
  return (
    <StyledContainer>
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinishFailed={onFinishFailed}
      >
        <StyledStepWrapper>
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.id} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">{steps[current].content}</div>
          <div className="steps-action">
            {current > 0 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <StyledButton onClick={() => prev()}>უკან</StyledButton>
                {current === 3 && (
                  <StyledButton green={true}>გაგზავნა</StyledButton>
                )}
              </div>
            )}
            {current < steps.length - 1 && (
              <StyledButton type="primary" onClick={() => next()}>
                შემდეგი
              </StyledButton>
            )}
          </div>
        </StyledStepWrapper>
      </Form>
    </StyledContainer>
  );
};

export default NumberPorting;
