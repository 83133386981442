import React from "react";

import { useTranslation } from "react-i18next";

import { Radio } from "antd";

import {
  StyledFormGroup,
  StyledLabel,
  StyledInput,
  StyledShowPassword,
} from "./LoginFormStyles";

import Timer from "../../Timer/Timer";


const ForgotPassword = ({
  showPassword,
  showPasswordHandler,
  radio,
  showTimerHandler,
  showTimer,
  disableTimer,
  state,
  pinNotification,
  forgotPassword,
  radioForgotPasswordHandler,
  handleForgotPassword,
  handleOnSubmitForgotPassword,
  forgotRepeatPassword,
  registerPhoneNumber,
  forgotPasswordPhoneNumber
}) => {
  const { t, i18n } = useTranslation();
  const pinOptions = [t("PasswordRecovery.CheckboxPhone"), "ელ.ფოსტა"];
  return (
    <>
      <StyledFormGroup>
        <Radio.Group
          options={pinOptions}
          onChange={radioForgotPasswordHandler}
          style={{ color: "red" }}
        />
      </StyledFormGroup>
      {pinNotification !== false && (
        <StyledFormGroup>
          <StyledLabel>
            {pinNotification === 1 && t("PasswordRecovery.CheckboxPhone")}
            {pinNotification === 2 && t("PasswordRecovery.CheckboxMail")}
          </StyledLabel>
          <StyledInput
            name={pinNotification === 1 ? "phoneNumber" : "email"}
            onChange={handleForgotPassword}
            isValid={
              pinNotification === 1
                ? state.error.forgotPhoneNumber
                : state.error.forgotEmail
            }
            value={
              pinNotification === 1
                ? state.forgotPasswordForm.phoneNumber
                : state.forgotPasswordForm.email
            }
            placeholder={
              pinNotification === 1
                ? t("PasswordRecovery.CheckboxPhoneInput")
                : t("PasswordRecovery.CheckboxEmailInput")
            }
          />
        </StyledFormGroup>
      )}
      <StyledFormGroup>
        <StyledLabel>{t("PasswordRecovery.NewPassword")}</StyledLabel>
        <StyledInput
          name="password"
          placeholder={t("PasswordRecovery.NewPassword")}
          type={showPassword ? "text" : "password"}
          onChange={handleForgotPassword}
          value={state.forgotPasswordForm.password}
          isValid={state.error.forgotPassword}
        />
        <StyledShowPassword
          type="button"
          onClick={showPasswordHandler}
          showPassword={showPassword}
        >
          <i className="fas fa-eye"></i>
        </StyledShowPassword>
      </StyledFormGroup>
      <StyledFormGroup>
        <StyledLabel>{t("PasswordRecovery.RepeatPassword")}</StyledLabel>
        <StyledInput
          name="repeatPassword"
          placeholder={t("PasswordRecovery.RepeatPassword")}
          type={showPassword ? "text" : "password"}
          onChange={handleForgotPassword}
          value={state.forgotPasswordForm.repeatPassword}
          isValid={state.error.forgotRepeatPassword}
        />
        <StyledShowPassword
          type="button"
          onClick={showPasswordHandler}
          showPassword={showPassword}
        >
          <i className="fas fa-eye"></i>
        </StyledShowPassword>
      </StyledFormGroup>
      {forgotRepeatPassword ? <p style={{color:"#eb0028",fontSize:"12px"}}>გამეორებული პაროლი არასწორია!</p> : ""}
      <StyledFormGroup>
        <div className="step__box">
          <StyledFormGroup center={true}>
            <Timer
              showTimer={showTimer}
              showTimerHandler={showTimerHandler}
              forgotPassword={forgotPassword}
              disableTimer={disableTimer}
              forgotRepeatPassword={forgotRepeatPassword}
              pinNotification={pinNotification}
              registerPhoneNumber={registerPhoneNumber}
            forgotPasswordPhoneNumber={forgotPasswordPhoneNumber}
            />
            <div style={{ textAlign: "center" }} className="notification">
              {pinNotification === 1 && (
                <p>
                  {t("PasswordRecovery.PinNotificationMessagePhone")}
                  <br />
                  {state.forgotPasswordForm.phoneNumber}
                </p>
              )}
              {pinNotification === 2 && (
                <p>
                  {t("PasswordRecovery.PinNotificationMessageEmail")}
                  <br />
                  {state.forgotPasswordForm.email}
                </p>
              )}
            </div>
          </StyledFormGroup>
        </div>
      </StyledFormGroup>
    </>
  );
};

export default ForgotPassword;
