import React, { useState } from "react";
import { Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { StyledContainer, StyledItem } from "./styles/DoubleStyles";
import Drawer from "./Drawer";
import ScrollAnimation from "react-animate-on-scroll";

const { Option } = Select;

const Double = () => {
  const [visible, setVisible] = useState(false);
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <StyledContainer>
        <StyledItem>
          <Drawer setVisible={() => setVisible(false)} visible={visible} />
          <div className="first__quotes">
            <label>ინტერნეტი</label>
            <label className="first_sub">20 Mbps</label>
          </div>
          <div className="divider">
            <label>
              <PlusOutlined />
            </label>
          </div>
          <div className="last__quotes">
            <label>ტელევიზია</label>
            <span>ჯეო ჩენელზ</span>
            <label className="sub" onClick={() => setVisible(true)}>
              114 არხი
            </label>
            <Select
              defaultValue="პაკეტები"
              style={{ width: 120 }}
              onChange={handleChange}
            >
              <Option value="jack">ქართული</Option>
              <Option value="lucy">რეგიონალური</Option>
              <Option value="Yiminghe">ძირითადი</Option>
            </Select>
          </div>

          <div className="price">
            <h3>
              55 <span>₾</span>
            </h3>
            <h4>30 დღე</h4>
          </div>

          <button>შეკვეთა</button>
        </StyledItem>

        <StyledItem>
          <div className="first__quotes">
            <label>ინტერნეტი</label>
            <label className="first_sub">40 Mbps</label>
          </div>
          <div className="divider">
            <label>
              <PlusOutlined />
            </label>
          </div>
          <div className="last__quotes">
            <label>ტელევიზია</label>
            <span>ჯეო ჩენელზ</span>
            <label className="sub">114 არხი</label>
            <Select
              defaultValue="პაკეტები"
              style={{ width: 120 }}
              onChange={handleChange}
            >
              <Option value="jack">ქართული</Option>
              <Option value="lucy">რეგიონალური</Option>
              <Option value="Yiminghe">ძირითადი</Option>
            </Select>
          </div>

          <div className="price">
            <h3>
              55 <span>₾</span>
            </h3>
            <h4>30 დღე</h4>
          </div>

          <button>შეკვეთა</button>
        </StyledItem>

        <StyledItem>
          <div className="first__quotes">
            <label>ინტერნეტი</label>
            <label className="first_sub">60 Mbps</label>
          </div>
          <div className="divider">
            <label>
              <PlusOutlined />
            </label>
          </div>
          <div className="last__quotes">
            <label>ტელევიზია</label>
            <span>ჯეო ჩენელზ</span>
            <label className="sub">114 არხი</label>
            <Select
              defaultValue="პაკეტები"
              style={{ width: 120 }}
              onChange={handleChange}
            >
              <Option value="jack">ქართული</Option>
              <Option value="lucy">რეგიონალური</Option>
              <Option value="Yiminghe">ძირითადი</Option>
            </Select>
          </div>

          <div className="price">
            <h3>
              55 <span>₾</span>
            </h3>
            <h4>30 დღე</h4>
          </div>

          <button>შეკვეთა</button>
        </StyledItem>
        <StyledItem>
          <div className="first__quotes">
            <label>ინტერნეტი</label>
            <label className="first_sub">80 Mbps</label>
          </div>
          <div className="divider">
            <label>
              <PlusOutlined />
            </label>
          </div>
          <div className="last__quotes">
            <label>ტელევიზია</label>
            <span>ჯეო ჩენელზ</span>
            <label className="sub">114 არხი</label>
            <Select
              defaultValue="პაკეტები"
              style={{ width: 120 }}
              onChange={handleChange}
            >
              <Option value="jack">ქართული</Option>
              <Option value="lucy">რეგიონალური</Option>
              <Option value="Yiminghe">ძირითადი</Option>
            </Select>
          </div>

          <div className="price">
            <h3>
              55 <span>₾</span>
            </h3>
            <h4>30 დღე</h4>
          </div>

          <button>შეკვეთა</button>
        </StyledItem>
    </StyledContainer>
  );
};

export default Double;
