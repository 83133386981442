import React from "react";

// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Footer from "../Lcomponents/Footer/Footer";
import Slider from "../Lcomponents/Slider/Slider";
import NumberPortingg from "../Lcomponents/NumberPorting/NumberPorting";

// styles
import { StyledNumberPortingContainer } from "./LandingStyle";

const NumberPorting = () => {
  return (
    <div>
      <Navbar />
      <Menu />
      <Slider />
      {/* ნომრის პორტირება */}
      <StyledNumberPortingContainer id="section2">
        <div className="container">
          <div className="section__title">
            <h1>განაცხადი ნომრის პორტირებაზე</h1>
          </div>
          <NumberPortingg />
        </div>
      </StyledNumberPortingContainer>
      <Footer />
    
    </div>
  );
};

export default NumberPorting;
