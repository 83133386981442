import React from "react";

// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Footer from "../Lcomponents/Footer/Footer";
import Slider from "../Lcomponents/Slider/Slider";
import Packets from "../Lcomponents/Telephone/Packets";
import Section1 from "../Lcomponents/Telephone/Section1";
import Description from "../Lcomponents/Telephone/Description";
import Alphabet from "../Lcomponents/Telephone/Alphabet";

const Voip = () => {
  return (
    <div>
      {/* ნავიგაცია */}
      <Navbar />
      {/* მენიუ */}
      <Menu />
      <Slider />
      <Section1 />
      {/* ანბანი */}
      <Alphabet />
      {/**     <Packets /> */}
      <Description />
      <Footer />
    </div>
  );
};

export default Voip;
