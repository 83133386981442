import styled from 'styled-components'

export const StyledContainer = styled.div`
    margin-top:25px;
    display:flex;
    align-items:center;
    width:100%;
    @media (max-width:1160px) {
        flex-direction:column;
        justify-content:center;
    }
    .column {
        margin-right:10px;
    }
    .ant-input {
        &::placeholder {
            font-size:12px;
        }
    }
    .ant-btn {
        span {
            font-size:12px;
        }
    }
    .ant-btn-primary {
    color: #fff;
    background: linear-gradient(90deg,rgba(63,121,186,1) 0%,rgba(0,69,146,1) 100%) !important;
    border-color: 90deg,rgba(63,121,186,1) !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
`