import React, { useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import {setServiceContent} from '../../../redux/reducers/dashboard/servicesContentMappings'
import ServicesMenu from "./ServicesMenu";

// სერვისები
import Service from "./ServicesContent/Service/Service";
import MyServices from "./ServicesContent/Service/MyServices";

// ტელეფონი
import Telephone from "./ServicesContent/Telephone/Telephone";
import Extraction from "./ServicesContent/Telephone/Extraction";
import NumberSettings from "./ServicesContent/Telephone/NumberSettings";

// A2P SMS
import A2P from "./ServicesContent/A2p/A2p";
import MassiveSms from "./ServicesContent/A2p/MassiveSms";
import TradeMark from "./ServicesContent/A2p/TradeMark";
import A2PExtraction from "./ServicesContent/A2p/A2pExtraction";
import DatabaseUpload from "./ServicesContent/A2p/DatabaseUpload";
import SMSAPI from "./ServicesContent/A2p/SmsApi";

// გადახდები
import Payments from "./ServicesContent/Payments/Payments";

// დოკუმენტები
import Documents from "./ServicesContent/Documents/Documents";
import Surrender from "./ServicesContent/Documents/Surrender";
import Invoice from "./ServicesContent/Documents/Invoice";

// პარამეტრები
import Address from './ServicesContent/Options/Address'
import ProfilePage from './ServicesContent/Profile/ProfilePage'

import { StyledContainer, StyledServicesContainer } from "./ServicesStyles";

// მაპი კომპონენტები
const mappings = {
  1: Service,
  2: Telephone,
  3: A2P,
  4: Payments,
  5: Documents,
  6: MyServices,
  8: Extraction,
  9: NumberSettings,
  10: MassiveSms,
  11: TradeMark,
  12: A2PExtraction,
  13: DatabaseUpload,
  14: SMSAPI,
  15: Surrender,
  16: Invoice,
  17: Address,
  18: ProfilePage
};

const Services = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userInfo.user);
  const content = useSelector(state => state.servicesContentMappings.number)
  // სერვისების მენიუ "კომპონენტები"
  const Component = mappings[content];
  const setContent = (contentNumber) => {
    dispatch(setServiceContent(contentNumber))
  };

  return (
    <StyledContainer>
      <ServicesMenu
        setServiceContent={setContent}
      />
      <div className="right__side">
        <StyledServicesContainer>
          {Component ? (
            <Component
              user={user}
            />
          ) : null}
        </StyledServicesContainer>
      </div>
    </StyledContainer>
  );
};

export default Services;
