import styled from "styled-components";

export const StyledTariff = styled.div`
  width: 220px !important;
  height: 165px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 0px 5px 0px rgba(204, 204, 204, 1);
  transition: 300ms;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  margin:10px;
  .head {
    width: 100%;
    text-align: center;
    font-family: "BOG 2017 Headline";
    background-color: #fff;
    color: #7f848a;
    padding: 5px;
    h3 {
      font-size: 16px;
      color: #7f848a;
      margin-top:10px;
    }
    span {
      font-size: 10px;
    }
  }
  ul li {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: #FF5A5F;
    position: relative;
    span {
      font-family: "BOG 2017 Headline";
      color: #fff;
      font-size: 12px;
    }
    .gel {
      color: #fff;
      position: absolute;
    }
    .price {
      font-size: 25px;
    }
    .limit {
      margin-top: 13px;
    }
    .format {
      display: flex;
      flex-direction: column;
    }
  }
  .order__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
`;

export const StyledOrderButton = styled.button`
  padding: 7px;
  width: 60%;
  border: 0;
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  color: #FF5A5F;
  border-radius: 3px;
  cursor: pointer;
  transition: 300s;
  background-color: #fff;
  border: 1px solid #FF5A5F;
`;

export const StyledContainer = styled.div`
  width: 1020px;
  max-width: 100%;
  margin: 0 auto;
 
  .slideInner___2mfX9  {
    @media (max-width:700px) {
      display: flex;
    justify-content: center;
    align-items: center;
    }
  }
  .title {
    font-family: "BOG 2017 Headline";
    font-size: 16px;
    color: #0e4c90;
    padding-top: 20px;
    text-align: center;
    padding-bottom: 20px;
    i {
      color: #004592;
    }
  }
`;

export const StyledControllers = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  button {
    margin:10px;
    background-color:#0e4c90;
    color:#fff;
    width:30px;
    height:30px;
    border-radius:50%;
    border:0;
    transition:300ms;
    &:hover {
      opacity:0.5;
    }
    &:focus {
      outline:none;
    }
  }
`