import React, { useState, useEffect } from "react";
import {message} from 'antd'
import { CameraOutlined, FormOutlined, CheckOutlined } from "@ant-design/icons";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser,userUpdate } from "../../../../../redux/reducers/landing/userInfoReducer";


import {
  StyledContainer,
  StyledAvatar,
  StyledPersonInfo,
  StyledAccountInfo,
  StyledInfo,
  StyledEditButton,
} from "./ProfilePageStyle";
const infoarray = [
  {
    id: 1,
    label: "სახელი",
    name: "firstname",
  },
  {
    id: 2,
    label: "გვარი",
    name: "lastname",
  },
  {
    id: 6,
    label: "პირადი ნომერი",
    name: "idcard",
  },
  {
    id: 3,
    label: "დაბადების თარიღი",
    name: "birthday",
  },
  {
    id: 5,
    label: "მომხმარებელი",
    name: "login",
  },
  {
    id: 4,
    label: "ტელეფონი",
    name: "cellphone",
  },
  {
    id: 7,
    label: "იმეილი",
    name: "email",
  },
  {
    id: 8,
    label: "მისამართი",
    name: "address",
  },
];

const ProfilePage = () => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState({});
  const [activeInput, setActiveInput] = useState(0);
  const [file, setFile] = useState(null);

  const userData = useSelector((state) => state.userInfo.user);

  const {
    firstname,
    lastname,
  } = userData;


  const fileHandler = (e) => {
    setFile(e.target.files[0]);
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("upload", file, file.name);
    formData.append("currentUser", userData.currentUser);
    axios.post("http://telecom1.ge/api_web/api_image.php", formData);
  };
  const editHandler = (e) => {
    setEdit({ [e.target.name]: e.target.value });
  };

  const updateUserHandler = () => {
    dispatch(userUpdate(edit))
    setEdit({})
    setActiveInput(0)
    message.success("წარმატებით შეიცვალა ინფორმაცია.")
  }
  useEffect(() => {
    dispatch(fetchUser());
    console.log(userData)
  }, []);
  return (
    <StyledContainer>
      <StyledAvatar>
        <div className="avatar">
          <img src="https://scontent.ftbs4-1.fna.fbcdn.net/v/t1.6435-9/37943235_223188348523910_8505778165283553280_n.jpg?_nc_cat=105&ccb=1-3&_nc_sid=174925&_nc_ohc=a1SpFhf0l2QAX-VUtCW&_nc_oc=AQmBtzBH2VB7MEmUedVYrTTpOrAYO8_5d5p-FqaenPEYwHQjpHmbqbWtCS_so3cKBEQ&_nc_ht=scontent.ftbs4-1.fna&oh=11e412089cb3305f6e13ee4b966b94a0&oe=6089D59E" />
          <div className="upload">
            <CameraOutlined style={{ color: "#fff" }} />
          </div>
        </div>
        <StyledPersonInfo>
          <h4>
            {firstname} {lastname}
          </h4>
          <span>ფიზიკური პირი</span>
        </StyledPersonInfo>
      </StyledAvatar>
      <StyledAccountInfo>
        {infoarray.map((item) => (
          <StyledInfo key={item.id}>
            <span>{item.label}</span>
            <input
              name={item.name}
              placeholder={userData[item.name]}
              disabled={activeInput !== item.id ? true : false}
              onChange={editHandler}
            />
            {edit[item.name]?.length ? (
              <StyledEditButton onClick={updateUserHandler}>
                <CheckOutlined style={{ fontSize: "16px" }} />
              </StyledEditButton>
            ) : (
              <StyledEditButton onClick={() => setActiveInput(item.id)}>
                <FormOutlined style={{ fontSize: "16px" }} />
              </StyledEditButton>
            )}
          </StyledInfo>
        ))}
      </StyledAccountInfo>
    </StyledContainer>
  );
};

export default ProfilePage;
