import React from "react";
// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Footer from "../Lcomponents/Footer/Footer";
import Slider from "../Lcomponents/Slider/Slider";
import CheckBalance from "../Lcomponents/CheckBalance/CheckBalance";

const Balance = () => {
  return (
    <div>
      <Navbar />
      <Menu />
      <Slider />
      <CheckBalance />
      <Footer />
    </div>
  );
};

export default Balance;
