import React from 'react'
import Layoute from './Layoute'
import Categorys from './Categorys'
import {StyledContainer} from './styles/VacancyCStyles'
const VacancyC = () => {
    return (
        <StyledContainer>
            <Categorys />
            <Layoute />
        </StyledContainer>
    )
}

export default VacancyC
