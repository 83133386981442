import React, { useEffect, useState } from "react";
import { message,Modal } from "antd";
import {InfoCircleOutlined } from '@ant-design/icons'

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// fetch data
import { fetchTariffData } from "../../redux/reducers/landing/tariffReducer";

// components
import NumbersTable from "./NumbersTable";
import OrderAddModal from "../OrderAddModal/OrderAddModal";
// styles
import {
  StyledTariff,
  StyledOrderButton,
  StyledWrapper,
  StyledTarrifWrapper,
  StyledPacketInfoButton,
  StyledInfoList
} from "./styles/Section1";
import lg from '../../assets/png/logo.png'


const Section1 = React.memo(() => {
  const [orderAddModalVisible, setOrderAddModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [infoModal,setInfoModal] = useState([])
  const [orderId, setOrderId] = useState(null);
  const dispatch = useDispatch();
  const phone = useSelector((state) => state.basket.phone);
  const tariff = useSelector((state) => state.tariff.tariff);
  const history = useHistory();

  const tariffHandler = async (prop) => {
    if (localStorage.getItem("token")) {
      await setOrderId(prop.id);
      phone.id ? showModal() : message.warning("გთხოვთ აირჩიოთ ნომერი.");
    } else {
      message.warning("გთხოვთ გაიაროთ ავტორიზაცია ან რეგისტრაცია");
      history.push("/login");
    }
  };
  const clearPhone = () => {};

  const showModal = () => {
    setOrderAddModalVisible(true);
  };
  const showInfoModal = (info) => {
    setIsModalVisible(true);
    setInfoModal(info)
  };

  const handleOk = () => {
    setOrderAddModalVisible(false);
  };

  const handleCancel = () => {
    setOrderAddModalVisible(false);
    setIsModalVisible(false);
  };

  useEffect(() => {
    dispatch(fetchTariffData());
  }, []);
  return (
    <div>
      <OrderAddModal
        handleOk={handleOk}
        handleCancel={handleCancel}
        orderAddModalVisible={orderAddModalVisible}
        category="voip"
        order_id={orderId}
      />
                 <Modal
                title={`დეტალური ინფორმაცია`}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
              >
                <StyledInfoList>
                  <img className="logo" alt="" src={lg} />
                  {infoModal.map((info, index) => (
                    <li key={index}>
                      <span>{info.name}</span> <span>{info.value}</span>
                    </li>
                  ))}
                </StyledInfoList>
              </Modal>
      <StyledWrapper>
        <div className="title">
          <h1>VoIP ტელეფონი</h1>
        </div>
      </StyledWrapper>
      <NumbersTable />
      <StyledTarrifWrapper id="tariff">
        <div className="section__title">
          <h1>სატარიფო გეგმა</h1>
        </div>
        <div className="tariff__container">
          {tariff &&
            tariff.map((item) => (
                          <StyledTariff key={item.id}>
                          <div className="head">
                            <h3>{item.display_name}</h3>
                          </div>
                          
                        <div className="price">
                          <h3>
                            {item.base_price} <span>₾</span>
                          </h3>
                          <h4>30 დღე</h4>
                        </div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <StyledPacketInfoButton
                              onClick={() => showInfoModal(item.info)}
                              className="info__btn"
                            >
                         <InfoCircleOutlined /> <p>დეტალური ინფორმაცია</p>
                            </StyledPacketInfoButton>
                          </div>
              
                          <div className="order__container">
                            {/* პაკეტის არჩევის ღილაკი */}
                            <StyledOrderButton
                              onClick={() => {
                                tariffHandler(item);
                                clearPhone();
                              }}
                              green={item.green}
                              red={item.red}
                            >
                              შეკვეთა
                            </StyledOrderButton>
                          </div>
                        </StyledTariff>
            ))}
        </div>
      </StyledTarrifWrapper>
    </div>
  );
});

export default Section1;
