import styled from 'styled-components'

export const StyledContainer = styled.div`
    width:100%;
    padding-bottom:50px;
    margin:5px;
`

export const StyledItem = styled.div`
  border:1px solid rgba(204, 204, 204, 1);
  padding:10px;
  border-radius:3px;
  margin-top:15px;
  &:first-child {
      margin-top:0;
  }
    h4 {
        font-size:14px;
        font-family: "BOG 2017 Headline";
    }
    p {
        font-size:12px;
    }
    label {
        font-size:12px;
    }
    ul {
        padding:18px;
    }
    ul li {
        font-size:12px;
    }
    button {
        padding:4px;
        font-size:12px;
        cursor:pointer;
    }
`