import React from "react";
import Navbar from "../Lcomponents/Navbar/Navbar";
import Footer from "../Lcomponents/Footer/Footer";
import LoginForm from "../Lcomponents/HomePage/LoginForm/LoginForm";
import { StyledContainer } from "./HomeStyles";
const Home = () => {
  return (
    <StyledContainer>
      <Navbar />
      <LoginForm />
      <Footer />
    </StyledContainer>
  );
};

export default Home;
