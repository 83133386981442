import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Redirect
  } from "react-router-dom";
const PrivateRoute = ({component: Component,token,clearBasket, ...rest}) => (
    <Route {...rest} render={(props) => (
      localStorage.getItem("token") === null ? <Redirect to="/login" />
      : <Component clearBasket={clearBasket} {...props} />
    )}/>
  )

export default PrivateRoute
