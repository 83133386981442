import React from "react";

// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Footer from "../Lcomponents/Footer/Footer";
import Slider from "../Lcomponents/Slider/Slider";
import Faqq from "../Lcomponents/Faq/Faq";
import Rules from "../Lcomponents/Faq/Rules";

const Faq = () => {
  return (
    <div>
      <Navbar />
      <Menu />
      <Slider />
      <Faqq />
      {/* წესები */}
      <Rules />
      <Footer />
    </div>
  );
};

export default Faq;
