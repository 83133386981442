import React from "react";
import {
  StyledContainer,
  StyledFormGroup,
  StyledTradeMarkStatusContainer,
  StyledTradeMarkStatus,
  StyledStatus,
  StyledBar,
  StyledTradeMarkHead,
} from "./Styles/TradeMarkStyle";
const TradeMark = () => {
  return (
    <div>
      <StyledBar>
        <h3>საფირმო სახელწოდების შეკვეთა</h3>
      </StyledBar>
      <StyledContainer>
        <form>
          <StyledFormGroup>
            <label>შეიყვანეთ მაქსიმუმ 11 ლათინური სიმბოლო</label>
            <input
              type="text"
              placeholder="საფირმო სახელწოდება"
              maxLength="11"
            />
          </StyledFormGroup>
          <div>
            <button>შეკვეთა</button>
          </div>
        </form>
        <StyledTradeMarkStatusContainer>
          <StyledTradeMarkHead>
            <h4>შეკვეთილი საფირმო სახელწოდებები</h4>
          </StyledTradeMarkHead>
          <StyledTradeMarkStatus>
            <h4>AQCIA</h4>
            <h4>
              სტატუსი: <StyledStatus confirmed={false}>შეკვეთილი</StyledStatus>
            </h4>
          </StyledTradeMarkStatus>

          <StyledTradeMarkStatus>
            <h4>AQCIA</h4>
            <h4>
              სტატუსი:
              <StyledStatus confirmed={true}>დადასტურებული</StyledStatus>
            </h4>
          </StyledTradeMarkStatus>

          <StyledTradeMarkStatus>
            <h4>AQCIA</h4>
            <h4>
              სტატუსი:
              <StyledStatus confirmed={true}>დადასტურებული</StyledStatus>
            </h4>
          </StyledTradeMarkStatus>
        </StyledTradeMarkStatusContainer>
      </StyledContainer>
    </div>
  );
};

export default TradeMark;
