import styled from 'styled-components'

export const StyledContainer = styled.div`
    width:1050px;
    max-width:100%;
    margin:0 auto;
    @media (max-width:768px) {
        display:flex;
        align-items:center;
    justify-content:center;
    flex-direction:column;
  }
    .title {
        text-align:center;
        h1 {
            text-align: center;
    font-family: "BOG 2017 Headline";
    padding-top: 30px;
    padding-bottom: 30px;
    color: #0e4c90;
    font-size: 18px;
        }
    }
`