import styled from 'styled-components'

export const StyledContainer = styled.div`
    width:350px;
    border:1px solid rgba(204, 204, 204, 1);
  margin:5px;
  border-radius:3px;

  ul {
      list-style:none;
      padding:5px;
      li {
          cursor:pointer;
          padding:10px;
          transition:300ms;
          border-radius:3px;
          margin-top:10px;
        border:1px solid rgba(204, 204, 204, 1);
        &:hover {
            background-color:#004592;
            color:#fff;
        }
      }
  }
`