import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSmsContact,
  fetchNumber,
  remoteHandler,
  localHandler,
  setSmsApiKeyAction,
} from "../../../../../redux/reducers/dashboard/sms";
// import {resetHandler} from '../../../../../redux/reducers/dashboard/userContacts' 

import { Select, Input } from "antd";

import TelephoneDrawer from "./TelephoneDrawer";
import Chat from "./Chat/Chat";
import {
  StyledBar,
  StyledButton,
  StyledContainer,
} from "./Styles/TelephoneStyles";
const { Option } = Select;
const Telephone = () => {
  const dispatch = useDispatch();
  const { numbers,remoteNumber} = useSelector((state) => state.sms);
  const [telephoneDrawer, setTelephoneDrawer] = useState(false);


  useEffect(() => {
    dispatch(fetchNumber());
  }, []);


  const showTelephoneDrawer = () => {
    setTelephoneDrawer(true);
  };
  const closeTelephoneDrawer = () => {
    setTelephoneDrawer(false);
  };

  const setContactItemHandler = (item) => {
    closeTelephoneDrawer();
  };
  return (
    <StyledContainer>
      <TelephoneDrawer
        showTelephoneDrawer={showTelephoneDrawer}
        closeTelephoneDrawer={closeTelephoneDrawer}
        telephoneDrawer={telephoneDrawer}
        setContactItemHandler={setContactItemHandler}
      />
      <StyledBar>
        <div className="barx">
          <div className="search__number">
            <Select
              defaultValue="აირჩიეთ ნომერი"
              style={{ width: "210px" }}
              onChange={(value, props) => {
                dispatch(fetchSmsContact())
                dispatch(localHandler(value, props.freesms,props.sms_free_sent_today));
                dispatch(setSmsApiKeyAction(props.apikey));
              }}
            >
              {numbers.map((item) => {
                return (
                  <Option
                    style={{ fontSize: "12px" }}
                    key={item.id}
                    apikey={item.sms_apikey}
                    value={item.sip_username}
                    freesms={item.sms_free_daily}
                    sms_free_sent_today={item.sms_free_sent_today}
                    id={item.acc_product_id}
                  >
                    {item.sip_username}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="search__number">
            <Input
              placeholder="ადრესატის სახელი ან ნომერი"
              style={{
                width: 215,
                padding: "6px",
              }}
              value={remoteNumber && remoteNumber }
              onChange={(e) => dispatch(remoteHandler(e.target.value))}
            />
          </div>
        </div>
        <div>
          <StyledButton large={true} red={true}>
            ახალი SMS
          </StyledButton>
          <StyledButton large={true} red={false} onClick={showTelephoneDrawer}>
            კონტაქტები
          </StyledButton>
        </div>
      </StyledBar>
      <Chat />
    </StyledContainer>
  );
};
export default Telephone;
