import styled from "styled-components";

export const StyledBar = styled.div`
  width: 880px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f7f7f7;
  padding: 20px;
  margin-top: 25px;
  h3 {
    font-family: "BOG 2017 Headline";
    color: #7f848a;
    font-size: 12px;
  }
  @media (max-width: 890px) {
    width: 100%;
  }
`;
export const StyledContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 880px;
  max-width: 100%;
  border: 2px solid #f7f7f7;
  padding-bottom: 20px;
  padding-top: 20px;
  padding: 20px;
  @media (max-width: 931px) {
    width: 100%;
    flex-direction: column;
  }
  form {
    width: 600px;
    padding: 20px;
    border: 2px solid #f7f7f7;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 3px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    @media (max-width: 625px) {
      width: 100%;
    }
    button {
      background-color: ${(p) => (p.red ? "#eb0028" : "#004592")};
      color: #fff;
      padding: 10px;
      border: 0;
      border-radius: 3px;
      cursor: pointer;
      font-size: 12px;
      width: 150px;
      font-family: "BOG 2017 Headline";
      transition: 300ms;
      margin-top: 20px;
      &:hover {
        color: #ccc;
      }
    }
  }
`;

export const StyledFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  input {
    padding: 5px;
    border: 2px solid #f7f7f7;
    width: 300px;
    margin-top: 5px;
    border-radius: 3px;
    @media (max-width: 931px) {
      width: 100%;
    }
    &::placeholder {
      color: #ccc;
      font-size: 12px;
    }
  }
  label {
    font-size: 12px;
    color: #7f848a;
  }
`;

export const StyledTradeMarkStatusContainer = styled.div`
  width: 400px;
  height: auto;
  border: 2px solid #f7f7f7;
  margin-top: 20px;
  margin: 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 931px) {
    width: 100%;
  }
`;
export const StyledTradeMarkHead = styled.div`
  background: #004592;
  width: 100%;
  padding: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  h4 {
    font-size: 12px;
    color: #fff;
    font-family: "BOG 2017 Headline";
  }
`;
export const StyledTradeMarkStatus = styled.div`
  width: 95%;
  height: 60px;
  border: 2px solid #f7f7f7;
  padding: 5px;
  border-radius: 3px;
  margin-top: 10px;
  margin: 5px;
  h4 {
    font-size: 12px;
    color: #7f848a;
    display: flex;
    align-items: center;
  }
`;

export const StyledStatus = styled.h5`
  font-size: 12px;
  padding-top: 6px;
  padding-left: 2px;
  color: ${(p) => (p.confirmed ? "#00cc41" : "#004592")};
`;
