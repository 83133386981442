import React from 'react'

// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Footer from "../Lcomponents/Footer/Footer";
import Slider from "../Lcomponents/Slider/Slider";
import Packets from '../Lcomponents/Internet/Packets'
import Description from '../Lcomponents/Internet/Description'

const Internet = () => {
    return (
        <div>
        <Navbar />
        <Menu />
        <Slider />
            <Packets />
            <Description />
        <Footer />
      
      </div>
    )
}

export default Internet
