import React from "react";
import { Modal } from "antd";
import {useSelector} from 'react-redux'
import {
  StyledCategoryContainer,
  StyledChannelsContainer,
  StyledChannel,
  StyledButton
} from "./styles/DrawerStyles";
const Drawer = ({ visible, setVisible,type }) => {
  const basicList = useSelector((state) => state.iptv.basicList);
  const filterCategory = useSelector((state) => state.iptv.filterCategory);
  console.log(type)
  return (
    <div>
      <Modal
        title="ტელევიზია"
        centered
        visible={visible}
        onOk={setVisible}
        onCancel={setVisible}
        width={900}
      >
        <StyledCategoryContainer>
          <div>
            <StyledButton active={true} >ყველა</StyledButton>
          </div>
          {filterCategory.map((item) => <div key={item.UID}><StyledButton>{item.GANRE_NAME}</StyledButton></div>)}
        </StyledCategoryContainer>

        <StyledChannelsContainer>
          {type === "iptv_dcm_basic" ? basicList.map((item) => <StyledChannel key={item.UID}><img src={item.CHANNEL_LOGO}/> <span>{item.CHANNEL_NAME}</span></StyledChannel>) : null}
        </StyledChannelsContainer>
      </Modal>
    </div>
  );
};

export default Drawer;
