import React from "react";
// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Slider from '../Lcomponents/Slider/Slider'
import Footer from "../Lcomponents/Footer/Footer";
import VacancyC from "../Lcomponents/Vacancy/VacancyC";

const Vacancy = () => {
  return (
    <div>
      {/* ნავიგაცია */}
      <Navbar />
      {/* მენიუ */}
      <Menu />
      {/* hero სექცია */}
      <Slider />
        <VacancyC />
      {/* ფუთერი */}
      <Footer />
    </div>
  );
};

export default Vacancy;
