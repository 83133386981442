import React, {useEffect} from "react";
import {useDispatch} from 'react-redux'
import {fetchPbxData} from '../redux/reducers/landing/pbxReducer'
// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Footer from "../Lcomponents/Footer/Footer";
import Slider from "../Lcomponents/Slider/Slider";
import Bpx from "../Lcomponents/Pbx/Bpx";
import Description from '../Lcomponents/Pbx/Description'
const Pbx = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPbxData())
    }, [])
    return (
        <div>
            <Navbar />
            <Menu />
            <Slider/>
            <Bpx />
            <Description />
            <Footer/>
        </div>
    )
}

export default Pbx
