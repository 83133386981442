import styled from 'styled-components'

export const StyledCategoryContainer = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-wrap:wrap;
    box-shadow: 0 0 5px 0 rgb(100 100 100 / 10%);
  border: 1px solid #eff1f3;
  padding:5px;
  border-radius:3px;

`

export const StyledButton=  styled.button`
        width:130px;
        padding:5px;
        background-color:#fff;
        border:1px solid #ccc;
        border-radius:3px;
        margin:5px;
        cursor: pointer;
        font-size:12px;
        background-color:${p => p.active ? "#0e4c90" : "#fff"};
        border:${p => p.active ? "1px solid #0e4c90" : "1px solid #ccc"};
        color:${p => p.active ? "#fff" : "#282828"};
    `
export const StyledChannelsContainer = styled.div`
    margin-top:30px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-wrap:wrap;
    height:400px;
    overflow-x:scroll;

`

export const StyledChannel = styled.div`
    width:70px;
    height:60px;
    overflow:hidden;
    padding:5px;
    margin:5px;
    border-radius:3px;
    box-shadow: 0 0 5px 0 rgb(100 100 100 / 10%);
  border: 1px solid #eff1f3;
  display:flex;
  justify-content:space-between;
  align-items:center;

  flex-direction:column;
  span {
      font-size:12px;
  }
  img {
      width:30px;
  }
`