import styled from "styled-components";

export const StyledUl = styled.ul`
    width:1200px;
    max-width:100%;
    margin:0 auto;
    h4 {
        font-family: "BOG 2017 Headline";
        font-size:15px;
        color:#004592;
        padding-top:20px;
        padding-bottom:20px;
        @media (max-width:768px) {
                padding:10px;
            }
    }
    li {
        font-size:12px;
            @media (max-width:768px) {
                padding:10px;
            }
        a { 
            color:#282828;
            text-decoration:underline;
            transition:300ms;
            &:hover {
                text-decoration:none;
                color:#004592;
            }
        }
    }
`;
