import React, { useState } from "react";
import { StyledContainer, StyledItem } from "./styles/LayouteStyles";
const Layoute = () => {
  const [show, setShow] = useState(0);

  const showRequirementsHandler = (id) => {
    setShow(id);
  };
  return (
    <StyledContainer>
      <StyledItem>
        <div>
          <h4>Front End დეველოპერი</h4>
          <p>
            პროგრამული უზრუნველყოფისა და ციფრული ინოვაციების დარგში მოღვაწე
            კომპანია ვაბაკო ეძებს React დეველოპერს, რომელიც დაგვეხმარება ჩვენი
            ციფრული პროდუქტების შექმნაში. გთავაზობთ კრეატიულ, მეგობრულ და
            დინამიურ გარემოში პოტენციალის გამოვლენას, სადაც ჯანსაღი ინიციატივა
            და საქმის მაღალი ხარისხით შესრულება აუცილებლად ფასდება.
          </p>
          {show === 1 && (
            <div>
              <div>
                <label>საკვალიფიკაციო მოთხოვნები:</label>
                <ul>
                  <li>JavaScript-ის (es6) კარგად ცოდნა</li>
                  <li>Webpack-ის კონფიგურაციის ცოდნა;</li>
                  <li>React-ისა და Redux-ის კარგი ცოდნა;</li>
                  <li>CSS3, HTML5, JS (ES6+) და Web API-ს კარგი ცოდნა;</li>
                  <li>
                    ფუნქციონალური პროგრამირების (FP) პრინციპების ცოდნა და აღქმა
                  </li>
                  <li>პროექტირებისა და პროგრამირების პატერნების ცოდნა</li>
                </ul>
              </div>
              <div>
                <label>პირობები:</label>
                <ul>
                  <li>სამუშაო გრაფიკი - ორშაბათიდან პარასკევის ჩათვლით</li>
                  <li>ჯანმრთელობის დაზღვევა</li>
                </ul>
              </div>
            </div>
          )}
          <button onClick={() => showRequirementsHandler(1)}>დეტალურად</button>
        </div>
      </StyledItem>

      <StyledItem>
        <div>
          <h4>Back End დეველოპერი</h4>
          <p>
            პროგრამული უზრუნველყოფისა და ციფრული ინოვაციების დარგში მოღვაწე
            კომპანია ვაბაკო ეძებს React დეველოპერს, რომელიც დაგვეხმარება ჩვენი
            ციფრული პროდუქტების შექმნაში. გთავაზობთ კრეატიულ, მეგობრულ და
            დინამიურ გარემოში პოტენციალის გამოვლენას, სადაც ჯანსაღი ინიციატივა
            და საქმის მაღალი ხარისხით შესრულება აუცილებლად ფასდება.
          </p>
          {show === 2 && (
            <div>
              <div>
                <label>საკვალიფიკაციო მოთხოვნები:</label>
                <ul>
                  <li>JavaScript-ის (es6) კარგად ცოდნა</li>
                  <li>Webpack-ის კონფიგურაციის ცოდნა;</li>
                  <li>React-ისა და Redux-ის კარგი ცოდნა;</li>
                  <li>CSS3, HTML5, JS (ES6+) და Web API-ს კარგი ცოდნა;</li>
                  <li>
                    ფუნქციონალური პროგრამირების (FP) პრინციპების ცოდნა და აღქმა
                  </li>
                  <li>პროექტირებისა და პროგრამირების პატერნების ცოდნა</li>
                </ul>
              </div>
              <div>
                <label>პირობები:</label>
                <ul>
                  <li>სამუშაო გრაფიკი - ორშაბათიდან პარასკევის ჩათვლით</li>
                  <li>ჯანმრთელობის დაზღვევა</li>
                </ul>
              </div>
            </div>
          )}
          <button onClick={() => showRequirementsHandler(2)}>დეტალურად</button>
        </div>
      </StyledItem>
    </StyledContainer>
  );
};

export default Layoute;
