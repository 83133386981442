import styled from "styled-components";

export const StyledContainer = styled.div``;

export const StyledStepWrapper = styled.div`
  .ant-steps-item-process .ant-steps-item-icon {
    background: #004592;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #004592;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    border-color: #004592;
  }
  .steps-content {
    margin-top: 15px;
  }
  .steps-action {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 480px) {
    .ant-steps-horizontal.ant-steps-label-horizontal {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: initial;
      flex-direction: initial;
    }
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #fff;
  }
  .button-wrapper {
    position: relative;
    width: 180px;
    text-align: center;
    cursor: pointer;
    border: 2px solid #f7f7f7;
    border-radius: 3px;
  }

  .button-wrapper span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: #fff;
    cursor: pointer;
    color: #7f848a;
    padding: 8px 0;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 3px;
    font-family: "BOG 2017 Headline";
  }

  #upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
`;

export const StyledRadioButtons = styled.div`
  .ant-radio-wrapper {
    span {
      font-family: "BOG 2017 Headline";
      font-size: 10px;
      color: #00418f;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #00418f !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #00418f;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #00418f;
  }
  .ant-input {
    font-size: 12px;
  }
  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
    font-size: 12px;
  }
`;

export const StyledSubmitButton = styled.button`
  background: ${(p) => (p.red ? "#eb0028" : "#00418f")};
  color: #fff;
  border: 0;
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
  font-family: "BOG 2017 Headline";
  font-size: 12px;
`;

export const StyledAddInputButton = styled.button`
  background: #1a518e;
  color: #fff;
  border: 0;
  padding: 5px;
  border-radius: 3px;
  margin-top: 5px;
  width: 120px;
  cursor: pointer;
  font-family: "BOG 2017 Headline";
  font-size: 12px;
`;

export const StyledFormGroup = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media (max-width: 414px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .inputs {
    display: flex;
    flex-direction: column;
  }
  .input__box {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    @media (max-width: 414px) {
      margin-left: 0;
      margin-top: 10px;
    }
    button {
      background: transparent;
      border: 0;
      cursor: pointer;
      i {
        color: #eb0028;
        font-size: 20px;
        margin-top: 5px;
        margin-left: 5px;
      }
    }
  }
  .ant-select-selection-item {
    font-size: 12px;
  }
  .ant-select-item-option-content {
    font-size: 12px;
  }
`;

export const StyledItem = styled.div`
  .ant-input {
    font-size: 12px;
  }
  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .ant-form-item-label > label {
    font-size: 12px;
  }
`;

export const StyledLegalFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
export const StyledPersonalButton = styled.button`
  background: ${(p) => (!p.legalForm ? "#00418f" : "#fff")};
  color: ${(p) => (!p.legalForm ? "#fff" : "#00418f")};
  border: 1px solid #7f848a;
  padding: 7px;
  border-radius: 3px;
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  margin: 5px;
  cursor: pointer;
  @media (max-width: 414px) {
    font-size: 10px;
  }
`;
export const StyledLegalButton = styled.button`
  background: ${(p) => (p.legalForm ? "#00418f" : "#fff")};
  color: ${(p) => (p.legalForm ? "#fff" : "#00418f")};
  border: 1px solid #7f848a;
  padding: 7px;
  border-radius: 3px;
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  margin: 5px;
  cursor: pointer;
  @media (max-width: 414px) {
    font-size: 10px;
  }
`;

export const StyledButton = styled.div`
  background-color: ${(p) => (p.green ? "#398439" : "#00418f")};
  padding: 7px;
  border-radius: 3px;
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  margin: 5px;
  cursor: pointer;
  width: 160px;
  color: #fff;
  text-align: center;
  transition: 300ms;
  @media (max-width: 355px) {
    width: 130px;
  }
  &:hover {
    color: #ccc;
  }
`;
