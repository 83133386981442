import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 878px;
  margin-top: 20px;
  max-width:100%;
  .ant-table-thead {
    .ant-table-cell {
      font-family: "BOG 2017 Headline";
      color: #004592;
    }
  }
  .ant-table-cell {
    color: #7f848a;
    font-size: 12px;
    a {
      color: #004592;
      &:hover {
        color: #ccc;
      }
    }
  }
  .ant-pagination-item-active a {
    color: #004592;
  }
  .ant-pagination-item-active {
    border-color: #004592;
  }
  .ant-pagination-item a {
    &:hover {
      color: #ccc;
    }
  }
`;
