import styled from 'styled-components'

export const StyledContainer = styled.div`
    width:1200px;
    margin:0 auto;
    max-width:100%;
    margin-top:50px;
    margin-bottom:50px;
    display:flex;
    align-items:flex-start;
    background:#F2F2F2;
    border-radius:3px;
    position:relative;
    h3 {
        font-family: "BOG 2017 Headline";
        font-size:14px;
        padding:5px;
        padding-top:0;
    }
    p {
        font-size:13px;
        padding:5px;
        padding-top:0px;
    }

    img {
        width:400px;
        border-top-left-radius:3px;
        border-bottom-left-radius:3px;
    }
    .view {
        position:absolute;
    top:5px;
    right:5px;
    }
    
`

export const StyledSocialMedia = styled.div`
    position:absolute;
    bottom:15px;
    right:15px;
    .Demo__some-network__share-button {
        margin-left:5px;
    }
`