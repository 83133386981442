import React from 'react'
import { Select } from 'antd';
import {StyledContainer} from './styles/DoubleSearch'
import ScrollAnimation from 'react-animate-on-scroll';

const { Option } = Select;

const DoubleSearch = () => {

    function onChange(value) {
        console.log(`selected ${value}`);
      }
      
      function onBlur() {
        console.log('blur');
      }
      
      function onFocus() {
        console.log('focus');
      }
      
      function onSearch(val) {
        console.log('search:', val);
      }


    return (
        <StyledContainer>
          <div className="title">
          <h1>შეთავაზებები</h1>
          
          </div>
          <ScrollAnimation animateIn="fadeIn">
              <Select
                showSearch
                style={{ width: 230 }}
                placeholder="აირჩიე ქალაქი / რაიონი"
                optionFilterProp="children"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
                <Option value="tom">Tom</Option>
            </Select>
            </ScrollAnimation>
        </StyledContainer>
    )
}

export default DoubleSearch
