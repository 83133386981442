import styled from 'styled-components'

export const StyledContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin-left:0px;
    padding-top:20px;
    width:760px;
    margin:0 auto;
    max-width:100%;
    @media (max-width:682px) {
        flex-direction:column;
    }
    .ant-select-selector {
        height:40px !important;
    }
    .ant-input {
        height:40px !important;
    }
    .ant-btn {
        height:40px !important;
        width: 40px !important;
    }
    .select_btn {
        width:140px !important;
        font-family: "BOG 2017 Headline";
    font-size: 12px;
    background: rgb(63,121,186);
background: linear-gradient(90deg, rgba(63,121,186,1) 0%, rgba(0,69,146,1) 100%);
    color:#fff;
    }
    .ant-select-selection-item {
        line-height:40px !important;
    }
    .ant-select-selection-placeholder {
        line-height:40px !important;
    }
    .sec {
        margin:1px;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        h5 {
            height: 15px;
            font-family: "BOG 2017 Headline";
    font-size: 12px;
    padding-bottom:3px;
    font-weight:400;
    color:#7f848a;
        }
        .ant-input::placeholder {
            font-size:12px;
        }
    }
`

export const StyledImg = styled.div`
    width:100%;
    height:100vh;
    background-color:#fff;
    position:fixed;
    z-index:${p => p.show ? "1000" : "-1"};;
    top:0;
    display:flex;
    justify-content:center;
    align-items:center;
    transition:300ms;
    opacity: ${p => p.show ? "0.9" : "0"};

    img {
        width:200px;
    }

`

export const StyledNumberInfo = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:747px;
    max-width:100%;
    margin:0 auto;
    margin-top:10px;
    .ant-input {
        height:40px !important;
        margin:1px;
        font-size:12px;
    }
    .ant-btn {
        height:40px !important;
        width: 40px !important;
    }
    .select_btn {
        font-family: "BOG 2017 Headline";
    font-size: 12px;
    background-color:#95de64;
    color:#fff;
    border:0;
    border-radius:1px;
    height:39px;

    }
`