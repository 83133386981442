import React from 'react'

// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import AboutLayoute from "../Lcomponents/About/AboutLayoute";
import Slider from "../Lcomponents/Slider/Slider";
import Footer from "../Lcomponents/Footer/Footer";

const About = () => {
    return (
        <div>
                 <Navbar />
      <Menu />
      <Slider />
      <AboutLayoute />
      <Footer /> 
        </div>
    )
}

export default About
