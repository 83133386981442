import styled from "styled-components";

export const StyledBar = styled.div`
  width: 885px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #f7f7f7;
  padding: 5px;
  margin-top: 25px;
  border-radius: 3px;
  @media (max-width: 1000px) {
    justify-content: space-between;
    margin: 10px;
    width: 95%;
  }
  @media (max-width: 752px) {
    flex-direction: column;
  }
  .ant-input {
    &::placeholder {
      font-size: 12px;
      color: #ccc;
    }
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  @media (max-width: 520px) {
    justify-content: center;
    align-items: center;
  }
  .ant-picker-input > input:placeholder-shown {
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .ant-picker-input > input {
    font-size: 12px;
  }
`;

export const StyledNumberBox = styled.div`
  width: 250px;
  height: 140px;
  transition: 300ms;
  border: 2px solid #f7f7f7;
  padding: 10px;
  border-radius: 3px;
  margin-right: 10px;
  overflow: hidden;
  @media (max-width: 520px) {
    margin-top: 10px;
  }
`;

export const StyledHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    color: #5f656c;
  }
  
  .edit__button {
    background: transparent;
    border: 0;
    color: #5f656c;
    cursor: pointer;
    padding: 4px;
    &:focus {
      outline: none;
    }
  }
`;

export const StyledBody = styled.div`
  padding-top: 20px;
  .edit__password {
    border: 0;
    border-bottom: 2px solid #f7f7f7;
    width: 100%;
    padding: 0px;
    border-radius: 3px;
    margin-top: 5px;
    text-align: right;
    font-size: 12px;
    color: #5f656c;
    &::placeholder {
      font-size: 12px;
    }
    &:focus {
      outline: none;
    }
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(p) => p.column && `flex-direction:column;`};
  h4 {
    font-size: 12px;
    color: #5f656c;
  }
  button {
    font-size: 12px;
    color: #5f656c;
    background:transparent;
    border:0;
    cursor:pointer;
  }
  .blue {
    color: #004592;
  }
  .edit__input {
    border: 2px solid #f7f7f7;
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    margin-top: 5px;

    &::placeholder {
      font-size: 12px;
      color: #ccc;
    }
  }
  .edit__button {
    width: 100%;
    border-radius: 3px;
    border: 0;
    background: #eb0028;
    color: #fff;
    padding: 8px;
    transition: 300ms;
    font-family: "BOG 2017 Headline";
    margin-top: 10px;
    &:hover {
      color: #ccc;
    }
  }
`;
