import React from "react";
// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Footer from "../Lcomponents/Footer/Footer";
import Slider from "../Lcomponents/Slider/Slider";
import Desc from "../Lcomponents/AuthorizationDesc/Desc";

const AuthDescription = () => {
  return (
    <div>
      <Navbar />
      <Menu />
      <Slider />
      <Desc />
      <Footer />
    </div>
  );
};

export default AuthDescription;
