import styled from 'styled-components'

export const StyledContainer = styled.div`
    background-color:#fafbff;
    margin-bottom:20px;
    padding:10px;
    padding-bottom:70px;
    .hero__title {
        text-align:center;
        font-family: "BOG 2017 Headline";
        padding-top:30px;
        padding-bottom:30px;
        color:#0e4c90;
        font-size:18px;
        
    }
`

export const StyledNewsContainer = styled.div`
       width:1050px;
    max-width:100%;
    margin:0 auto;
    display:flex;
    justify-content:space-between;
    align-items:center;
    flex-wrap:wrap;
    @media (max-width:1145px) {
        width:1000px;
    }
    @media (max-width:980px) {
        justify-content:center;
    }
`

export const StyledNews = styled.div`
    width:230px;
    height:170px;
    position:relative;
    overflow:hidden;
    transition:300ms;
    border-radius:3px;
    cursor:pointer;
    @media (max-width:1145px) {
        margin:20px;
    }
    img {
        position:absolute;
        left:0;
        top:0;
        width:100%;
        filter:brightness(80%);
        transition:300ms;
    }
    h4 {
        position:absolute;
        bottom:0;
        left:10px;
        color:#fff;
        font-family: "BOG 2017 Headline";
        font-size:12px;
        z-index:10;
        transition:300ms;
        padding:5px;
        &::before {
            content:"";
            width:280px;
            height:100%;
            background-color:#0e4c90;
            position:absolute;
            left:-300px;
            top:0;
            z-index:-1;
            transition:300ms;
        }
    }
    &:hover {
        img {
            transform:scale(1.1);
            filter:brightness(100%);
        }
        h4 {
            bottom:10px;
            &::before {
                left:-10px;
            }
        }
    }
`