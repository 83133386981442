import axios from "axios";

// Actions

export const userCityAction = (data) => {
  return { type: "USER_CITY", payload: data };
};

export const userAddressAction = (data) => {
  return { type: "USER_ADDRESS", payload: data };
};

const fetchUserAddressSuccess = (data) => {
  return { type: "FETCH_USER_ADDRESS_SUCCESS", payload: data ? data : [] };
};

const deleteUserAddressAction = (id) => {
  return { type: "DELETE_USER_ADDRESS", payload: id };
};

export const createUserAddress = (cityy, addresss) => {
  return function (dispatch, getState) {
    const userId = localStorage.getItem("userId");
    const { city, address } = getState().userAddress;
    axios.post(process.env.REACT_APP_API_URL, {
      lang: "ka",
      base: "users",
      function: "address_add",
      pars: {
        account_id: userId,
        area: "1",
        address: `${city ? city : cityy}, ${address ? address : addresss}`,
        notes: "",
        token: localStorage.getItem("token"),
      },
      apikey: process.env.REACT_APP_API_KEY,
    });

}
};

export const deleteUserAddress = (id) => {
  return function (dispatch, getState) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "address_delete",
        pars: {
          id: id,
          token: localStorage.token,
          account_id: localStorage.getItem("userId"),
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then(() => dispatch(deleteUserAddressAction(id)));
  };
};

export const editUserAddress = (id,address) => {
  return function (dispatch, getState) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "address_update",
        pars: { id: id, account_id: localStorage.getItem("userId"), token: localStorage.getItem("token") },
        newpars: {
          account_id: localStorage.getItem("userId"),
          area: "1",
          address: address,
          notes: "ფიზიკური ტესტ",
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch({type:"EDIT_ADDRESS"})
      });
  };
};

export const fetchUserAddress = () => {
  return function (dispatch, getState) {
    const userId = localStorage.getItem("userId");
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "address_list",
        pars: { account_id: userId, token: localStorage.getItem("token") },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch(fetchUserAddressSuccess(res.data.data));
      });
  };
};
// state
const initialState = {
  city: "",
  address: "",
  addressItems: [],
};

export const userAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_ADDRESS":
      return state;
    case "USER_CITY":
      return { ...state, city: action.payload };
    case "USER_ADDRESS":
      return { ...state, address: action.payload };
    case "FETCH_USER_ADDRESS_SUCCESS":
      return { ...state, addressItems: action.payload };
    case "EDIT_ADDRESS":
      return state
    case "DELETE_USER_ADDRESS":
      return {
        ...state,
        addressItems: state.addressItems.filter((x) => x !== action),
      };
    default:
      return state;
  }
};
