import React from 'react'
// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Footer from "../Lcomponents/Footer/Footer";
import Slider from "../Lcomponents/Slider/Slider";
import Layoute from "../Lcomponents/Contracts/Layoute";

const Contracts = () => {
    return (
        <div>
                 <Navbar />
            <Menu />
            <Slider/>
            <Layoute />
            <Footer/>
        </div>
    )
}

export default Contracts
