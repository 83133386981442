import axios from "axios";

const initialState = {
  countryItems: [],
  filteredCountryItems: {},
};

// fetch data
const countryItems = (data) => {
  return { type: "COUNTRY_ITEMS", payload: data };
};

export const fetchCountryItems = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "info",
        function: "int_cost_info",
        pars: {"lang": "ge"},
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch(countryItems(res.data.data))
      });
  };
};

// filter data
const countryItemsFilter = (data) => {
  return { type: "COUNTRY_ITEMS_FILTER", payload: data };
};

export const fetchCountryItemsFilter = (id) => {

  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "info",
        function: "int_cost_info",
        pars: { id: id, lang: "eng" },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch(countryItemsFilter(res.data.data))
      });
  };
};

export const alphabetReducer = (state = initialState, action) => {
  switch (action.type) {
    case "COUNTRY_ITEMS":
      return { ...state, countryItems: action.payload };
    case "COUNTRY_ITEMS_FILTER":
      return { ...state, filteredCountryItems: action.payload };
    default:
        return state
        
  }
};
