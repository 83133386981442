import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserAddOutlined, DeleteOutlined } from "@ant-design/icons";
import { Drawer, Input, message, Checkbox, Popconfirm } from "antd";
import {
  resetHandler,
  fetchContact,
  deleteContact,
  deleteAction,
} from "../../../../../redux/reducers/dashboard/userContacts";
import {
  remoteHandler,
  fetchPersonSms,
} from "../../../../../redux/reducers/dashboard/sms";

import AddContactDrawer from "./AddContactDrawer";
import {
  StyledContactImage,
  StyledContactItem,
  StyledSmsSendButton,
  StyledButton,
  StyledBar,
  StyledCheckbox,
} from "./Styles/TelephoneDrawer";

const { Search } = Input;

const TelephoneDrawer = ({
  telephoneDrawer,
  showTelephoneDrawer,
  closeTelephoneDrawer,
  setContactItemHandler,
  contacts,
}) => {
  const [match, setMatch] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const [contactDrawer, setContactDrawer] = useState(false);
  const dispatch = useDispatch();
  const { list, listOfDeleteId } = useSelector((state) => state.userContacts);
  const { localNumber } = useSelector((state) => state.sms);

  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onCheckAllChange = (e) => {};

  const addContactDrawerVisible = () => {
    setContactDrawer(!contactDrawer);
  };

  const closeDrawerHandler = (e) => {
    setContactDrawer(false);
    dispatch(resetHandler());
  };

  const deleteContactHandler = (id) => {
    dispatch(deleteContact(id));
  };

  function confirm(e) {
    console.log(e);
    message.success("Click on Yes");
  }

  function cancel(e) {
    message.error("მოქმედება გაუქმებულია");
  }

  let x = window.matchMedia("(max-width: 700px)");

  function myFunction(x) {
    if (x.matches) {
      // If media query matches
      setMatch(true);
    } else {
      setMatch(false);
    }
  }

  const matched = match ? 340 : 520;
  useEffect(() => {
    dispatch(fetchContact());
    myFunction(x);
  }, []);
  return (
    <Drawer
      title="კონტაქტები"
      placement="right"
      closable={false}
      onClose={closeTelephoneDrawer}
      visible={telephoneDrawer}
      width={matched}
    >
      <Drawer
        width={300}
        visible={contactDrawer}
        onClose={closeDrawerHandler}
        title="კონტაქტის დამატება"
        placement="right"
      >
        <AddContactDrawer closeDrawerHandler={closeDrawerHandler} />
      </Drawer>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "3px",
        }}
      >
        <Search
          placeholder="კონტაქტის ძებნა"
          onSearch={(value) => console.log(value)}
          style={{ width: 240 }}
        />
        <StyledButton red={false} onClick={addContactDrawerVisible}>
          <UserAddOutlined  />{" "}
          დამატება
        </StyledButton>

        <StyledButton
          red={true}
          onClick={() => dispatch(deleteContact(listOfDeleteId))}
        >
          <DeleteOutlined  />{" "}
          წაშლა
        </StyledButton>
      </div>

      {list?.map((item) => (
        <StyledContactItem key={item.mob}>
          <div className="left__side">
            <Checkbox
              onChange={(list) => {
                setCheckedList(checkedList.filter((x) => x.id !== item.id));
                return dispatch(deleteAction(item.id));
              }}
            ></Checkbox>
            <div className="nickname__number">
              <ul>
                <li>
                  {item.name} {item.surname}
                </li>
                <li>{item.mob}</li>
              </ul>
            </div>
          </div>
          <button
            onClick={async () => {
              if (localNumber) {
                await dispatch(remoteHandler(`995${item.mob}`));
                await dispatch(fetchPersonSms());
                return closeTelephoneDrawer();
              } else {
                message.warning("გთხოვთ აირჩიოთ თქვენი ნომერი.");
                return closeTelephoneDrawer();
              }
            }}
          >
            SMS გაგზავნა
          </button>
        </StyledContactItem>
      ))}
    </Drawer>
  );
};

export default TelephoneDrawer;
