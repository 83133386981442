import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SMSCalculator } from "./calculator";
import ReactScrollableFeed from "react-scrollable-feed";
import {
  MessageOutlined,
  DeleteOutlined,
  UserAddOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import {
  fetchNumber,
  fetchPersonSms,
  sendSms,
  remoteHandler,
} from "../../../../../../redux/reducers/dashboard/sms";

import {
  StyledButton,
  StyledInbox,
  StyledSent,
  StyledMessages,
  StyledMessage,
  StyledTextArea,
  StyledMessagesContainer,
  StyledInboxContainer,
  StyledInboxBar,
  StyledInboxDelete,
  StyledInboxContacts,
  StyledCheckbox,
  StyledDeleteButton,
  StyledContact,
} from ".././Styles/TelephoneStyles";

import receivedMp3 from "../../../../../../assets/sound/received.mp3";

const Chat = () => {
  const dispatch = useDispatch();
  const {
    sms_contact,
    sms,
    remoteNumber,
    smsReceived,
    freesms,
    sms_free_sent_today,
    freeSmsSender,
  } = useSelector((state) => state.sms);
  const [messagee, setMessage] = useState("");
  const [keyCode, setKeyCode] = useState(false);
  const [calculate, setCalculate] = useState({
    maxCharCount: 0,
    numberOfSms: 0,
    remaining: 0,
    totalLength: 0,
  });
  const [textAreaLength, setTestAreaLength] = useState(0);
  const [visible, setVisible] = useState(false);
  const [contact, setContact] = useState(null);

  function sendMessage(e) {
    e.preventDefault();
    dispatch(sendSms(messagee));
    setMessage("");
    dispatch(fetchPersonSms());
    setCalculate({
      maxCharCount: 0,
      numberOfSms: 0,
      remaining: 0,
      totalLength: 0,
    });
    message.success("შეტყობინება წარმატებით გაიგზავნა");
  }

  function handleChange(e) {
    setTestAreaLength(e.target.value.length);
    const calc = SMSCalculator.getCount(e.target.value);
    setCalculate({
      maxCharCount: calc.maxCharCount,
      numberOfSms: calc.numberOfSMS,
      remaining: calc.remaining,
      totalLength: calc.totalLength,
    });
    if (calculate.numberOfSms === 6) {
      keyCode && setMessage(e.target.value);
    } else {
      setMessage(e.target.value);
      setKeyCode(false);
    }
  }

  const onKeyDown = function (e) {
    if (e.keyCode === 8) {
      setKeyCode(true);
    } else {
      setKeyCode(false);
    }
  };

  const showDrawer = async (item) => {
    setVisible(true);
    setContact(item);
  };

  const changeDetectionFilter = (previousProps, newProps) => {
    const prevChildren = previousProps.children;
    const newChildren = newProps.children;

    return (
      prevChildren !== newChildren &&
      prevChildren[prevChildren.length - 1] !==
        newChildren[newChildren.length - 1]
    );
  };

  useEffect(() => {
    dispatch(fetchNumber());
    const interval = setInterval(() => {
      remoteNumber && dispatch(fetchPersonSms());
    }, 2000);
    return () => clearInterval(interval);
  }, [sms]);

  return (
    <div>
      <div>
        <audio autoPlay={smsReceived}>
          <source src={receivedMp3} type="audio/mp3"></source>
        </audio>
      </div>
      <StyledMessagesContainer>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <StyledMessages>
            <ReactScrollableFeed changeDetectionFilter={changeDetectionFilter}>
              {sms.map((message, index) => {
                if (message.direction === "outbound") {
                  return (
                    <StyledSent key={index}>
                      <StyledMessage sender={true}>
                        <p>{message.msg}</p>
                        <h6>
                          გამავალი {`>`} {message.ts}
                        </h6>
                      </StyledMessage>
                    </StyledSent>
                  );
                }
                return (
                  <StyledInbox key={index}>
                    <StyledMessage sender={false}>
                      <p>{message.msg}</p>
                      <h6>
                        შემომავალი {`<`} {message.ts}
                      </h6>
                    </StyledMessage>
                  </StyledInbox>
                );
              })}
            </ReactScrollableFeed>
          </StyledMessages>
          <form>
            <StyledTextArea
              onChange={handleChange}
              onKeyDown={onKeyDown}
              value={messagee}
              placeholder="აკრიბეთ ტექსტი..."
            />
            <div className="character__length">
              <div>
                <h5>სიმბოლოების რაოდენობა: {messagee.length}</h5>
                <h5>შეტყობინების რაოდენობა: {calculate.numberOfSms}</h5>
              </div>

              <StyledButton large={true} onClick={sendMessage}>
                გაგზავნა
              </StyledButton>
            </div>
          </form>
        </div>
        <StyledInboxContainer>
          <StyledInboxBar>
            <div className="message">
              <MessageOutlined style={{ fontSize: "20px", color: "#fff" }} />
              <h4>შეტყობინებები</h4>
            </div>
            <input placeholder="ძებნა" />
          </StyledInboxBar>
          <h4 className="freesms">
            დარჩენილი უფასო sms რაოდენობა:{" "}
            {!freeSmsSender
              ? freesms - sms_free_sent_today
              : 50 - freeSmsSender}
          </h4>
          <StyledInboxDelete>
            <StyledCheckbox absolute={false}>
              <label className="container">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    let checked = e.target.checked;
                  }}
                />
                ყველას მონიშვნა
                <span className="checkmark"></span>
              </label>
            </StyledCheckbox>
            <StyledDeleteButton>
              <DeleteOutlined /> წაშლა
            </StyledDeleteButton>
          </StyledInboxDelete>
          <StyledInboxContacts>
            {sms_contact?.map((item, index) => {
              return (
                <StyledContact key={index} active={remoteNumber === item}>
                  <div
                    className="avatar__content"
                    onClick={() => {
                      dispatch(remoteHandler(item));
                      return dispatch(fetchPersonSms(item));
                    }}
                  >
                    <div className="avatar"></div>
                    <div className="user">
                      <StyledCheckbox absolute={false}>
                        <label className="container">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              let checked = e.target.checked;
                              /**    setContact(
                      contacts.filter((item) => {
                        item.select = checked;
                        return item;
                      })
                    ); */
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </StyledCheckbox>
                      <div className="number">
                        <h4>{item}</h4>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="user__add"
                      onClick={() => showDrawer(item)}
                    >
                      <UserAddOutlined style={{ fontSize: "18px" }} />
                    </button>
                  </div>
                </StyledContact>
              );
            })}
          </StyledInboxContacts>
        </StyledInboxContainer>
      </StyledMessagesContainer>
    </div>
  );
};

export default Chat;
