import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import App from "./App";
// React Redux
import {Provider} from 'react-redux'
import {store} from './redux/store'

import "./index.css";

import "./i18next";

//compose( window.devToolsExtension ? window.devToolsExtension() : f => f)

ReactDOM.render(
  <Suspense fallback={<div>იტვირთება...</div>}>
    <Provider store={store}> 
      <App />
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
