import axios from "axios";

// ტელეფონის ნომრები
export const telephoneNumbers = (numbers) => {
  return {
    type: "TELEPHONE_NUMBERS",
    payload: numbers,
  };
};

export const fetchTelephoneNumbers = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "info",
        function: "list_numbers",
        pars: { limit: "",number: "99532%", inuse: "0", in_order: "0" },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch(telephoneNumbers(res.data.data))
      });
  };
};

// ქვეყნები
export const countryList = (data) => {
  return {
    type: "COUNTRY_LIST",
    payload: data,
  };
};

export const fetchCountryList = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "info",
        function: "list_areacodes",
        pars: { country_code: "995", in_range: 1},
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch(countryList(res.data.data))
      });
  };
};

// ტელეფონის ნომრების ფასები
export const telephoneNumbersPrice = (data) => {
  return {
    type: "TELEPHONE_NUMBERS_PRICE",
    payload: data,
  };
};

export const fetchTelephoneNumbersPrice = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "info",
        function: "list_voip_price",
        pars: {},
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch(telephoneNumbersPrice(res.data.data))
      });
  };
};

// Filters >> Filters >> Filters

// ნომრების გაფილტვრა ფასით
export const telephoneNumbersFilterByPrice = (data, value) => {
  return {
    type: "TELEPHONE_NUMBERS_FILTER_PRICE",
    payload: data,
    price: value,
  };
};

export const fetchTelephoneNumbersFilterByPrice = (value) => {
  return function (dispatch, getState) {
    const { number, pagination } = getState().telephoneNumbers;
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "info",
        function: "list_numbers",
        pars: {
          number: `995${number === null ? "" : number}%`,
          buy_cost: value,
          limit: pagination === null ? 2000 : pagination,
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) =>{
        dispatch(telephoneNumbersFilterByPrice(res.data.data, value))
      }
      );
  };
};

// ნომრების გაფილტვრა ქვეყნებით
export const telephoneNumbersFilterByCountry = (data, value) => {
  return {
    type: "TELEPHONE_NUMBERS_FILTER_COUNTRY",
    payload: data,
    number: value,
  };
};

export const fetchTelephoneNumbersFilterByCountry = (value) => {
  return function (dispatch, getState) {
    const { pagination, cost } = getState().telephoneNumbers;
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "info",
        function: "list_numbers",
        pars: {
          number: `995${value}%`,
          buy_cost: cost,
          limit: pagination === null ? 2000 : pagination,
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) =>{
        dispatch(telephoneNumbersFilterByCountry(res.data.data, value))
      }
      );
  };
};

// პაგინაცია
export const telephoneNumbersPagination = (data, value) => {
  return {
    type: "TELEPHONE_NUMBERS_PAGINATION",
    payload: data,
    pagination: value,
  };
};

export const fetchTelephoneNumbersPagination = (value) => {
  return function (dispatch, getState) {
    const { cost,number } = getState().telephoneNumbers;
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "info",
        function: "list_numbers",
        pars:
          number && cost
            ? {
                number: `995${number}%`,
                buy_cost: cost,
                limit: value,
                in_order: 0,
              }
            : number
            ? { number: `995${number}%`, limit: value, in_order: 0 }
            : cost
            ? { buy_cost: cost, limit: value, in_order: 0 }
            : { number: `995%`, limit: value, in_order: 0 },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) =>
        dispatch(telephoneNumbersPagination(res.data.data, value))
      );
  };
};

export const telephoneNumbersSearch = (data) => {
    return {
        type: "TELEPHONE_NUMBERS_SEARCH",
        payload:data
    }
}

export const fetchTelephoneNumbersSearch = (value) => {
    return function (dispatch, getState) {
      const { number } = getState().telephoneNumbers;
      value.length? 
      axios
        .post(process.env.REACT_APP_API_URL, {
            lang: "ka",
            base: "info",
            function: "list_numbers",
            pars: {
              start: `995${number}${value}`,
              end: `995${number}${value}`,
              limit: 2000,
              inuse: 0,
              in_order: 0,
            },
            apikey: process.env.REACT_APP_API_KEY,
          })
        .then((res) =>
          dispatch(telephoneNumbersSearch(res.data.data))
        )
        : dispatch(fetchTelephoneNumbers())
    };
  };

const initialState = {
  telephoneNumbers: [],
  countryList: [],
  telephoneNumbersPrice: [],
  number: 32,
  pagination: null,
  cost: null,
};

export const numbersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TELEPHONE_NUMBERS":
      return { ...state, telephoneNumbers: action.payload };
    case "COUNTRY_LIST":
      return { ...state, countryList: action.payload };
    case "TELEPHONE_NUMBERS_PRICE":
      return {
        ...state,
        telephoneNumbersPrice: action.payload,
      };
    case "TELEPHONE_NUMBERS_FILTER_PRICE":
      return {
        ...state,
        telephoneNumbers: action.payload,
        cost: action.price,
      };
    case "TELEPHONE_NUMBERS_FILTER_COUNTRY":
      return {
        ...state,
        telephoneNumbers: action.payload,
        number: action.number,
      };
      case "TELEPHONE_NUMBERS_SEARCH":
        return {
          ...state,
          telephoneNumbers: action.payload,
        };

      case "TELEPHONE_NUMBERS_PAGINATION":
        return {
          ...state,
          telephoneNumbers: action.payload,
          pagination: action.pagination,
        };
    default:
      return state;
  }
};
