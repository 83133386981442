import axios from "axios";


export const addOrder = ( address,order_id,category, phone) => {
  console.log(phone)
  return function (dispatch,getState) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "orders",
        function: "order_add",
          pars: {
            account_id: localStorage.getItem("userId"),
            category: category,
            product_id: order_id,
            order_address: address,
            info: {voip_number: phone.number, price: phone.buy_cost},
            token: localStorage.getItem("token")
          },
        apikey: process.env.REACT_APP_API_KEY,
      }).then((res) => console.log({
        account_id: localStorage.getItem("userId"),
        category: category,
        product_id: order_id,
        order_address: address,
        info: {voip_number: phone.number, price: phone.buy_cost},
        token: localStorage.getItem("token")
      }))
  };
};
