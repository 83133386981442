import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  handler,
  fetchContactCategory,
  createContact,
  fetchContact,
} from "../../../../../redux/reducers/dashboard/userContacts";

import { StyledFormGroup, StyledButton } from "./Styles/AddContactDrawer";

const { Option } = Select;

const AddContactDrawer = ({ closeDrawerHandler }) => {
  // dispatch
  const dispatch = useDispatch();
  // selectors
  const { name, surname, mobile } = useSelector((state) => state.userContacts);
  // inputs handler

  const eventHandler = (e) => {
    dispatch(handler(e.target.name, e.target.value));
  };

  const createContactHandler = async (e) => {
    e.preventDefault();

    await dispatch(createContact());
    await dispatch(fetchContact());
    closeDrawerHandler();
  };

  useEffect(() => {
    dispatch(fetchContactCategory());
  }, []);
  return (
    <form onSubmit={createContactHandler}>
      <StyledFormGroup>
        <label>სახელი</label>
        <input
          placeholder="შეიყვანეთ სახელი"
          value={name}
          name="name"
          onChange={eventHandler}
          maxLength="16"
          required
        />
      </StyledFormGroup>

      <StyledFormGroup>
        <label>გვარი</label>
        <input
          placeholder="შეიყვანეთ გვარი"
          value={surname}
          name="surname"
          onChange={eventHandler}
          maxLength="22"
        />
      </StyledFormGroup>

      <StyledFormGroup>
        <label>ნომერი</label>
        <input
          placeholder="შეიყვანეთ მობილურის ნომერი"
          name="mobile"
          value={mobile}
          max="12"
          maxLength="12"
          pattern="\d*"
          type="text"
          required
          onChange={eventHandler}
        />
      </StyledFormGroup>

      <StyledFormGroup buttons={true}>
        <StyledButton red={true} onClick={closeDrawerHandler}>
          გაუქმება
        </StyledButton>
        <StyledButton red={false} type="submit">
          დამატება
        </StyledButton>
      </StyledFormGroup>
    </form>
  );
};

export default AddContactDrawer;
