import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 890px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width:100%;
  @media (max-width: 1160px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
   width: 100%;
  }
`;

export const StyledServicesBox = styled.div`
  width: 275px;
  height:180px;
  max-width: 100%;
  border: 2px solid #f7f7f7;
  border-radius: 6px;
  padding: 10px;
  background: #fff;
  position: relative;
  padding-bottom: 0;
  margin-right: 18px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow:hidden;

`;

export const StyledServicesTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:100%;
  svg {
    path {
      font-size: 30px;
    }
  }
  h4 {
    font-family: "BOG 2017 Headline";
    color: rgba(63,121,186,1);
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;

  }
  p {
    font-family: "BOG 2018";
    font-size: 11px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    display:flex;
  }
`;

export const StyledPacketList = styled.ul``;

export const StyledPacket = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  color: #343e73;
  border: 1px solid #f7f7f7;
  margin-top: 5px;
  padding: 10px;
  border-radius: 3px;
  font-size: 11px;
  width: 100%;
  span {
    color: #5f656c;
  }
`;

export const StyledDeleteAddress = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index:11;
`;

export const StyledControll = styled.ul`
  position: absolute;
  background-color: #ffffffec;
  left: 0;
  top: 0px;
  width: 100%;
  height: 140px;
  border-radius: 3px;
  transition:300ms;
  margin-left: ${(p) => (p.activeControll ? "0px" : "-275px")};
  display:flex;
  justify-content:center;
  align-items:center;
  li {
    list-style: none;
    padding: 5px;
    font-size: 12px;
    button {
      padding:8px;
      border: 1px solid #ccc;
      background-color:#fff;
      border-radius:3px;
      cursor:pointer;
      width:120px;
      color:rgba(63,121,186,1);
      transition:300ms;
      font-family: "BOG 2017 Headline";
      font-size:12px;
      &:hover {
        background:linear-gradient(90deg,rgba(63,121,186,1) 0%,rgba(0,69,146,1) 100%) ;
        color:#fff;
        border:1px solid rgba(63,121,186,1);
      }
    }
  }
`;
