import React from "react";
// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Slider from '../Lcomponents/Slider/Slider'
import Footer from "../Lcomponents/Footer/Footer";
import NewsContent from "../Lcomponents/News/NewsContent";
import FilterBar from "../Lcomponents/News/FilterBar";

const NewsContentItem = () => {
  return (
    <div>
      {/* ნავიგაცია */}
      <Navbar />
      {/* მენიუ */}
      <Menu />
      {/* hero სექცია */}
      <Slider />
      <FilterBar />
        <NewsContent />
      {/* ფუთერი */}
      <Footer />
    </div>
  );
};

export default NewsContentItem;
