import axios from "axios";

export const fetchExtractionData = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "voip_cdr",
        pars: {
          product_index: 1,
          start: "2021-03-20 00:00:00",
          end: "2021-04-27 23:59:00",
          account_id: localStorage.getItem("userId"),
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch({
          type: "FETCH_EXTRACTION",
          payload: res.data.data,
          bonus: res.data.bonus,
          spent: res.data.spent,
        });
      });
  };
};

export const fetchNumber = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "voip_list",
        pars: { account_id: localStorage.getItem("userId"), status: "1" },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        const data = res.data.data;
        dispatch({
          type: "FETCH_NUMBER",
          payload: data,
          defaultValue: {id:data[0].acc_product_id, number: data[0].sip_username}
        });
      });
  };
};

export const fetchFilteredExtractionData = (id,start,end,direction) => {
  console.log(id,start,end,direction)
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "voip_cdr",
        pars: {
          account_id: localStorage.getItem("userId"),
          acc_product_id: id,
          start: `${start} 00:00:00`,
          end: `${end} 23:59:00`,
          token: localStorage.getItem("token"),
          call_direction: direction,
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        if(!res.data.data) {
          dispatch({
            type: "FETCH_FILTERED_EXTRACTION",
            payload: [],
            bonus: 0,
            spent: 0,
          });
        } else { 
        dispatch({
          type: "FETCH_FILTERED_EXTRACTION",
          payload: res.data.data,
          bonus: res.data.bonus,
          spent: res.data.spent,
        });
      }
      });

  };
};

const initialState = {
  data: [],
  numbers: [],
  defaultNumber:{id: "", number: ""},
  bonus: null,
  spent: null,
};
export const extractionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_EXTRACTION":
      return {
        ...state,
        data: action.payload,
        bonus: action.bonus,
        spent: action.spent,
      };
      case "FETCH_FILTERED_EXTRACTION":
        return {...state, data:action.payload, bonus: action.bonus,spent: action.spent}
    case "FETCH_NUMBER":
      return {...state, numbers: action.payload,defaultNumber: action.defaultValue }
    default:
      return state;
  }
};
