import React from "react";

import {
  StyledContainer,
  StyledItem,
  StyledContent,
  StyledBottomContent
} from "./styles/NewsContainer";
const NewsContainer = () => {
  const truncate = (text) => text.length > 300 ? `${text.substring(0, 300)}...` : text;
  const truncateTitle = (text) => text.length > 50 ? `${text.substring(0, 50)}...` : text;
  return (
    <StyledContainer>
      <StyledItem>
        <StyledContent>
          <div className="image">
            <img alt="" src="http://localhost:3000/static/media/logo.04de3a7a.png" />
          </div>
          <h3>{truncateTitle("შპს „ტელეკომ 1”-ისთვის ნუმერაციის რესურსით სარგებლობის უფლების მინიჭების შესახებ")}</h3>
          <p>{truncate("საქართველოს კომუნიკაციების კომისიამ 2020 წლის 26 მარტს, შპს ტელეკომ 1-ს ფიქსირებული ელექტრონული საკომუნიკაციო ქსელებით და საშუალებებით მომსახურების ორგანიზებისათვის და ამ ქსელების აბონენტების ტერმინალური მოწყობილობების იდენტიფიცირებისათვის მიანიჭა ნუმერაციის   რესურსით   სარგებლობის   №532 უფლება.")}</p>
        </StyledContent>
        <StyledBottomContent>
           <span>Mar 26, 2020</span>
           <button>სრულად</button>
          </StyledBottomContent>
      </StyledItem>

      <StyledItem>
        <StyledContent>
          <div className="image">
            <img alt="" src="http://localhost:3000/static/media/logo.04de3a7a.png" />
          </div>
          <h3>{truncateTitle("შპს „ტელეკომ 1”-ისთვის ნუმერაციის რესურსით სარგებლობის უფლების მინიჭების შესახებ")}</h3>
          <p>{truncate("საქართველოს კომუნიკაციების კომისიამ 2020 წლის 26 მარტს, შპს ტელეკომ 1-ს ფიქსირებული ელექტრონული საკომუნიკაციო ქსელებით და საშუალებებით მომსახურების ორგანიზებისათვის და ამ ქსელების აბონენტების ტერმინალური მოწყობილობების იდენტიფიცირებისათვის მიანიჭა ნუმერაციის   რესურსით   სარგებლობის   № 531 უფლება.")}</p>
        </StyledContent>
        <StyledBottomContent>
           <span>Mar 26, 2020</span>
           <button>სრულად</button>
          </StyledBottomContent>
      </StyledItem>

      <StyledItem>
        <StyledContent>
          <div className="image">
            <img alt="" src="http://localhost:3000/static/media/logo.04de3a7a.png" />
          </div>
          <h3>{truncateTitle("შპს „ტელეკომ 1“-სთვის ფიქსირებული სატელეფონო კავშირით მომსახურებისთვის ნუმერაციის რესურსის - დამისამართების პრეფიქსის მინიჭების შესახებ")}</h3>
          <p>{truncate("საქართველოს კომუნიკაციების ეროვნულმა კომისიამ 2020 წლის 2 აპრილს, შპს ტელეკომ 1-ს ფიქსირებული სატელეფონო მომსახურების ორგანიზებისთვის ტერმინალური მოწყობილობებიდან სააბონენტო ნომრების პორტაბელობის მონაცემთა ცენტრალურ ბაზაში ზარის დამისამართებისა და იდენტიფიცირებისთვის მიენიჭა დამისამართების პრეფიქსი 7-5-4-4 (შვიდი- ხუთი-ოთხი-ოთხი)")}</p>
        </StyledContent>
        <StyledBottomContent>
           <span>Apr 2, 2020</span>
           <button>სრულად</button>
          </StyledBottomContent>
      </StyledItem>

      <StyledItem>
        <StyledContent>
          <div className="image">
            <img alt="" src="http://localhost:3000/static/media/logo.04de3a7a.png" />
          </div>
          <h3>{truncateTitle("შპს ,,ტელეკომ 1”-სთვის ნუმერაციის რესურსით სარგებლობის უფლების მინიჭების შესახებ")}</h3>
          <p>{truncate("საქართველოს კომუნიკაციების ეროვნულმა კომისიამ 2020 წლის 2 აპრილს, შპს ტელეკომ 1-ს მობილური ელექტრონული საკომუნიკაციო ქსელებით და საშუალებებით მომსახურების ორგანიზებისათვის და ამ ქსელების აბონენტების ტერმინალური მოწყობილობების იდენტიფიცირებისათვის მიანიჭა ნუმერაციის რესურსით სარგებლობის N534 უფლება:1. გეოგრაფიული ზონა: საქართველო, ნუმერაციის რესურსი: 550550000 - 550554999, სულ 5000 (ხუთი ათასი) სააბონენტო ნომერი")}</p>
        </StyledContent>
        <StyledBottomContent>
           <span>Apr 2, 2020</span>
           <button>სრულად</button>
          </StyledBottomContent>
      </StyledItem>

      <StyledItem>
        <StyledContent>
          <div className="image">
            <img alt="" src="http://localhost:3000/static/media/logo.04de3a7a.png" />
          </div>
          <h3>{truncateTitle("საქართველოს კომუნიკაციების ეროვნული კომისიის 2019 წლის 28 მარტის N179/15 გადაწყვეტილების ძალადაკარგულად გამოცხადების და შპს „ტელეკომ 1”-სთვის მოძრავი ელექტრონული საკომუნიკაციო ქსელებით და საშუალებებით მომსახურების ორგანიზებისათვის ნუმერაციის რესურსის - დამისამართების პრეფიქსის მინიჭების შესახებ")}</h3>
          <p>{truncate("საქართველოს კომუნიკაციების ეროვნულმა კომისიამ 2020 წლის 2 აპრილს, შპს ტელეკომ 1-ს მობილური სატელეფონო მომსახურების ორგანიზებისთვის ტერმინალური მოწყობილობებიდან სააბონენტო ნომრების პორტაბელობის მონაცემთა ცენტრალურ ბაზაში ზარის დამისამართებისა და იდენტიფიცირებისთვის მიანიჭა დამისამართების პრეფიქსი 7-3-8 (შვიდი- სამი-რვა)")}</p>
        </StyledContent>
        <StyledBottomContent>
           <span>Apr 2, 2020</span>
           <button>სრულად</button>
          </StyledBottomContent>
      </StyledItem>

      <StyledItem>
        <StyledContent>
          <div className="image">
            <img alt="" src="http://localhost:3000/static/media/logo.04de3a7a.png" />
          </div>
          <h3>{truncateTitle("საქართველოს კომუნიკაციების ეროვნული კომისიის 2019 წლის 28 მარტის N178/15 გადაწყვეტილების ძალადაკარგულად გამოცხადებისა და შპს „ტელეკომ 1“-სთვის მობილური საკომუნიკაციო ოპერატორის ქსელის კოდის (MNC) მინიჭების შესახებ")}</h3>
          <p>{truncate("2020 წლის 2 აპრილს, საქართველოს კომუნიკაციების ეროვნულმა კომისიამ, ტელეკომუნიკაციების საერთაშორისო კავშირის (ITU) E.212 და „ევროპის სატელეკომუნიკაციო სტანდარტების ინსტიტუტის“ („ETSI“) ETS 300 523 რეკომენდაციების შესაბამისად, მობილური ქსელის აბონენტების საერთაშორისო იდენტიფიცირებისთვის შპს ტელეკომ 1-ს მიანიჭა მობილური საკომუნიკაციო ქსელის კოდი (MNC) 282-12")}</p>
        </StyledContent>
        <StyledBottomContent>
           <span>Jun 11, 2020</span>
           <button>სრულად</button>
          </StyledBottomContent>
      </StyledItem>

      <StyledItem>
        <StyledContent>
          <div className="image">
            <img alt="" src="http://localhost:3000/static/media/logo.04de3a7a.png" />
          </div>
          <h3>{truncateTitle("შპს „ტელეკომ 1”-ისთვის ნუმერაციის რესურსით სარგებლობის უფლების მინიჭების შესახებ")}</h3>
          <p>{truncate("საქართველოს კომუნიკაციების კომისიამ 2020 წლის 11 ივნისს, შპს ტელეკომ 1-ს ტექსტური/მულტიმედიური შეტყობინებების ნომრებით (სტანდარტული ტარიფით) არასატელეკომუნიკაციო სერვისების მიწოდების უზრუნველსაყოფად მიანიჭა ნუმერაციის რესურსით სარგებლობის № N535 უფლება: 1. გეოგრაფიული ზონა: საქართველო, ნუმერაციის რესურსი: 91400-91409 (სულ ათი სააბონენტო ნომერი)")}</p>
        </StyledContent>
        <StyledBottomContent>
           <span>Jun 18, 2020</span>
           <button>სრულად</button>
          </StyledBottomContent>
      </StyledItem>

      <StyledItem>
        <StyledContent>
          <div className="image">
            <img alt="" src="http://localhost:3000/static/media/logo.04de3a7a.png" />
          </div>
          <h3>{truncateTitle("შპს „ტელეკომ 1”-ისთვის ნუმერაციის რესურსით სარგებლობის უფლების მინიჭების შესახებ")}</h3>
          <p>{truncate("საქართველოს კომუნიკაციების კომისიამ 2020 წლის 28 იანვარს, შპს ტელეკომ 1-ს ფიქსირებული ელექტრონული საკომუნიკაციო ქსელებით და საშუალებებით მომსახურების ორგანიზებისათვის და ამ ქსელების აბონენტების ტერმინალური მოწყობილობების იდენტიფიცირებისათვის მიანიჭა ნუმერაციის   რესურსით   სარგებლობის   №569 უფლება.")}</p>
        </StyledContent>
        <StyledBottomContent>
           <span>Jan 28, 2021</span>
           <button>სრულად</button>
          </StyledBottomContent>
      </StyledItem>

      <StyledItem>
        <StyledContent>
          <div className="image">
            <img alt="" src="http://localhost:3000/static/media/logo.04de3a7a.png" />
          </div>
          <h3>{truncateTitle("შპს „დატაკომისთვის“ ნუმერაციის რესურსით სარგებლობის უფლებების გაუქმებისა და შპს „ტელეკომ 1“-სთვის ნუმერაციის რესურსით სარგებლობის უფლებების მინიჭების შესახებ")}</h3>
          <p>{truncate("საქართველოს კომუნიკაციების კომისიამ 2020 წლის 26 ნოემბერს, შპს ტელეკომ 1-ს ფიქსირებული ელექტრონული საკომუნიკაციო ქსელებით და საშუალებებით მომსახურების ორგანიზებისათვის და ამ ქსელების აბონენტების ტერმინალური მოწყობილობების იდენტიფიცირებისათვის მიანიჭა ნუმერაციის   რესურსით   სარგებლობის   № 558, 559, 560  უფლება.")}</p>
        </StyledContent>
        <StyledBottomContent>
           <span>Nov 26, 2020</span>
           <button>სრულად</button>
          </StyledBottomContent>
      </StyledItem>

      <StyledItem>
        <StyledContent>
          <div className="image">
            <img alt="" src="http://localhost:3000/static/media/logo.04de3a7a.png" />
          </div>
          <h3>{truncateTitle("შპს „ტელეკომ 1”-ისთვის ნუმერაციის რესურსით სარგებლობის უფლების მინიჭების შესახებ")}</h3>
          <p>{truncate("საქართველოს კომუნიკაციების კომისიამ 2020 წლის 25 ივნისს, შპს ტელეკომ 1-ს ფიქსირებული ელექტრონული საკომუნიკაციო ქსელებით და საშუალებებით მომსახურების ორგანიზებისათვის და ამ ქსელების აბონენტების ტერმინალური მოწყობილობების იდენტიფიცირებისათვის მიანიჭა ნუმერაციის   რესურსით   სარგებლობის   № 537 უფლება.")}</p>
        </StyledContent>
        <StyledBottomContent>
           <span>Jun 25, 2020</span>
           <button>სრულად</button>
          </StyledBottomContent>
      </StyledItem>
    </StyledContainer>
  );
};

export default NewsContainer;
