import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
import {EyeOutlined} from "@ant-design/icons"
import {
  fetchSipNumberOption,
  fetchNumberPass,
} from "../../../../../redux/reducers/dashboard/sipNumberOption";
import {
  StyledBar,
  StyledContainer,
  StyledNumberBox,
  StyledHead,
  StyledBody,
  StyledColumn,
} from "./Styles/NumberSettingsStyle";


const { Search } = Input;
const NumberSettings = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.sipNumberOption.data);
  const numberPass = useSelector((state) => state.sipNumberOption.numberPass);

  const seePassword = (number) => {
    dispatch(fetchNumberPass(number));
  };
  useEffect(() => {
    dispatch(fetchSipNumberOption());
  }, []);
  return (
    <div>
      <StyledBar>
        <Search
          placeholder="შეიყვანეთ ნომერი"
          onSearch={(value) => console.log(value)}
          style={{ width: 200 }}
        />
      </StyledBar>
      <StyledContainer>
        {data?.map((item) => {
          return (
            <StyledNumberBox key={item.id}>
              <StyledHead>
                <h5>ნომერი: {item.voip_number}</h5>
              </StyledHead>
              <StyledBody>
                <StyledColumn column={false}>
                  <h4 className="blue">SIP სერვერი:</h4>
                  <h4>{item.voip_server_name}</h4>
                </StyledColumn>
                <StyledColumn column={false}>
                  <h4 className="blue">მომხმარებელი:</h4>
                  <h4>{item.voip_number}</h4>
                </StyledColumn>
                <StyledColumn column={false}>
                  <h4 className="blue">პაროლი:</h4>
                  {!numberPass ? (
                    <button onClick={() => seePassword(item.voip_number)}>
                  <EyeOutlined /> ****
                    </button>
                  ) : numberPass.username === item.voip_number ? (
                    <h4>{numberPass.password}</h4>
                  ) : (
                    <button onClick={() => seePassword(item.voip_number)}>
                    <EyeOutlined /> ****
                      </button>
                  )}
                </StyledColumn>
              </StyledBody>
            </StyledNumberBox>
          );
        })}
      </StyledContainer>
    </div>
  );
};

export default NumberSettings;
