import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { StyledContainer, StyledFormGroup } from "./styles/ContactStyle";
const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const { t } = useTranslation();

  const success = (text) => {
    message.success(text);
  };

  const formHandler = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    success("შეტყობინება გაგზავნილია.");
  };
  return (
    <StyledContainer>
      <form onSubmit={formSubmit}>
        <StyledFormGroup center={true}>
          <h2>{t("page_title.contact.title")}</h2>
        </StyledFormGroup>
        <StyledFormGroup center={false}>
          <label>{t("page_title.contact.name")}</label>
          <input
            type="text"
            placeholder={t("page_title.contact.name_holder")}
            onChange={formHandler}
          />
        </StyledFormGroup>
        <StyledFormGroup center={false}>
          <label>{t("page_title.contact.email")}</label>
          <input
            type="text"
            placeholder={t("page_title.contact.email_holder")}
            onChange={formHandler}
          />
        </StyledFormGroup>
        <StyledFormGroup center={false}>
          <label>{t("page_title.contact.message")}</label>
          <textarea
            name=""
            placeholder={t("page_title.contact.message_holder")}
            onChange={formHandler}
          ></textarea>
        </StyledFormGroup>
        <StyledFormGroup center={false}>
          <button type="submit">{t("page_title.contact.send")}</button>
        </StyledFormGroup>
      </form>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2952.876929446456!2d42.66939801531878!3d42.25980067919378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x405c8d8148115e71%3A0xc5f0719a6ef3fe4d!2z4YOi4YOU4YOa4YOU4YOZ4YOd4YObIDEgLSBUZWxlY29tIDE!5e0!3m2!1ska!2sge!4v1602750339403!5m2!1ska!2sge"
          width="600"
          height="450"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    </StyledContainer>
  );
};

export default Contact;
