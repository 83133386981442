import React from "react";
import { useTranslation } from "react-i18next";
import {StyledContainer,Left,StyledTitle} from './styles/DescStyles'
const Desc = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
        <div>
            <h3 className="title">ავტორიზაცია</h3>
        </div>
      <Left>
        <h3><strong>{t("authDescription.right_1")}</strong></h3>
        <h4>
        {t("authDescription.right_2")}
        </h4>
        <h4>{t("authDescription.right_3")}</h4>
        <h4>{t("authDescription.right_4")}</h4>
      </Left>

      <div className="list">
        <span>
          <strong>{t("authDescription.left_1")}</strong>
        </span> <br />
        <span>
       <strong> {t("authDescription.left_2")}</strong>
        </span><br />
        <span>
          <strong>{t("authDescription.left_3")}</strong>
        </span>
      </div>

      <div>
        <StyledTitle >
        {t("authDescription.title")}
        </StyledTitle>
      </div>

      <div>
        <div className="list">
          <strong>
          {t("authDescription.a_1.title")}
          </strong>
          <ul>
            <li>
            {t("authDescription.a_1.1")}
            </li>
            <li>
            {t("authDescription.a_1.2")}
            </li>
          </ul>
        </div>

        <div className="list">
          <strong>
          {t("authDescription.a_2.title")}
          </strong>
          <ul>
            <li>
            {t("authDescription.a_2.1")}
            </li>
          </ul>
        </div>

        <div className="list">
          <strong>
          {t("authDescription.a_4.title")}
          </strong>
          <ul>
            <li>
            {t("authDescription.a_4.1")}
            </li>
            <li>
            {t("authDescription.a_4.2")}
            </li>
          </ul>
        </div>

        <div className="list">
          <strong>
          {t("authDescription.a_5.title")}
          </strong>
          <ul>
            <li>
            {t("authDescription.a_5.1")}
            </li>
          </ul>
        </div>

        <div className="list">
          <strong>
          {t("authDescription.a_7.title")}
          </strong>
          <ul>
            <li>
            {t("authDescription.a_7.1")}
            </li>
            <li>
            {t("authDescription.a_7.2")}
            </li>
          </ul>
        </div>

        <div className="list">
          <strong>
          {t("authDescription.b_1.title")}
          </strong>
          <ul>
            <li>     {t("authDescription.b_1.1")}</li>
            <li>     {t("authDescription.b_1.2")}</li>
            <li>     {t("authDescription.b_1.3")}</li>
            <li>     {t("authDescription.b_1.4")}</li>
          </ul>
        </div>

        <div className="list">
          <strong>     {t("authDescription.b_2.title")}</strong>
          <ul>
            <li> {t("authDescription.b_2.1")}</li>
            <li> {t("authDescription.b_2.2")}</li>
            <li> {t("authDescription.b_2.3")}</li>
          </ul>
        </div>

        <div className="list">
          <strong> {t("authDescription.b_3.title")}</strong>
          <ul>
            <li>{t("authDescription.b_3.1")}</li>
          </ul>
        </div>

        <div className="list">
          <strong>{t("authDescription.b_4.1")}</strong>
          <ul>
            <li>{t("authDescription.b_4.1")}</li>
            <li>{t("authDescription.b_4.2")}</li>
          </ul>
        </div>

        <div className="list">
          <strong>
          {t("authDescription.b_5.title")}
          </strong>
          <ul>
            <li> {t("authDescription.b_5.1")}</li>
          </ul>
        </div>

        <div className="list">
          <strong>
          {t("authDescription.bm_1.title")}
          </strong>
          <ul>
            <li>
            {t("authDescription.bm_1.1")}
            </li>
            <li>{t("authDescription.bm_1.2")}</li>
            <li>{t("authDescription.bm_1.3")}</li>
          </ul>
        </div>

        <div className="list">
          <strong>
          {t("authDescription.bm_2.title")}
          </strong>
          <ul>
            <li>{t("authDescription.bm_2.1")}</li>
            <li>{t("authDescription.bm_2.2")}</li>
            <li>{t("authDescription.bm_2.3")}</li>
            <li>{t("authDescription.bm_2.4")}</li>
            <li>{t("authDescription.bm_2.5")}</li>
            <li>{t("authDescription.bm_2.6")}</li>
          </ul>
        </div>
      </div>
    </StyledContainer>
  );
};

export default Desc;
