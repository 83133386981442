import React, {useState} from "react";
import { useTranslation } from "react-i18next";

import {DownOutlined} from "@ant-design/icons"
import { StyledContainer,StyledCollapse } from "./style/DescriptionStyles";
const Description = () => {
  const [collapse,setCollapse] = useState(false);
  const { t } = useTranslation();
  const handleCollapse = () => {
    setCollapse(!collapse)
  }
  return (
    <StyledContainer collapse={collapse}>
      <StyledCollapse onClick={handleCollapse}>
        <h3>{t("generalInformation.title")}</h3>
        <span><DownOutlined /></span>
      </StyledCollapse>
      <p> {t("generalInformation.internet")}</p>
    </StyledContainer>
  );
};

export default Description;
