import styled from 'styled-components'

export const StyledContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    width:800px;
    padding-bottom:15px;
    max-width:100%;
    margin:0 auto;
    animation: 1s ${p => p.bounceAnimation};
    margin-top:-20px;
    flex-wrap:wrap;
    button {
        padding:6px;
        border: 1px solid #d9d9d9;
        background-color:#004592;
        border-radius:3px;
        font-size:12px;
        width:200px;
        cursor:pointer;
        color:#fff;
    }
    .step {
        display:flex;
        flex-direction:column;
        label {
            height:21px;
            font-size:12px;
        }
        margin:10px;
    }
`