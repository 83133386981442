import React, { useState, useEffect } from "react";
//packages
import { Modal, Input } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { MenuOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { fetchUserAddress, deleteUserAddress,editUserAddress} from "../../../../../redux/reducers/dashboard/userCreateAddress";

// styles
import {
  StyledServicesBox,
  StyledServicesTitle,
  StyledPacketList,
  StyledPacket,
  StyledDeleteAddress,
  StyledControll,
} from "./styles/OptionsStyle";

const AddressItem = () => {
  const dispatch = useDispatch();
  const addressItems = useSelector((state) => state.userAddress.addressItems);
  const [activeControll, setActiveControll] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAddress,setSelectedAddress] = useState({})
  const [selectedAddressValue,setSelectedAddressValue] = useState("")



  const editAddressValueHandler = (e) => {
    setSelectedAddressValue(e.target.value)
  }
  const showModal = (item) => {
    setIsModalVisible(true);
    setSelectedAddress(item)
    
  };

  const handleOk = () => {
    dispatch(editUserAddress(selectedAddress.id, selectedAddress))
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const activeControllHandler = (id) => {
    setActiveControll(id === activeControll ? 0 : id);
  };

  const deleteUserAddressHandler = (id) => {
    dispatch(deleteUserAddress(id))
  }
  useEffect(() => {
    dispatch(fetchUserAddress());
  }, [addressItems]);
  return addressItems?.map((item) => (
    <StyledServicesBox key={item.id}>
      <Modal title="მისამართის რედაქტირება" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="რედაქტირება" cancelText="გაუქმება">
      <Input placeholder={selectedAddress.address} value={selectedAddressValue} onChange={editAddressValueHandler} />
      </Modal>
      <StyledServicesTitle>
        <EnvironmentOutlined style={{ fontSize: "30px", color: "rgba(63,121,186,1)" }} />
        <h4>ფაქტობრივი მისამართი</h4>
        <p>{item.address}</p>
      </StyledServicesTitle>
      <StyledDeleteAddress>
        <MenuOutlined onClick={() => activeControllHandler(item.id)} />
      </StyledDeleteAddress>
      <StyledControll activeControll={activeControll === item.id}>
          <li><button onClick={() => showModal(item)}>რედაქტირება</button></li>
          <li><button onClick={() => deleteUserAddressHandler(item.id)}>წაშლა</button></li>
        </StyledControll>
    </StyledServicesBox>
  ));
};

export default AddressItem;
