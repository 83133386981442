import styled from "styled-components";


export const StyledTariff = styled.div`
  width: 230px;
  height: 250px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 0px 3px 0px rgba(204, 204, 204, 1);
  transition: 300ms;
  border: 2px solid #fff;
  background-color: #fff;
  transition: 300ms;
  margin-top:25px;
  .price {
    background-color: #0e4c90;
    width: 100%;
    height:50px;
    padding: 2px;
    padding-top:5px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    display:flex;
    justify-content:center;
    align-items:center;
    span {
      font-size:12px;
      }
    h3 {
      font-family: "BOG 2017 Headline";
      font-size: 26px;
      color:#fff;
      position:relative;
      span {
        position:absolute;
        right:-13px;
        top:7px;
        font-family: "BOG 2018";
      }
    }
    h4 {
      display:flex;
      flex-direction:column;
      margin-top:7px;
      margin-left:3px;
      color:#fff;
      font-size:12px;
    }
  }
  &:hover {
    z-index: 111;
    transform:scale(1.1);
  }
  .head {
    width: 100%;
    text-align: center;
    font-family: "BOG 2017 Headline";
    color: #282828;
    padding: 5px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0;
      margin: 0;
    }
    h1 {
      font-size: 30px;
      color: #004592;
    }
    h3 {
      color: #282828;
      font-size: 19px;
      margin-top:20px;
    }
    h4 {
      color: #969696;
    }
  }
  ul li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: #f5f5f5 1px solid;
    span {
      font-size: 12px;
      color: #7f848a;
    }
  }
  .order__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledOrderButton = styled.button`
  padding: 10px;
  width: 155px;
  border: 0;
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  color: #282828;
  border-radius: 3px;
  cursor: pointer;
  transition: 300s;
  border: 1px solid #004592;
  background-color: transparent;
  margin-top: 35px;
`;
export const StyledContainer = styled.div`
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
  padding:30px;
  .tariff__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 700px) {
      justify-content: space-around;
      align-content: center;
    }
  }

  .title {
    font-family: "BOG 2017 Headline";
    font-size: 16px;
    color: #0e4c90;
    text-align: center;
    padding-bottom: 20px;
    i {
      color: #004592;
    }
  }
  // right button
  .fXNjvC {
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1em;
    background-color: #0e4c90;
    color: #333;
    box-shadow: 0 0 2px 0px #333;
    border-radius: 50%;
    border: none;
    padding: 0;
    width: 30px;
    height: 30px;
    min-width: 30px;
    line-height: 30px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
    outline: none;
    color:#fff;
    &:hover {
      background:#fff;
      color:#004592;
    }

}

.gtIdWk {
  box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1em;
    background-color: #0e4c90;
    color: #333;
    box-shadow: 0 0 2px 0px #333;
    border-radius: 50%;
    border: none;
    padding: 0;
    width: 30px;
    height: 30px;
    min-width: 30px;
    line-height: 30px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
    outline: none;
    color:#fff;
    opacity:0.3;
}
// left button
.rec-arrow-left {
  box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1em;
    background-color: #0e4c90;
    color: #333;
    box-shadow: 0 0 2px 0px #333;
    border-radius: 50%;
    border: none;
    padding: 0;
    width: 30px;
    height: 30px;
    min-width: 30px;
    line-height: 30px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
    outline: none;
    color:#fff;
    &:hover {
      background:#fff;
      color:#004592;
    }
}

`;
