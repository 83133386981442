import React from "react";
import { useTranslation } from "react-i18next";

import { FilePdfOutlined } from "@ant-design/icons";
import pdf1 from "../../assets/pdf/1.pdf";
import pdf2 from "../../assets/pdf/2.pdf";
import pdf3 from "../../assets/pdf/3.pdf";
import pdf4 from "../../assets/pdf/4.pdf";
import pdf5 from "../../assets/pdf/5.pdf";
import pdf6 from "../../assets/pdf/6.pdf";
import pdf7 from "../../assets/pdf/7.pdf";
import { StyledContainer } from "./styles/LayouteStyles";
const Layoute = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <h2>{t("page_title.contracts.title")}</h2>
      <ul>
        <li>
          <a href={pdf1} target="_blank" rel="noopener">
            <FilePdfOutlined />
            {t("page_title.contracts.title1")}
          </a>
        </li>
        <li>
          <a href={pdf2} target="_blank" rel="noopener">
            <FilePdfOutlined />
            {t("page_title.contracts.title2")}          </a>
        </li>
        <li>
          <a href={pdf3} target="_blank" rel="noopener">
            <FilePdfOutlined />
            {t("page_title.contracts.title3")}          </a>
        </li>
        <li>
          <a href={pdf4} target="_blank" rel="noopener">
            <FilePdfOutlined />
            {t("page_title.contracts.title4")}        </a>
        </li>
        <li>
          <a href={pdf5} target="_blank" rel="noopener">
            <FilePdfOutlined />
            {t("page_title.contracts.title5")}          </a>
        </li>
        <li>
          <a href={pdf6} target="_blank" rel="noopener">
            <FilePdfOutlined />
            {t("page_title.contracts.title6")}          </a>
        </li>
        <li>
          <a href={pdf7} target="_blank" rel="noopener">
            <FilePdfOutlined />
            {t("page_title.contracts.title7")}          </a>
        </li>
      </ul>
    </StyledContainer>
  );
};

export default Layoute;
