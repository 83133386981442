import styled from "styled-components";

export const StyledContainer = styled.div`
  .ant-drawer-content-wrapper {
    width: 540px !important;
  }
`;
export const StyledBar = styled.div`
  width: 885px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #f7f7f7;
  padding: 5px;
  margin-top: 25px;
  border-radius: 3px;
  max-width:100%;
  @media (max-width:408px) {
        width:300px;
        max-width:100%;
      }
  @media (max-width: 1000px) {
    justify-content: space-between;
    margin: 10px;
    width: 95%;
  }

  @media (max-width: 752px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .barx {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  .barx {
    display: flex;
      justify-content: center;
      align-items: center;
      .search__number {
        margin-left:5px;
        @media (max-width:768px) {
          margin-top:5px;
        }
        &:first-child {
          margin-left:0;
        }
  }
    input {
      font-size: 12px;
      &::placeholder {
        font-size: 12px;
      }
    }
  }
  .freesms {
    font-size: 12px;
  }
  .ant-select-selection-item {
    font-size: 12px;
  }
`;

export const StyledButton = styled.button`
  background: ${(p) => (p.red ? "linear-gradient(90deg, rgba(253,97,124,1) 0%, rgba(235,0,41,1) 100%);" : "linear-gradient(90deg,rgba(63,121,186,1) 0%,rgba(0,69,146,1) 100%);")};
  color: #fff;
  padding: 6px;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  width: 100px;
  font-family: "BOG 2017 Headline";
  transition: 300ms;
  margin-right: ${(p) => (p.red ? "5px" : "0px")};
  &:hover {
    color: #ccc;
  }
  @media (max-width: 752px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const StyledInbox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const StyledSent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
`;

export const StyledMessages = styled.div`
  width: 526px;
  max-width: 100%;
  margin-top: 20px;
  border: 2px solid #f7f7f7;
  height: 370px;
  padding: 10px;
  padding-top: 0;
  overflow: hidden; 
  @media (max-width: 569px) {
    width: 400px;
    margin: 10px;
  }
  @media (max-width: 428px) {
    width: 300px;
    margin: 5x;
  }
`;

export const StyledMessage = styled.div`
  width: 240px;
  padding: 5px;
  margin:5px;
  padding-bottom:1px;
  border-radius: 3px;
  background-color:#F7F7F7;
  margin-top: 10px;
  overflow-wrap: anywhere;

  p {
    font-size:12px;
      color:#282828;
    padding-bottom: 15px;

  }
  h6 {
    color: #282828;
    font-size: 9px;
    text-align: center;
    background: #fff;
    width: 100%;
    padding: 3px;
    border-radius: 3px;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 526px;
  max-width: 100%;
  border: 2px solid #f7f7f7;
  margin-top: 5px;
  height: 100px;
  font-size: 12px;
  padding: 5px;
  &::placeholder {
    color: #ccc;
    font-size: 12px;
  }
  @media (max-width: 569px) {
    width: 400px;
    margin: 10px;
  }
  @media (max-width: 428px) {
    width: 300px;
    margin: 5px;
  }
`;

export const StyledMessagesContainer = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 897px) {
    flex-direction: column;
    align-items: center;
  }
  .character__length {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      font-size: 12px;
    }
  }
`;

export const StyledInboxContainer = styled.div`
  margin: 10px;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;
  overflow: hidden;
  border: 2px solid #f7f7f7;
  .freesms {
    text-align: center;
    border-bottom: 2px solid #f7f7f7;
    padding: 10px;
    font-size: 12px;
  }
`;
export const StyledInboxBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg,rgba(63,121,186,1) 0%,rgba(0,69,146,1) 100%);
  padding: 10px;
  input {
    padding:4px;
    border-radius:3px;
    border:0;
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
    }
  }
  h4 {
    color: #fff;
    font-family: "BOG 2017 Headline";
    padding-left: 5px;
    padding-top: 7px;
    font-size: 12px;
  }
  .message {
    display: flex;
    align-items: center;
  }
`;
export const StyledInboxDelete = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 2px solid #f7f7f7;
  padding: 16px;
`;
export const StyledInboxContacts = styled.div`
  height: 352px;
  overflow: auto;
  background: #fff;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const StyledContact = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin:5px;
  border: 1px solid #f7f7f7;
  border-radius:3px;
  transition: 100ms;
  cursor: pointer;
  background:${p => p.active ? "linear-gradient(90deg,rgba(63,121,186,1) 0%,rgba(0,69,146,1) 100%)": "#fff"};
  .number h4 {
    color:${p => p.active ? "#fff": "#282828"};
  }
  svg {
    fill:${p => p.active ? "#fff": "#004592"};
  }
  &:hover {
    background:linear-gradient(90deg,rgba(63,121,186,1) 0%,rgba(0,69,146,1) 100%);;
    .number {
     h4 {
      color:#fff;
     }
    }
    svg {
      fill:#fff;
    }
  }
  .user__add {
    background: transparent;
    border: 0;
    font-size: 20px;
    color: #004592;
    cursor: pointer;
  }
  .avatar__content {
    display: flex;
    align-items: center;
  }
  .user {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 5px;
    .number {
      margin-left:25px;
      margin-top:2px;
      cursor: pointer;
    }
    h4 {
      font-size: 12px;
    }
    h5 {
      font-size: 11px;
    }
  }
`;

export const StyledCheckbox = styled.div`
  border-radius: 3px;
  ${(p) => p.absolute && ` margin-top: -18px;`};
  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 13px;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    border: 1px solid #004592;
    background: #fff;
    border-radius: 3px;
    margin-top: 3px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background: linear-gradient(90deg,rgba(63,121,186,1) 0%,rgba(0,69,146,1) 100%);;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const StyledDeleteButton = styled.button`
  border: 2px solid #f7f7f7;
  background: #fff;
  padding: 4px;
  width: 140px;
  border-radius: 3px;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    background: #eb0028;
    border: 2px solid #eb0028;
    color: #fff;
  }
`;
