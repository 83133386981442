import React from 'react'
// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Footer from "../Lcomponents/Footer/Footer";
import Slider from "../Lcomponents/Slider/Slider";
import TermsAndConditions from "../Lcomponents/Terms/TermsAndConditionsModal";

const Terms = () => {
    return (
        <div>
                 <Navbar />
            <Menu />
            <Slider/>
            <TermsAndConditions />
            <Footer/>
        </div>
    )
}

export default Terms
