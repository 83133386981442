import React from "react";
import { useTranslation } from "react-i18next";

import {StyledContainer} from "./styles/AboutLayouteStyles";

const AboutLayoute = () => {
  const { t,i18n } = useTranslation();
  return (
    <StyledContainer>
       <div style={{marginTop:"30px"}}>
       </div>
      <h2>
        {t("aboutMe.headTitle")}
      </h2>
      <h3>
      {t("aboutMe.title")}
      </h3>
      <ul>
        <li>
        {t("aboutMe.t1")}
        </li>
        <li>
        {t("aboutMe.t2")}
        </li>
        <li>
        {t("aboutMe.t3")}
        </li>
        <li>
        {t("aboutMe.t4")}
        </li>
        <li>
        {t("aboutMe.t5")}
        </li>
      </ul>
      <h3>
      {t("aboutMe.bottomTitle")}
      </h3>
      <ul>
        <li>
        {t("aboutMe.b1")}
        </li>
        <li>
        {t("aboutMe.b2")}
        </li>
        <br />
        <p style={{fontSize:"12px"}}>
        {t("aboutMe.mvno")}
        </p>
      </ul>
    </StyledContainer>
  );
};

export default AboutLayoute;
