import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import {useHistory} from 'react-router-dom'
// fetch data
import { fetchA2pData } from "../../redux/reducers/landing/a2pReducer";
// components
import OrderAddModal from "../OrderAddModal/OrderAddModal";
// styles
import {
  StyledContainer,
  StyledTariff,
  StyledOrderButton,
} from "./styles/PacketsStyles";

const Packets = () => {
  const [orderAddModalVisible, setOrderAddModalVisible] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const history = useHistory();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.a2p.data);

  const showModal = () => {
    setOrderAddModalVisible(true);
  };

  const handleOk = () => {
    setOrderAddModalVisible(false);
  };

  const handleCancel = () => {
    setOrderAddModalVisible(false);
  };

  const orderIdHandler = (id) => {
    if (localStorage.getItem("token")) {
      setOrderId(id);
      showModal();
    } else {
      message.warning("გთხოვთ გაიაროთ ავტორიზაცია ან რეგისტრაცია");
      history.push("/login");
    }
  };

  useEffect(() => {
    dispatch(fetchA2pData());
  }, []);
  return (
    <StyledContainer>
      <OrderAddModal
        handleOk={handleOk}
        handleCancel={handleCancel}
        orderAddModalVisible={orderAddModalVisible}
        category="a2p"
        order_id={orderId}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          aligItems: "center",
        }}
      >
        <h1 className="title">A2P SMS პაკეტები</h1>
      </div>

      <div className="tariff__container">
        {data.map((item) => (
                      <StyledTariff key={item.id}>
                      <div className="head">
                        <h3>{item.display_name}</h3>
                      </div>
                      
                    <div className="price">
                      <h3>
                        {item.base_price} <span>₾</span>
                      </h3>
                      <h4>30 დღე</h4>
                    </div>
          
                      <div className="order__container">
                        {/* პაკეტის არჩევის ღილაკი */}
                        <StyledOrderButton
                          onClick={() => {
                            orderIdHandler(item.id);
                          }}
                          green={item.green}
                          red={item.red}
                        >
                          შეკვეთა
                        </StyledOrderButton>
                      </div>
                    </StyledTariff>
        ))}
      </div>
    </StyledContainer>
  );
};

export default Packets;
