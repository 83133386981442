import React, {useState} from "react";
import {DownOutlined} from "@ant-design/icons"
import { StyledContainer,StyledCollapse } from "./styles/DescriptionStyles";
const Description = () => {
  const [collapse,setCollapse] = useState(false);

  const handleCollapse = () => {
    setCollapse(!collapse)
  }
  return (
    <StyledContainer collapse={collapse}>
      <StyledCollapse onClick={handleCollapse}>
        <h3>ზოგადი ინფორმაცია</h3>
        <span><DownOutlined /></span>
      </StyledCollapse>
      <p>
      Gpon და P2P (peer-to-peer) ტექნოლოგიით შესაძლებელია სწრაფი, ხარისხიანი და სტაბილური ოპტიკური ინტერნეტით სარგებლობა. ფიზიკურ და იურიდიულ პირებზე მორგებული სატარიფო გეგმა.
      </p>
    </StyledContainer>
  );
};

export default Description;
