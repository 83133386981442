import axios from "axios";

export const fetchSipNumberOption = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "voip_list",
        pars: { account_id: localStorage.getItem("userId") },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch({ type: "FETCH_SIP_NUMBER_OPTION", payload: res.data.data });
      });
  };
};

export const fetchNumberPass = (number) => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "voip_sip_data",
        pars: {
          account_id: localStorage.getItem("userId"),
          token: localStorage.getItem("token"),
          username: number,
          server: 1,
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch({ type: "FETCH_NUMBER_PASSWORD", payload: res.data.data[0] });
      });
  };
};

// state
const initialState = {
  data: [],
  numberPass: null,
};

export const sipNumberOption = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SIP_NUMBER_OPTION":
      return { data: action.payload };
    case "FETCH_NUMBER_PASSWORD":
      return {...state, numberPass: action.payload}
    default:
      return state;
  }
};
