import axios from "axios";

const initialState = {
  data: [],
  basicList: [],
  filterCategory: []
};

const ipTvDataAction = (data) => {
  return { type: "IPTV_DATA", payload: data };
};

export const fetchipTvData = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "info",
        function: "list_pakets",
        pars: { product_category_name: "iptv", user_can_add: 1 },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch(ipTvDataAction(res.data.products));
      });
  };
};

export const fetchBasicTv = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "info",
        function: "channels_list",
        pars: { package: "iptv_dcm_basic" },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch({ type: "BASIC_LIST", payload: res.data.data });
      });
  };
};

export const fetchTvCategory = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "info",
        function: "ganres_list",
        pars: {},
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch({ type: "FILTER_CATEGORY", payload: res.data.data });
      });
  };
};

export const iptvReducer = (state = initialState, action) => {
  switch (action.type) {
    case "IPTV_DATA":
      return { ...state, data: action.payload };
    case "BASIC_LIST":
      return { ...state, basicList: action.payload };
    case "FILTER_CATEGORY":
      return { ...state, filterCategory: action.payload };

    default:
      return state;
  }
};
