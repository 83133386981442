import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 880px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #f7f7f7;
  padding: 20px;
  margin-top: 25px;
  @media (max-width: 928px) {
    width: 100%;
  }
  .information {
    text-align: center;
    padding-bottom: 20px;
    h4 {
      text-align: center;
      font-family: "BOG 2017 Headline";
      font-size: 12px;
      color: #7f848a;
    }
  }
  .download {
    background: #398439;
    width: 190px;
    text-align: center;
    cursor: pointer;
    border: 2px solid #398439;
    border-radius: 3px;
    padding: 6px;
    color: #fff;
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    margin-top: 10px;
  }
  .bottom {
    @media (max-width: 665px) {
      flex-direction: column;
    }
  }
  form {
    margin: 10px;
    border: 2px solid #f7f7f7;
    padding: 10px;
    .form__group {
      display: flex;
      flex-direction: column;
      @media (max-width: 665px) {
        align-items: center;
      }
      label {
        font-size: 12px;
        color: #7f848a;
        font-family: "BOG 2017 Headline";
      }

      input {
        border: 2px solid #f7f7f7;
        border-radius: 3px;
        padding: 5px;
        @media (max-width: 665px) {
          width: 100%;
          margin-top: 5px;
        }
        &::placeholder {
          font-size: 12px;
        }
      }
      .upload {
        background: #004592;
        width: 230px;
        text-align: center;
        cursor: pointer;
        border: 2px solid #004592;
        border-radius: 3px;
        padding: 6px;
        color: #fff;
        font-family: "BOG 2017 Headline";
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
  h3 {
    font-family: "BOG 2017 Headline";
    color: #7f848a;
    font-size: 12px;
    text-align: center;
  }
`;

export const StyledFormButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  .button-wrapper {
    position: relative;
    width: 230px;
    text-align: center;
    cursor: pointer;
    border: 2px solid #f7f7f7;
    border-radius: 3px;
  }

  .button-wrapper span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: #fff;
    cursor: pointer;
    color: #7f848a;
    padding: 8px 0;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 3px;
    font-family: "BOG 2017 Headline";
  }

  #upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .form__download {
    width: 230px;
    cursor: pointer;
    margin-top: 0;
    padding: 8px;
    margin: 5px;
    background: #28a745;
    border: 2px solid #28a745;
  }
`;

export const StyledHead = styled.div`
  background: #004592;
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  h4 {
    color: #fff;
    text-align: center;
    font-family: "BOG 2017 Headline";
    font-size: 12px;
  }
`;
export const StyledDatabaseList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 320px;
  height: auto;
  border-radius: 3px;
  border: 2px solid #f7f7f7;
  padding: 10px;
  margin: 10px;
`;
export const StyledDatabase = styled.div`
  width: 100%;
  margin: 10px;
  padding: 4px;
  border: 2px solid #f7f7f7;
  h4 {
    font-size: 12px;
  }
`;
