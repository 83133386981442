import React from "react";
// packages
import { Select, Input } from "antd";
import { fadeInLeft, fadeInRight } from "react-animations";
import { keyframes } from "styled-components";
// styles
import { StyledContainer } from "./styles/StepsStyles";
// anim
const bounceAnimation = keyframes`${fadeInRight}`;
const zoomOutUpAn = keyframes`${fadeInLeft}`;

const { Option } = Select;

const StepTwo = () => {
  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }
  return (
    <>
      <StyledContainer bounceAnimation={bounceAnimation} see={false}>
        <div className="step">
          <label>მომხმარებელი</label>
          <Input style={{ width: 200 }} />
        </div>
        <div className="step">
          <label></label>
          <button>დავალიანების გაგება</button>
        </div>
      </StyledContainer>

      <StyledContainer  bounceAnimation={zoomOutUpAn} see={true}>
        <div className="step">
          <label>მომხმარებლის სახელი</label>
          <Input style={{ width: 200 }} disabled={true} />
        </div>

        <div className="step">
          <label>ბალანსი</label>
          <Input style={{ width: 200 }} disabled={true} />
        </div>

        <div className="step">
          <label>გადასახდელი თანხა</label>
          <Input style={{ width: 200 }} placeholder="შეიყვანეთ თანხა" />
        </div>

        <div className="step">
          <label>ბარათი</label>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="აირჩიეთ ბარათი"
            optionFilterProp="children"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="tom">Tom</Option>
          </Select>
        </div>
        <div className="step">
          <label>მომსახურების საკომისიო</label>
          <Input style={{ width: 200 }} disabled={true} />
        </div>
        <div className="step">
          <label></label>
          <button>გადახდა</button>
        </div>
      </StyledContainer>
    </>
  );
};

export default StepTwo;
