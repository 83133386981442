import styled from "styled-components";

export const StyledLandingContainer = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding:50px;
  overflow:hidden;
  background-color:#fff;
  filter: brightness(95%);
  .wrapper {
    width:1200px;
    margin:0 auto;
    display:flex;
  justify-content:space-between;
  align-items:center;
  }
  .dd {
  z-index:111;
}
  ul li {
    list-style:none;
    color:#000000;
  }
  h1 {
    font-size:40px;
    font-family: "BOG 2017 Headline";
    color:#0E111F;

  }
  img {
    width:450px;
  }

  @media (max-width: 768px) {
    height: 100%;
  }
  .slider__container {
    width: 950px;
    margin: 0 auto;
    max-width: 100%;
    margin-top: 150px;
    overflow: hidden;

    @media (max-width: 883px) {
      width: 800px;
    }
    @media (max-width: 543px) {
      width: 100%;
    }
  }
`;

export const StyledSelectNumber = styled.a`
  font-family: "BOG 2018";
  color: rgb(0, 69, 146);
  font-size: 12px;
`;

export const StyledSliderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  @media (max-width: 883px) {
    flex-direction: column;
  }
  h3 {
    font-size: 27px;
    color: #004592;
    margin-left: 50px;
    font-family: "BOG 2017 Headline";
    position: relative;
    @media (max-width: 883px) {
      font-size: 20px;
    }
  }

  img {
    width: 300px;

    @media (max-width: 883px) {
      width: 200px;
    }
  }
  ul li {
    margin-left: 50px;
    list-style: none;
    font-size: 13px;
    color: #7f848a;
    margin-top: 10px;
    svg {
      color: #004592;
    }
  }
`;

export const StyledNewNumberSection = styled.section`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 555px) {
    justify-content: center;
    align-items: flex-start;
  }
  .section__title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 55px;
    @media (max-width: 1015px) {
      padding-bottom: 15px;
    }
    h1 {
      font-family: "BOG 2017 Headline";
      color: #7f848a;
      position: relative;
      font-size: 16px;
      i {
        color: #004592;
      }
    }
  }
  .tariff__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 1000px;
    max-width: 100%;
    @media (max-width: 1077px) {
      width: 100%;
      flex-direction: column;
    }
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    .check__number__container {
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-select-selector {
        height: 50px !important;
        margin-top: -5px;
        border: 0;
        margin-left: -10px;
      }
      .ant-select-arrow {
        margin-right: 10px !important;
      }
      .ant-select-selection-item {
        padding: 8px !important;
      }
      .ant-select-selection-item {
        border: 0px !important;
      }
    }
    h1 {
      font-family: "BOG 2017 Headline";
      color: #7f848a;
      font-size: 16px;
    }
    .check {
      display: flex;
      align-items: center;
      input {
        border: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        padding: 10px;
        width: 260px;
        height: 50px;
        &::placeholder {
          font-size: 12px;
        }
        &:focus {
          outline: none;
        }
      }

      .check__btn {
        background: #0e4c90;
        padding: 10px;
        height: 50px;
        width: 160px;
        border: 0;
        font-family: "BOG 2017 Headline";
        font-size: 12px;
        color: #fff;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        transition: 300s;
        cursor: pointer;
        &:hover {
          color: red;
        }
      }
    }
  }
`;

export const StyledTelephoneSection = styled.div`
  width: 1000px;
  margin: 0 auto;
  max-width: 100%;
  .section__title {
    text-align: center;
    padding-bottom: 55px;
    @media (max-width: 1015px) {
      padding-bottom: 15px;
    }
    h1 {
      font-family: "BOG 2017 Headline";
      color: #7f848a;
      font-size: 16px;
    }
  }
`;

export const StyledNumberPortingContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 100px;
  background-color: #f8f9fc;
  overflow: hidden;
  .container {
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
  }
  .section__title {
    text-align: center;
    padding-bottom: 25px;
    h1 {
      font-family: "BOG 2017 Headline";
      color: #7f848a;
      font-size: 16px;
    }
  }
`;

export const StyledFooter = styled.div`
  width: 100%;
  background-color: #4a4a4a;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    padding-top: 10px;
    height: 100%;
  }
  .container {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 600px) {
      flex-direction: column;
      text-align: center;
    }
    h4 {
      font-family: "BOG 2017 Headline";
      color: #fff;
      font-size: 14px;
      margin-top: 3px;
    }
    li {
      list-style: none;
      color: #fff;
      font-size: 13px;
      margin-top: 3px;
    }
  }
  .socials {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px;
    i {
      color: #fff;
      width: 30px;
      height: 30px;
      border: 1px solid #fff;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 300ms;
      cursor: pointer;
      &:hover {
        color: #e20f34;
      }
    }
  }
`;

export const StyledControll = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;

  @media (max-width: 555px) {
    flex-direction: column;
    justify-content: center;
  }
  h4 {
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    color: #7f848a;
  }
  @media (max-width: 555px) {
    .ant-input {
      width: 180px;
    }
  }
  input {
    padding: 6px;
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
    }
  }
  .number__filters {
    margin: 10px;
  }
`;

export const StyledTableContainer = styled.div`
  width: 100%;
  .ant-table-cell {
    font-size: 12px;
    font-family: "BOG 2017 Headline";
    color: #7f848a;
  }
  .ant-table-thead {
    .ant-table-cell {
      color: #0e4c90;
    }
  }
`;

export const StyledCalculator = styled.div`
  width: 1000px;
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f8f9fc;
  border-radius: 3px;
  margin-bottom: 40px;
  h1 {
    font-family: "BOG 2017 Headline";
    font-size: 16px;
    color: #7f848a;
    padding-top: 20px;
    @media (max-width: 550px) {
      text-align: center;
    }
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      list-style: none;
      display: flex;
      flex-direction: column;
      margin: 10px;
      font-family: "BOG 2017 Headline";
      color: #7f848a;
      text-align: center;
      @media (max-width: 550px) {
        font-size: 11px;
        margin: 3px;
      }
    }
    .calculator__title {
      color: #0e4c90;
    }
  }
  button {
    background: #0e4c90;
    padding: 6px;
    border: 0;
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    width: 120px;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    transition: 300s;
    margin-left: 5px;
  }
  .calculator {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 3px;
    margin-top: 10px;
    background-color: #fff;
    margin-bottom: 15px;
    @media (max-width: 403px) {
      width: 100%;
    }
  }
`;

export const StyledAddBasket = styled.div`
  position: fixed;
  bottom: ${(p) => (p.active ? "30px" : "-50px")};
  right: 30px;
  width: 60px;
  height: 40px;
  border-radius: 50px;
  background-color: #eb0028;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  font-size: 25px;
  transition: 300ms;
  padding: 10px;
  cursor: pointer;
  z-index: 111;
  &:hover {
    background-color: #004592;
  }
  ${(p) => p.active && `bottom:30px`};
  h4 {
    font-size: 12px;
    font-family: "BOG 2017 Headline";
    color: #fff;
  }
  .length {
    font-size: 14px;
    color: #fff;
    font-family: "BOG 2017 Headline";
  }
`;

export const StyledBb = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 170px;
  height: 40px;
  border-radius: 50px;
  background-color: #eb0028;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  transition: 300ms;
  overflow: hidden;
  cursor: pointer;
  z-index: 1111;
  font-family: "BOG 2017 Headline";
  &:hover {
    background-color: #004592;
  }
`;
