import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { fetchUser} from "./redux/reducers/landing/userInfoReducer";
import "antd/dist/antd.css";
import "pure-react-carousel/dist/react-carousel.es.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import PrivateRoute from "./PrivateRoute/PrivateRoute";
import AuthRoute from "./PrivateRoute/AuthRoute";
import ScrollToTop from "./ScrollToTop";

import Home from "./Lpages/Home";
import Dashboard from "./pages/Dashboard";
import Landing from "./Lpages/Landing";
import News from "./Lpages/News";
import NewsContentItem from "./Lpages/NewsContentItem";
import ConditionModal from "./components/ConditionModal/ConditionModal";
import Voip from "./Lpages/Voip";
import Pbx from "./Lpages/Pbx";
import Contact from "./Lpages/Contact";
import Faq from "./Lpages/Faq";
import NumberPorting from "./Lpages/NumberPorting";
import Internet from "./Lpages/Internet";
import A2p from "./Lpages/A2p";
import Television from "./Lpages/Television";
import About from "./Lpages/About";
import Contracts from "./Lpages/Contracts";
import Terms from "./Lpages/Terms";
import AuthDescription from "./Lpages/AuthDescription";
import Balance from "./Lpages/Balance";
import Vacancy from "./Lpages/Vacancy";

const App = React.memo(() => {

  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.getItem("token") && dispatch(fetchUser())

  }, []);
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <AuthRoute component={Home} path="/login" />
        <PrivateRoute
          component={Dashboard}
          clearBasket={() => localStorage.removeItem("basket")}
          token={localStorage.getItem("token")}
          path="/dashboard"
        />
        <Route component={NewsContentItem} path="/news/:id" />
        <Route component={News} path="/news" />
        <Route component={Pbx} path="/pbx" />
        <Route component={Contact} path="/contact" />
        <Route component={Faq} path="/faq" />
        <Route component={NumberPorting} path="/numberporting" />
        <Route component={Internet} path="/internet" />
        <Route component={Television} path="/television" />
        <Route component={About} path="/about" />
        <Route component={A2p} path="/a2p" />
        <Route component={Contracts} path="/contracts" />
        <Route component={Terms} path="/terms" />
        <Route component={AuthDescription} path="/authdescription" />
        <Route component={Balance} path="/balance" />
        <Route component={Vacancy} path="/vacancy" />
        <Route path="/">
          <Voip />
        </Route>
        {/**<Route component={Landing} path="/" /> */}
      </Switch>
    </Router>
  );
});

export default App;
