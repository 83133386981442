import React, { useEffect, useState } from "react";
import { Select, Button, message, Input } from "antd";
import {
  StyledContainer,
  StyledImg
} from "./styles/FilterStyles";
import { useDispatch, useSelector } from "react-redux";
import { setPhone } from "../../redux/reducers/landing/basketReducer";
import { numberCreate } from "../../redux/reducers/dashboard/userOrders";
import {
  fetchTelephoneNumbersFilterByPrice,
  fetchTelephoneNumbersPrice,
  fetchTelephoneNumbersFilterByCountry,
  fetchCountryList,
} from "../../redux/reducers/common/numbersReducer";

const { Option } = Select;

const Filter = () => {
  const { countryList, telephoneNumbersPrice } = useSelector(
    (state) => state.telephoneNumbers
  );
  const [showCheck, setShowCheck] = useState(false);
  const [showNumberInfo, setShowNumberInfo] = useState(false);
  const [telephoneItem, setTelephoneItem] = useState(null);
  const [country, setCountry] = useState("თბილისი");
  const [price, setPrice] = useState(null);
  // ტელეფონის ნომრები
  const { telephoneNumbers } = useSelector((state) => state.telephoneNumbers);

  const dispatch = useDispatch();

  // ნომრების გაფილტვრა ფასის მიხედვით
  const numbersFilterByPrice = (value) => {
    setPrice(value);
    dispatch(fetchTelephoneNumbersFilterByPrice(value));
    setTelephoneItem(null);
  };
  // ნომრების გაფილტვრა ქალაქების მიხედვით
  const countryFilter = (value, prop) => {
    setCountry(prop.prop);
    dispatch(fetchTelephoneNumbersFilterByCountry(value));
    setPrice(null);
    setTelephoneItem(null);
  };

  const phoneNumberHandler = (prop) => {
    setTelephoneItem(prop.prop);
    dispatch(numberCreate(prop.prop))
  };

  const selectNumber = () => {
    if (telephoneItem !== null) {
      dispatch(setPhone(telephoneItem));
      message.success(
        "თქვენ წარმატებით აირჩიეთ ნომერი, გთხოვთ აირჩიოთ სატარიფო გეგმა."
      );
      setShowCheck(true);
      setShowNumberInfo(true);
    } else {
      message.error("აირჩიეთ ნომერი.");
    }
  };

  useEffect(() => {
    dispatch(fetchCountryList());
    dispatch(fetchTelephoneNumbersPrice());
    showCheck &&
      setTimeout(() => {
        setShowCheck(false);
      }, 1900);
  }, [showCheck]);
  return (
    <>
      <StyledContainer>
        <StyledImg show={showCheck}>
          <img src="https://i.gifer.com/origin/11/1184b4c0aa977f925dde58d2075772dd.gif" />
        </StyledImg>

        <div className="sec">
          <h5>რეგიონი</h5>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="ქალაქი"
            defaultValue={country}
            onChange={(value, prop) => countryFilter(value, prop)}
          >
            {countryList.map((item) => (
              <Option
                key={item.id}
                value={item.area_code}
                prop={item.area_name}
              >
                {item.area_name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="sec">
          <h5>ღირებულება</h5>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="ყველა"
            value={!country ? price : price}
            onChange={(value) => numbersFilterByPrice(value)}
          >
            <Option>ყველა</Option>
            {telephoneNumbersPrice?.map((item) => (
              <Option key={item.id} value={item.price}>
                ღირებულება: {item.price}₾
              </Option>
            ))}
          </Select>
        </div>

        <div className="sec">
          <h5>ნომერი</h5>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="ძებნა"
            value={!price ? telephoneItem?.number : telephoneItem?.number}
            onChange={(value, prop) => phoneNumberHandler(prop)}
          >
            {telephoneNumbers?.map((item) => (
              <Option key={item.id} value={item.number} prop={item}>
                {item.number}
              </Option>
            ))}
          </Select>
        </div>
        <div className="sec">
          <h5></h5>
          <Button className="select_btn" onClick={() => selectNumber()}>
            <a href="#tariff">არჩევა</a>
          </Button>
        </div>
      </StyledContainer>
    </>
  );
};

export default Filter;
