import React from "react";
// packages
import {useHistory, Link} from 'react-router-dom'
import {RightOutlined} from "@ant-design/icons"
// components
import YearMonthPicker from '../Pickers/YearMonthPicker'
// styles
import {
    StyledContainer,
    StyledPageInfo,
    StyledFilter,
  } from "./styles/FilterBarStyles";

const FilterBar = () => {
    const pageHistory = useHistory();
  return (
    <StyledContainer>
      <StyledPageInfo>
        <ul>
        {pageHistory.location.pathname === "/news" ? <li><Link to="/news">სიახლეები</Link> <RightOutlined /> გამარჯობა ქცეყანა</li> : <li><Link to="/news">სიახლეები</Link> <RightOutlined /> გამარჯობა ქცეყანა</li>}
        </ul>
      </StyledPageInfo>

      <StyledFilter>
      <YearMonthPicker />
      </StyledFilter>
    </StyledContainer>
  );
};

export default FilterBar;
