import styled from "styled-components";

export const StyledBar = styled.div`
  width: 885px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #f7f7f7;
  padding: 5px;
  margin-top: 25px;
  border-radius: 3px;
  @media (max-width: 1000px) {
    justify-content: space-between;
    margin: 10px;
    width: 95%;
  }
  @media (max-width: 752px) {
    flex-direction: column;
  }
`;

export const StyledButton = styled.button`
  background-color: #004592;
  color: #fff;
  padding: 6px;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  width: 160px;
  font-family: "BOG 2017 Headline";
  transition: 300ms;
  &:hover {
    color: #ccc;
  }
  @media (max-width: 752px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
export const StyledPickerContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 752px) {
    flex-direction: column;
    .picker__box {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  .picker__box {
    margin-left: 5px;
  }
  .picker__box:first-child {
    margin-left: 0;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #eb0028 !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    font-size: 12px;
  }
  .ant-picker-input > input:placeholder-shown {
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .ant-picker-range {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 5px;
  }
  .ant-picker-input > input {
    font-size: 12px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #004592;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-picker-focused {
    border-color: #004592;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-picker-active-bar {
    background: #004592;
  }
`;

export const StyledExtractionContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
  margin-top: 10px;
`;
export const StyledExtraction = styled.div`
  width: 100%;
  height: ${(p) => (p.hasHeader ? "45px" : "40px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #f7f7f7;
  border-radius: 3px;
  margin-top: 4px;
  @media (max-width: 818px) {
    flex-direction: column;
    height: auto;
  }
  div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
    text-align: center;
    width: 130px;
    max-width: 100%;
    @media (max-width: 818px) {
      width: 100%;
    }
  }

  h3 {
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    color: #004592;
    margin-top: 6px;
    @media (max-width: 818px) {
      font-size: 10px;
    }
  }
  h4 {
    font-size: 12px;
    margin-top: 6px;
    color: #5f656c;
  }
`;

export const StyledPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  @media (max-width: 768px) {
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
  h4 {
    color: #5f656c;
    font-weight: 400;
    background: #f2f3f2;
    padding: 6px;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  h5 {
    font-family: "BOG 2017 Headline";
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #004592;
    &:hover {
      border-color: #004592;
    }
  }
  .ant-pagination-item-active a {
    color: #004592;
    &:hover {
      color: #004592;
    }
  }
  .ant-pagination-item {
    &:hover {
      border-color: #004592;
    }
  }
  .ant-pagination-item a {
    &:hover {
      color: #004592;
    }
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    &:hover {
      border-color: #004592;
      color: #004592;
    }
  }
`;

export const StyledTableContainer = styled.div`
  margin-top: 20px;
  .ant-table-thead {
    .ant-table-cell {
      font-family: "BOG 2017 Headline";
      color: #004592;
    }
  }
  .ant-table-cell {
    font-size: 12px;
    color: #7f848a;
  }
  .ant-pagination-item-active a {
    color: #004592;
  }
  .ant-pagination-item-active {
    border-color: #004592;
  }
  .ant-pagination-item a {
    &:hover {
      color: #ccc;
    }
  }
  .ant-pagination-item-active a {
    color: #004592;
  }
  .ant-pagination-item-active {
    border-color: #004592;
  }
  .ant-pagination-item a {
    &:hover {
      color: #ccc;
    }
  }
`;
