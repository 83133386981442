import React, { useEffect } from "react";
import { Radio, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setPhone } from "../../redux/reducers/landing/basketReducer";
import {
  fetchTelephoneNumbers,
  fetchTelephoneNumbersPagination,
} from "../../redux/reducers/common/numbersReducer";
import Filter from "./Filter";
import {
  StyledTableContainer,
} from "./styles/NumbersTableStyles";

const { Option } = Select;
const NumbersTable = React.memo(() => {
  const dispatch = useDispatch();
  const basket = useSelector((state) => state.basket.basket);
  const phone = useSelector((state) => state.basket.phone);
  // ტელეფონის ნომრები
  const { telephoneNumbers } = useSelector((state) => state.telephoneNumbers);

  const phoneNumberHandler = (prop) => {
    dispatch(setPhone(prop));
  };

  // პაგინაცია
  const pagePagination = (value) => {
    dispatch(fetchTelephoneNumbersPagination(value));
  };

  useEffect(() => {
    dispatch(fetchTelephoneNumbers());
  }, []);
  return (
    <div>
      <Filter />
    </div>
  );
});

export default NumbersTable;
