import axios from "axios";

export const fetchContact = () => {
  return function (dispatch, getState) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "address_book_list",
        pars: {
          token: localStorage.getItem("token"),
          account_id: localStorage.getItem("userId"),
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) =>
        dispatch({ type: "CONTACT_LIST", payload: res.data.data })
      );
  };
};

export const createContact = () => {
  return function (dispatch, getState) {
    const { name, surname, nickname, mobile } = getState().userContacts;
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "address_book_add",
        pars: {
          token: localStorage.getItem("token"),
          account_id: localStorage.getItem("userId"),
          name,
          surname,
          nickname,
          mob: mobile,
          category: "1",
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => console.log(res.data));
  };
};

export const fetchContactCategory = () => {
  return function (dispatch, getState) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "address_book_cat_list",
        pars: { token: localStorage.getItem("token") },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        console.log(res.data.data);
        return dispatch({ type: "CONTACT_CATEGORY", payload: res.data.data });
      });
  };
};
export const deleteContact = (deletedItemId) => {
  return function (dispatch, getState) {
    if (deletedItemId.length === 1) {
      axios
        .post(process.env.REACT_APP_API_URL, {
          lang: "ka",
          base: "users",
          function: "address_book_list_del",
          pars: {
            id: deletedItemId[0],
          },
          apikey: "u4AzRTE7Mpp6R4aEzopI",
        })
        .then(
          (res) => res.data.success === "success" && dispatch(fetchContact())
        );
    } else {
      deletedItemId.map((id) =>
        axios
          .post(process.env.REACT_APP_API_URL, {
            lang: "ka",
            base: "users",
            function: "address_book_list_del",
            pars: {
              token: localStorage.getItem("token"),
              account_id: localStorage.getItem("userId"),
              id: id,
            },
            apikey: "u4AzRTE7Mpp6R4aEzopI",
          })
          .then(
            (res) => res.data.success === "success" && dispatch(fetchContact())
          )
      );
    }
  };
};
export const handler = (event, value) => {
  return { type: "HANDLER", payload: value, target: event };
};

export const resetHandler = () => {
  return { type: "RESET_HANDLER" };
};

export const deleteAction = (prop) => ({
  type: "DELETE_ACTION",
  payload: prop,
});

const initialState = {
  name: "",
  surname: "",
  nickname: "",
  mobile: "",
  category: "",
  contactCategory: [],
  list: [],
  listOfDeleteId: [],
};

export const userContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HANDLER":
      return { ...state, [action.target]: action.payload };
    case "CONTACT_CATEGORY":
      return { ...state, contactCategory: action.payload };
    case "CONTACT_LIST":
      return { ...state, list: action.payload };
    case "DELETE_ACTION":
      return {
        ...state,
        listOfDeleteId: state.listOfDeleteId.includes(action.payload)
          ? state.listOfDeleteId.filter((x) => x !== action.payload)
          : [...state.listOfDeleteId, action.payload],
      };
    case "RESET_HANDLER":
      return {
        ...state,
        name: "",
        surname: "",
        nickname: "",
        mobile: "",
        category: "",
      };
    default:
      return state;
  }
};
