import React from "react";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon } from "react-share";
import {EyeOutlined} from '@ant-design/icons'
import { StyledContainer, StyledSocialMedia } from "./styles/NewsContentStyle";
const NewsContent = () => {
  return (
    <StyledContainer>
      <div>
        <img alt="" src="http://localhost:3000/static/media/whitelogo.d8b7726b.png" />
      </div>
      <div>
        <h3>მისალმება მსოფლიოს</h3>
        <p>
          შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული
          ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი
          წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის
          შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი. სწორედ ასეთ
          დროს არის მოსახერხებელი ამ გენერატორით შექმნილი ტექსტის გამოყენება,
          რადგან უბრალოდ „ტექსტი ტექსტი ტექსტი“ ან სხვა გამეორებადი სიტყვების
          ჩაყრა, ხელოვნურ ვიზუალურ სიმეტრიას ქმნის და არაბუნებრივად გამოიყურება.
        </p>
      </div>
      <div className="view">
            <span><EyeOutlined /> 0</span>
        </div>
      <StyledSocialMedia>

        <div>
        <FacebookShareButton
          url={"http://localhost:3001/news/1"}
          quote={"title"}
          hashtag={"#hashtag"}
          description={"aiueo"}
          className="Demo__some-network__share-button"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <LinkedinShareButton
          url={"http://localhost:3001/news/1"}
          quote={"title"}
          hashtag={"#hashtag"}
          description={"aiueo"}
          className="Demo__some-network__share-button"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        </div>
      </StyledSocialMedia>
    </StyledContainer>
  );
};

export default NewsContent;
