import React, { useState } from "react";

import { MenuOutlined, BarsOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { userLogOut } from "../../redux/reducers/landing/userInfoReducer";
import { resetServiceContent } from "../../redux/reducers/dashboard/servicesContentMappings";
import UserNavigationModal from "../../components/Dashboard/UserNavigationModal";

import {
  StyledMenuContainer,
  StyledToggle,
  StyledAvatar,
  StyledTitle,
  StyledDropdown,
} from "./styles/MenuStyle";

import whitelogo from "../../assets/png/whitelogo.png";

const Menuu = () => {
  const dispatch = useDispatch();
  const [showLogo, setShowLogo] = useState(false);
  const [dropDownActive, setDropDownActive] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [toggle, setToggle] = useState(false);
  const user = useSelector((state) => state.userInfo.user);
  const { t, i18n } = useTranslation();

  const menuToggle = () => {
    setToggle(!toggle);
  };

  const dropdownHandler = () => {
    setDropDownActive(!dropDownActive);
  };
  const modalVisibleOnHandler = () => {
    setModalVisible(true);
  };

  const modalVisibleOffHandler = () => {
    setModalVisible(false);
  };

  const userLogOutHandler = () => {
    dispatch(userLogOut());
  };

  const resetServiceContentHandler = () => {
    dispatch(resetServiceContent());
  };

  window.onscroll = function () {
    if (window.pageYOffset > 15) {
      setShowLogo(true);
    } else {
      setShowLogo(false);
    }
  };

  return (
    <StyledMenuContainer toggle={toggle}>
      <UserNavigationModal
        modalVisible={modalVisible}
        modalVisibleOffHandler={modalVisibleOffHandler}
      />
      <nav>
        <div className="logo">
          <a href="#top">
            {" "}
            <img src={whitelogo} className={showLogo ? "active" : "deactive"} />
          </a>
        </div>
        <StyledToggle onClick={menuToggle}>
          <BarsOutlined />
        </StyledToggle>
        <ul>
          <li onClick={resetServiceContentHandler}>
            <Link to="/">{t("Navbar.1")}</Link>
          </li>

          <li onClick={resetServiceContentHandler}>
            <Link to="/internet">{t("Navbar.2")}</Link>
          </li>

          {/**          <li className="dropdown" onClick={resetServiceContentHandler}>
            <Link to="voip" onClick={dropdownHandler}>
            {t("Navbar.3")}
            </Link>
        </li onClick={resetServiceContentHandler}> */}
          {/**          <li onClick={resetServiceContentHandler}>
            <Link to="/television">{t("Navbar.4")}</Link>
          </li> */}
          <li onClick={resetServiceContentHandler}>
            <Link to="/pbx">{t("Navbar.5")}</Link>
          </li>

          {/**          <li>
            <Link to="/a2p">{t("Navbar.6")}</Link>
          </li>
 */}
          {localStorage.getItem("token") ? (
            <StyledTitle>
              <StyledAvatar>
                <img
                  src="https://i.pinimg.com/originals/9f/00/af/9f00af501d2d324ac7f8ebb559fc25dd.png"
                  alt=""
                />
              </StyledAvatar>
              <div style={{ marginLeft: "10px" }}>
                <span
                  onClick={dropdownHandler}
                  className="ant-dropdown-link"
                  style={{ color: "#0e4c90", cursor: "pointer" }}
                >
                  {user.login} <MenuOutlined style={{ paddingLeft: "5px" }} />
                </span>
                <StyledDropdown active={dropDownActive}>
                  <li>
                    <Link to="dashboard">პირადი კაბინეტი</Link>
                  </li>
                  <li>
                    <Link to="/" onClick={userLogOutHandler}>
                      გასვლა
                    </Link>
                  </li>
                </StyledDropdown>
              </div>
            </StyledTitle>
          ) : (
            <li className="cabinet">
              <Link
                to={
                  localStorage.getItem("token") !== null
                    ? "/dashboard"
                    : "/login"
                }
              >
                პირადი კაბინეტი
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </StyledMenuContainer>
  );
};

export default Menuu;
