import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  h2 {
    font-family: "BOG 2017 Headline";
    margin:3px;
    margin-top:15px;
    font-size:16px;
    color:#0e4c90;
  }
  ul {
    list-style:none;
    li {

      padding: 3px;
      margin:3px;
        margin-top:3px;
        transition:300ms;
        border-radius:3px;
      a {
        font-size: 12px;
        transition:300ms;
        color:#004592;
        svg {
            color:#eb0028;
            fill:#eb0028;
            margin-right:5px;
        }
      }

    }
  }
`;
