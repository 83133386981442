import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  margin-top: ${(p) => (p.hasUser ? "10px" : "0px")};
`;
export const StyledNavbar = styled.nav`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: ${(p) => (p.hasUser ? "flex-end" : "space-between;")};
  align-items: center;
  margin-top: 5px;
  @media (max-width: 452px) {
    flex-direction: column;
    margin-top: 30px;
  }
`;
export const StyledUl = styled.ul`
  display: flex;
  align-items: center;
  margin-top: ${(p) => (p.hasNonUser ? "15px" : "0px")};
  @media (max-width: 350px) {
    flex-direction: column;
  }
`;
export const StyledLi = styled.li`
  list-style: none;
  margin: 0px;
  color: #7f848a;
  font-family: "BOG 2017 Headline";
  font-size: 13px;
  margin-right: 15px;
  margin-top: 5px;
  position: relative;
  display: flex;
  align-items: center;
  .cart__counter {
    width: 30px;
    height: 22px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eb0028;
    color: #fff;
    font-weight: 400;
  }
  i {
    color: #eb0028;
    margin-right: 5px;
  }
  .fa-shopping-cart {
    color: #fff;
    margin-right: 0;
  }
  .telephone {
    color: #7f848a;
  }
  a {
    color: #fff;
    font-size: 12px;
    transition: 300ms;
    &:hover {
      color: #ccc;
    }
  }
  a{
    color:#7f848a;
  }
  .ant-switch-checked {
    background-color: #eb0028;
    border-radius: 3px;
  }
  .ant-switch {
    border-radius: 3px;
    background-color: #eb0028;
  }

  .ant-switch-handle::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    content: "";
  }
`;
export const StyledLogo = styled.div`
  img {
    width: 120px;
  }
`;
