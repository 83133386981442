import styled from 'styled-components'

export const StyledContainer = styled.div`
    width:1200px;
    margin:0 auto;
    max-width:100%;
    h2 {
        font-family: "BOG 2017 Headline";

        padding-top:30px;
        padding-bottom:20px;
        font-size:18px;
    }
    h3 {
        font-family: "BOG 2017 Headline";
    }
    li {
        margin-top:8px;
        font-family: "BOG 2018";
            font-size:13px;
        span {
            font-family: "BOG 2018";
            font-size:13px;
        }
    }
    ul{
        padding:20px;
    }
`

