import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  .ant-drawer-title {
    font-family: "BOG 2017 Headline";
  }

  .ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 5px;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
  }
  @media (max-width: 1160px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  .right__side {
    margin-left: 30px;
    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: 0;
    }
  }
  padding-bottom: 100px;
  .ant-menu-root.ant-menu-inline {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 2px solid #f7f7f7;
    border-radius: 3px;
    width: 280px;
  }
  .menu {
    margin-top: 25px;

    max-width: 300px;
    .ant-menu-root.ant-menu-vertical,
    .ant-menu-root.ant-menu-vertical-left,
    .ant-menu-root.ant-menu-vertical-right,
    .ant-menu-root.ant-menu-inline {
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 2px solid #f7f7f7;
      border-radius: 3px;
    }
    li {
      font-size: 13px;
    }
    span {
      font-family: "BOG 2017 Headline";
    }
  }
  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: #004592;
  }
  .ant-menu-submenu-arrow {
    color: #282828;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #f7f7f7;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 3px solid #eb0028;
  }
  .ant-menu-submenu-vertical
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-left
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-left
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::before {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#004592),
      to(#004592)
    );
    background: linear-gradient(to right, #004592, #004592);
  }
  .ant-menu-item-selected {
    color: #004592;
  }
  .ant-menu-submenu-selected {
    color: #004592;
  }
  .ant-menu-submenu
    .ant-menu-submenu-inline
    .ant-menu-submenu-open
    .ant-menu-submenu-selected {
  }
  .ant-menu-submenu-title {
    color: #5f656c;
  }
`;
export const StyledServicesContainer = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-bottom: 50px;
  grid-gap: 15px;
  margin: 0 auto;
  @media (max-width: 967px) {
    width: 100%;
    justify-content: center;
  }
`;

export const StyledPacketList = styled.ul`
  padding-top: 20px;
`;
export const StyledPacket = styled.li`
  span {
    color: #5f656c;
  }
`;

export const StyledBalanceButton = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  font-family: "BOG 2017 Headline";
  color: #004592;
`;

export const StyledPacketController = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;
export const StyledPacketChange = styled.button`
  border: 2px solid #f7f7f7;
  background-color: transparent;
  border-radius: 3px;
  padding: 7px;
  font-family: "BOG 2017 Headline";
  font-size: 10px;
  width: 130px;
  cursor: pointer;
  color: #5f656c;
`;
export const StyledBalanceUp = styled.button`
  border: 1px solid #004592;
  padding: 7px;
  background-color: #004592;
  border-radius: 3px;
  color: #fff;
  font-family: "BOG 2017 Headline";
  font-size: 10px;
  width: 130px;
  cursor: pointer;
`;

export const StyledBar = styled.div`
  width: 885px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #f7f7f7;
  padding: 5px;
  margin-top: 25px;
  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
    font-size: 12px;
  }
  @media (max-width: 1000px) {
    justify-content: space-between;
    margin: 10px;
    width: 95%;
  }
`;

export const StyledButton = styled.button`
  background-color: #004592;
  color: #fff;
  padding: 6px;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  width: 100px;
  font-family: "BOG 2017 Headline";
`;
