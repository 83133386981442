import styled from "styled-components";

export const StyledCarouselContainer = styled.div`
  width: 100%;
  background: rgb(207, 203, 255);
  background: linear-gradient(
    90deg,
    rgba(207, 203, 255, 1) 0%,
    rgba(245, 245, 245, 1) 100%,
    rgba(121, 9, 9, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 12px -2px #ccc;
  @media (max-width:472px) {
    height:500px;
    overflow: hidden;
  }
  .carousel__dot-group {
    position: absolute !important;
    left: 20px !important;
    top: 80px !important;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      left: 20px !important;
      top: 10px !important;
      flex-direction: row;
    }
  }
  .dot___3c3SI {
    width: 5px;
    height: 40px;
    margin-top: 10px;
    border-radius: 3px;
    border: 0;
    transition: 300ms;
    @media (max-width: 768px) {
      width: 40px;
      height: 5px;
      margin-left: 5px;
    }
  }
  .carousel__dot--selected {
    height: 50px;
    transition: 300ms;
    @media (max-width: 768px) {
      width: 50px;
      height: 5px;
    }
  }
  .carousel {
    height: 340px !important;
    @media (max-width: 700px) {
      height: 500px !important;
    }
    @media (max-width: 472px) {
      height: 500px !important;
      .horizontalSlider___281Ls {
        overflow: visible !important;
      }
    }
  }
`;
export const StyledItem = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  height: 340px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-image: url(${(p) => p.image});
  background-size: cover;
  a {
    color: #fff;
  }
  .description {
    width: 50%;
    padding: 20px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.7430322470785189) 100%,
      rgba(245, 245, 245, 0.2528361686471463) 100%
    );
    margin-bottom: 20px;
    margin-right: 20px;
    border-radius: 3px;
    button {
      background: rgb(63, 121, 186);
      background: linear-gradient(
        90deg,
        rgba(63, 121, 186, 1) 0%,
        rgba(0, 69, 146, 1) 100%
      );
      border: 1px solid rgb(63, 121, 186);
      padding: 8px;
      width: 150px;
      border-radius: 3px;
      margin-top: 10px;
      cursor: pointer;
      color: #fff;
      font-family: "BOG 2017 Headline";
      font-size: 12px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    height: 500px;
    align-items: center;
    .description {
      width: 80%;
    }
  }
  @media (max-width: 416px) {
    height: 650px;
  }
  img {
    width: 300px;
    @media (max-width: 700px) {
      width: 300px;
    }
    @media (max-width: 472px) {
      width: 200px;
    }
  }
  h1 {
    font-size: 20px;
    font-family: "BOG 2017 Headline";
    color: #282828;
    @media (max-width: 472px) {
      font-size: 20px;
    }
  }
  h3 {
    font-size: 12px;
    font-weight: 400;
    color: #282828;
    width: 700px;
    @media (max-width: 1200px) {
      width: 100%;
    }
    @media (max-width: 472px) {
      font-size: 12px;
    }
  }
  @media (max-width: 700px) {
    text-align: center;
    height: 500px;
  }
`;
