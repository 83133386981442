import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReloadOutlined } from "@ant-design/icons";
import { Select, Input } from "antd";
import dayjs from "dayjs";
import DayJsUtils from "@date-io/dayjs";
import ka from "dayjs/locale/ka";

import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";

import {
  StyledBar,
  StyledButton,
  StyledTextArea,
  StyledMessagesContainer,
  StyledInboxContainer,
  StyledInboxBar,
  StyledInboxContacts,
  StyledContact,
} from "./Styles/MassiveStyle";

const { Option } = Select;
const { Search } = Input;
const MassiveSms = () => {
  const [selectedDate, setSelectedDate] = useState(
    dayjs(new Date()).format("MM/DD/YYYY")
  );
  const [projectName, setMessage] = useState("");
  const [textAreaLength, setTestAreaLength] = useState(0);
  const [contacts, setContacts] = useState([
    {
      id: 1,
      brandName: "Nike",
      projectName: "ფეხსაცმელი",
      processDate: "2020-09-20",
      processTime: "12:00",
      selected: false,
    },
    {
      id: 2,
      brandName: "Adidas",
      projectName: "ჩანთები",
      processDate: "2020-09-23",
      processTime: "12:00",
      selected: false,
    },
    {
      id: 3,
      brandName: "Extra.ge",
      projectName: "აქცია",
      processDate: "2020-09-25",
      processTime: "12:00",
      selected: false,
    },
    {
      id: 4,
      brandName: "Credline",
      projectName: "აქცია",
      processDate: "2020-09-21",
      processTime: "12:00",
      selected: false,
    },
  ]);

  const { t, i18n } = useTranslation();

  function handleChange(e) {
    setMessage(e.target.value);
    setTestAreaLength(e.target.value.length);
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    document.querySelector(".MuiOutlinedInput-input").style.padding = "8px";
    document.querySelector(".MuiOutlinedInput-input").style.marginTop = "2px";
    document.querySelector(".MuiFormControl-root").style.marginLeft = "10px";
    document.querySelector(".MuiFormControl-root").style.borderRadius = "3px";
  }, []);
  return (
    <div>
      <StyledBar>
        <div className="barx" style={{ display: "flex" }}>
          <div className="marginTop">
            <Select
              defaultValue="საფირმო სახელწოდება"
              style={{ width: "200px" }}
            >
              <Option value="595607900" style={{ fontSize: "12px" }}>
                595607900
              </Option>
              <Option value="578456794" style={{ fontSize: "12px" }}>
                578456794
              </Option>
            </Select>
          </div>
          <div className="marginTop">
            <Input
              placeholder="პროექტის სახელი"
              style={{
                width: 160,
                marginLeft: "10px",
                marginTop: "1px",
                padding: "5px",
              }}
            />
          </div>
          <div className="marginTop">
            <MuiPickersUtilsProvider locale={ka} utils={DayJsUtils}>
              <DateTimePicker
                label="აირჩიეთ თარიღი"
                inputVariant="outlined"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div>
          <StyledButton large={true} red={true} style={{ marginRight: "5px" }}>
            ახალი SMS
          </StyledButton>
          <Select defaultValue="ბაზის არჩევა" style={{ width: "160px" }}>
            <Option value="595607900" style={{ fontSize: "12px" }}>
              AQCIA
            </Option>
            <Option value="578456794" style={{ fontSize: "12px" }}>
              FEXSACMELEBI
            </Option>
          </Select>
        </div>
      </StyledBar>
      <StyledMessagesContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "15px",
          }}
        >
          <form>
            <StyledTextArea
              onChange={handleChange}
              value={projectName}
              placeholder="აკრიბეთ ტექსტი..."
              maxLength="100"
            />
            <div className="character__length">
              <h4>{textAreaLength} / 100</h4>
              <StyledButton large={true}>გაგზავნა</StyledButton>
            </div>
          </form>
        </div>
        <StyledInboxContainer>
          <StyledInboxBar>
            <div className="message">
              <ReloadOutlined style={{ fontSize: "20px", color: "#fff" }} />
              <h4>სტატუსები</h4>
            </div>
            <Search
              placeholder="ძებნა"
              onSearch={(value) => console.log(value)}
              style={{ width: "50%" }}
            />
          </StyledInboxBar>
          <h4 className="freesms">დარჩენილი A2P SMS რაოდენობა: 100</h4>
          <StyledInboxContacts>
            {contacts.map((item) => {
              return (
                <StyledContact key={item.id}>
                  <div className="process__content">
                    <div className="user">
                      <h4>{item.brandName}</h4>
                      <h5>{item.projectName}</h5>
                    </div>
                    <div className="process__date">
                      <h5>
                        <ReloadOutlined
                          spin
                          style={{
                            fontSize: "16px",
                            paddingRight: "5px",
                            color: "#004592",
                          }}
                        />{" "}
                        {item.processDate} / {item.processTime}
                      </h5>
                    </div>
                  </div>
                </StyledContact>
              );
            })}
          </StyledInboxContacts>
        </StyledInboxContainer>
      </StyledMessagesContainer>
      {/* ჯობია ყოველთვის რუთის პირველი კომპონენტი იყოს რადგან მთლიან გვერდზე აკეთებს ოვერლეის */}
    </div>
  );
};
export default MassiveSms;
