import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Redirect
  } from "react-router-dom";
const AuthRoute = ({component: Component,token,tokenTimeOut, ...rest}) => (
    <Route {...rest} render={(props) => (
      localStorage.getItem("token") !== null ? <Redirect to="/dashboard"  />
      : <Component {...props} />
    )}/>
  )

export default AuthRoute
