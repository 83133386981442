import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 50px;
  display:grid;
  grid-template-columns: repeat( auto-fit, minmax(285px, 1fr) );
  grid-gap:20px;
  .title {
    h1 {
      font-size: 16px;
      font-family: "BOG 2017 Headline";
      color: #7f848a;
      svg {
        color: #004592;
      }
    }
  }
`;
export const StyledItem = styled.div`
background:#F2F2F2;
  padding: 10px;
  border-radius: 3px;
  margin-top: 10px;
  width:285px;
  transition:300ms;
  @media (max-width:768px) {
    margin: 0 auto;
  }
  &:hover {
    img {
      transform:scale(1.1);
    }
  }
  h3 {
    font-size: 12px;
    font-family: "BOG 2017 Headline";
    color: #0e4c90;
    margin-top:10px;
    border-bottom:2px solid #0e4c90;
    width:100%;
  }
  p {
    font-size: 12px;
  }
  span {
    font-size: 12px;
  }
  .image {
    width:100%;
    height:150px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:3px;
    border:3px solid #fff;
    overflow: hidden;
    padding:10px;
    img {
      width:200px;
    }
  }
`;


export const StyledContent = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  flex-direction:column;

  img {
    width:100%;
    transition:300ms;
  }
  p {
  }
`

export const StyledBottomContent = styled.div`
    display:flex;
  justify-content:space-between;
  align-items:center;
  padding:5px;
  background-color:#fff;
  span {
    color:#0e4c90;
  }
  button {
    width:80px;
    padding:5px;
    font-size:12px;
    border:0;
    border-radius:3px;
    cursor: pointer;
    font-family: "BOG 2017 Headline";
    background-color:#fff;
    color:#0e4c90;
    transition:300ms;
    &:hover {
      color:#282828;
    }
  }
`
