import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUserOrders,
  userOrderDelete,
} from "../../../../../redux/reducers/dashboard/userOrders";
import { Modal, Popconfirm, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import {
  StyledServicesBox,
  StyledServicesTitle,
  StyledPacketList,
  StyledPacket,
  StyledPacketController,
  StyledBalanceUp,
  StyledButtonDetails,
  StyledPacketInfo,
  StyledPacketDelete,
  StyledContainer,
  StyledHiddenPacket,
} from "./ServiceStyles";

const MyServices = React.memo(() => {
  const [service, setService] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const userOrders = useSelector((state) => state.userOrders.orders);

  function confirm(id) {
    dispatch(userOrderDelete(id));
    message.success("შეკვეთა გაუქმებულია");
  }

  function cancel(e) {
    message.error("მოქმედება გაუქმებულია");
  }

  useEffect(() => {
    dispatch(fetchUserOrders());
  }, [dispatch]);
  return (
    <>

    <StyledContainer>
      {userOrders?.map((item) => {
        return (
       <StyledServicesBox key={item.id}>
            <StyledServicesTitle>
              <img
                src={`https://my.telecom1.ge/img/icons/${item.c_icon}`}
                alt="img"
              />
              <h3>{item.c_display_name}</h3>
              <span style={{ fontSize: "12px", textAlign: "center" }}>
                {item.order_address_details?.address}
              </span>
            </StyledServicesTitle>
            <StyledPacketList>
              <StyledPacket>
                <span>პაკეტი:</span> <span>{item.p_display_name}</span>
              </StyledPacket>
              <StyledPacket>
                <span>ღირებულება:</span> <span>{item.p_base_price}₾</span>
              </StyledPacket>
              <StyledHiddenPacket>
              </StyledHiddenPacket>

              <Popconfirm
                title="გსურთ შეკვეთის გაუქმება?"
                onConfirm={() => confirm(item.id)}
                onCancel={() => cancel()}
                okText="კი"
                cancelText="არა"
                placement="bottom"
              >
                <StyledPacketDelete>
                  <DeleteOutlined />
                </StyledPacketDelete>
              </Popconfirm>
            </StyledPacketList>
            <StyledPacketController>
              <StyledBalanceUp pay={true}>გადახდა</StyledBalanceUp>
              <StyledButtonDetails
                margin={true}
                onClick={() => {
                  setModalVisible(true);
                  setService(item);
                }}
              >
                დეტალები
              </StyledButtonDetails>
            </StyledPacketController>
          </StyledServicesBox>
        );
      })}
      <Modal
        title="დეტალები"
        centered
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <StyledServicesTitle>
          <img
            src={`https://my.telecom1.ge/img/icons/${service.c_icon}`}
            alt="img"
          />
          <h3>{service.c_display_name}</h3>
        </StyledServicesTitle>
        <StyledPacketList>
          <StyledPacketInfo>
            {service &&
              service.product_info?.map((info) => (

                <StyledPacket key={info.name}>
                  <span>{info.name}:</span> <span>{info.value}</span>
                </StyledPacket>
              ))}
            <StyledPacket>
              <span>შეკვეთის თარიღი: </span>{" "}
              <span>{service && service.create_time.slice(0, 10)}</span>
            </StyledPacket>
            {service.c_name === "voip" && (
                  <StyledPacket>
                    <span>ნომერი:</span> <span>+{service.info.voip_number}</span>
                  </StyledPacket>
                )}
                {service.c_name === "voip" && (
                  <StyledPacket>
                    <span>ნომრის ღირებულება:</span> <span>{service.info.price}₾</span>
                  </StyledPacket>
                )}
                {service.c_name === "voip" && (
                  <StyledPacket>
                    <span>მინიმალური ბალანსი:</span>{" "}
                    <span>{service?.p_minimal_deposit}₾</span>
                  </StyledPacket>
                )}
          </StyledPacketInfo>
        </StyledPacketList>
      </Modal>
    </StyledContainer>

    </>
  );
});

export default MyServices;
