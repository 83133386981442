import React from "react";

import { useTranslation } from "react-i18next";

import { Switch } from "antd";
import {Link} from 'react-router-dom'

import {
  StyledContainer,
  StyledNavbar,
  StyledUl,
  StyledLi,
  StyledLogo,
} from "./NavbarStyles";
import GlobalStyle from './globalStyles'

import telecomLogo from "../../assets/png/logo.png";


const Navbar = () => {
  //i18n
  const { t,i18n } = useTranslation();
  function handleLanguageChange(lang) {
    i18n.changeLanguage(lang);
  }

  const switchLang = (checked) => {
    checked
      ? localStorage.setItem("switched", checked)
      : localStorage.removeItem("switched");
    handleLanguageChange(localStorage.getItem("switched") ? "en" : "ka");
  };


  return (
    <StyledContainer>
      <GlobalStyle switched={localStorage.getItem("switched") ? true : false} />
      <StyledNavbar>
        <StyledLogo>
          <Link to="/"><img src={telecomLogo} alt="logo" /></Link>
        </StyledLogo>
        <StyledUl hasNonUser={true}>
          <StyledLi>
            <i className="fas fa-phone"></i>{" "}
            <a href="tel:+(995 32) 3 100100" className="telephone">
             3 100 100
            </a>
          </StyledLi>
          <StyledLi>
          <Link to="/contact">  <i className="fas fa-envelope"></i> {t("Navbar.sideMenu")}</Link>

          </StyledLi>
          <StyledLi>
            <Switch
              checkedChildren="GEO"
              unCheckedChildren="ENG"
              checked={localStorage.getItem("switched")
              && localStorage.getItem("switched")}
            onClick={(checked) => switchLang(checked)}
            />
          </StyledLi>
        </StyledUl>
      </StyledNavbar>
    </StyledContainer>
  );
};

export default Navbar;
