import axios from "axios";

const USER_REQ = "USER_REQ";
const USER_SUC = "USER_SUC";
const USER_ERR = "USER_ERR";

// Actions
export const userRequest = () => {
  return {
    type: USER_REQ,
  };
};

export const userSuccess = (user) => {
  return {
    type: USER_SUC,
    payload: user,
  };
};

export const userError = (error) => {
  return {
    type: USER_ERR,
    payload: error,
  };
};

export const fetchUser = () => {
  return function (dispatch) {
    const token = localStorage.getItem("token");
    dispatch(userRequest());
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "hauth2",
        pars: {
          account_id: localStorage.getItem("userId"),
          login: localStorage.getItem("aname"),
          token: token,
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        const user = res.data;
        if (user.success === "fail") {
          localStorage.removeItem("token");
        }
        dispatch(userSuccess(user));
        })
      .catch((error) => {
        console.log(error);
        dispatch(userError("error"));
      });
  };
};



export const userSignIn = () => {
  return {
    type: "USER_SIGNIN",
  };
};

export const userSignInError = () => {
  return {
    type: "USER_SIGNIN_ERROR",
  };
};

export const fetchUserSignIn = (name, password) => {
  return function (dispatch) {
    dispatch(userSignIn());
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "auth2",
        pars: {
          login: name,
          legalPassword: password,
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        if (res.data.success === "fail") {
          dispatch(userSignInError());
        } else {
          const { account_id } = res.data;
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("aname", name);
          localStorage.setItem("userId", account_id);

          dispatch(fetchUser());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const userLogOut = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "logout",
        pars: {
          account_id: localStorage.getItem("userId"),
          token: localStorage.getItem("token"),
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        if (res.data.success === "success") {
          localStorage.removeItem("token");
          localStorage.removeItem("aname");
          localStorage.removeItem("userId");
        }
        localStorage.removeItem("token");
        localStorage.removeItem("aname");
        localStorage.removeItem("userId");
        dispatch({ type: "USER_LOGOUT" });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const userUpdate = (update) => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "update_account",
        pars: { account_id: localStorage.getItem("userId"), login: localStorage.getItem("aname"), token: localStorage.getItem("token") },
        newpars: update,
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch({ type: "USER_UPDATE" });
        dispatch(fetchUser());
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// state
const initialState = {
  user: {},
  loading: false,
  error: "",
  signInError: false,
};

// reducer
export const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_REQ:
      return { ...state, loading: true };
    case USER_SUC:
      return { ...state, loading: false, user: action.payload, error: "" };
    case USER_ERR:
      return {
        ...state,
        loading: false,
        user: { error: "error" },
        error: action.payload,
      };
    case "USER_SIGNIN":
      return { ...state };
      case "USER_UPDATE":
        return { ...state };
    case "USER_SIGNIN_ERROR":
      return { ...state, signInError: true };
    case "USER_LOGOUT":
      return { user: {}, loading: false, error: "" };
    default:
      return state;
  }
};
