import React from "react";
import Services from "./Services/Services";
const DashboardPage = () => {
  return (
    <div>
      <Services/>
    </div>
  );
};

export default DashboardPage;
