import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import {fetchExtractionData,fetchNumber,fetchFilteredExtractionData} from '../../../../../redux/reducers/dashboard/extraction'
import { DatePicker, Select, Table } from "antd";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "moment/locale/ka";

import {
  StyledBar,
  StyledButton,
  StyledPickerContainer,
  StyledTableContainer,
  StyledPaginationContainer,
} from "./Styles/ExtractionStyles";

const { RangePicker } = DatePicker;
const { Option } = Select;

const columns = [
  {
    title: "ზარი",
    dataIndex: "direction",
    key: (prop) => prop.id,
    render: (prop) => <p key={prop.id}>{prop === "0" ? "შემომავალი" : "გამავალი"}</p>,
  },
  {
    title: "თარიღი / დრო",
    dataIndex: "callstart",
  },
  {
    title: "საიდან",
    dataIndex: "callerid",
  },
  {
    title: "სად",
    dataIndex: "callednum",
  },
  {
    title: "ხანგრძლივობა",
    dataIndex: "billseconds",
    render: (prop) => <p key={prop.id}>{prop}წ</p>
  },
  {
    title: "ღირებულება",
    dataIndex: "debit",
  },
];
const Extraction = () => {
  const dispatch = useDispatch();
  const {data, bonus,spent, numbers, defaultNumber} = useSelector((state) => state.extraction)
  const [selectedNumber,setSelectedNumber] = useState("");
  const [selectedNumberId,setSelectedNumberId] = useState("");
  const [selectedDate,setSelectedDate] = useState({start:"", end:""})
  const [inbound,setInbound] = useState("")

  function handleChange(value, props) {
    setSelectedNumber(value)
    setSelectedNumberId(props.id)
  }

  function onChange(dates, dateStrings) {
    setSelectedDate({...selectedDate, start: dateStrings[0], end:dateStrings[1]})
  }

  const inboundHandler = (value) => {
    setInbound(value)

  }
  const submitFilter = () => {
    dispatch(fetchFilteredExtractionData(defaultNumber.acc_product_id? defaultNumber.acc_product_id : selectedNumberId,selectedDate.start, selectedDate.end,inbound))
  }
  const { t, i18n } = useTranslation();

  const locale = {
    lang: {
      locale: "ka",
      placeholder: "აირჩიეთ თარიღი",
      yearFormat: "YYYY",
      dateFormat: "D M YYYY",
      dayFormat: "D",
      dateTimeFormat: "D M YYYY HH:mm:ss",
    },
  };

  useEffect(() => {
    const nodes = document.querySelectorAll(".ant-picker-input input");
    nodes[0].placeholder = t("RangePickerPlaceholder0");
    nodes[nodes.length - 1].placeholder = t("RangePickerPlaceholder1");
    dispatch(fetchExtractionData())
    dispatch(fetchNumber())
  }, [localStorage.getItem("switched")]);
  return (
    <div>
      <StyledBar>
        <StyledPickerContainer>
          <div className="picker__box">
            <Select
              onChange={handleChange}
              style={{ width: "150px" }}
            >
              {numbers.map((item) => <Option style={{ fontSize: "12px" }} key={item.id} value={item.sip_username} id={item.acc_product_id}>{item.sip_username}</Option>)}
            </Select>
          </div>
          <div className="picker__box">
            <Select
              defaultValue="ყველა"
              onChange={inboundHandler}
              style={{ width: "150px" }}
            >
              <Option value="" style={{ fontSize: "12px" }}>
                ყველა
              </Option>
              <Option value="inbound" style={{ fontSize: "12px" }}>
                შემომავალი
              </Option>
              <Option value="outbound" style={{ fontSize: "12px" }}>
                გამავალი
              </Option>
            </Select>
          </div>
          <div className="picker__box">
            <RangePicker locale={locale} onChange={onChange} />
          </div>
        </StyledPickerContainer>
        <div>
          <StyledButton large={true} onClick={submitFilter}>დადასტურება</StyledButton>
        </div>
      </StyledBar>
      <StyledTableContainer>
        <Table columns={columns} dataSource={data}  size="middle" loading={!data.length} />
      </StyledTableContainer>
      <StyledPaginationContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <h4>გახარჯული თანხა: {spent} ₾</h4>
          </div>
          <div>
            <h4 style={{ marginLeft: "15px" }}>
              ბონუსი შემომავალ წუთზე: {bonus} ₾
            </h4>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: "10px",
            }}
          >
            <h5 style={{ marginTop: "7px", paddingRight: "5px" }}> ექსპორტი</h5>
            <h5
              style={{
                textAlign: "center",
              }}
            >
              <FilePdfOutlined
                style={{ fontSize: "27px", color: "#E1191E", marginTop: "7px" }}
              />
            </h5>
            <h5 style={{ marginLeft: "5px", textAlign: "center" }}>
              <FileExcelOutlined
                style={{ fontSize: "27px", color: "#02723B", marginTop: "7px" }}
              />
            </h5>
          </div>
        </div>
      </StyledPaginationContainer>
    </div>
  );
};

export default Extraction;
