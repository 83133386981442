import React, { useState } from "react";
import { useSelector } from "react-redux";
import { message,Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
// components
import OrderAddModal from "../OrderAddModal/OrderAddModal";
import lg from '../../assets/png/logo.png'

// styles
import {
  StyledTariff,
  StyledOrderButton,
  StyledContainer,
  StyledPacketInfoButton,
  StyledInfoList
} from "./styles/BpxStyle";
const Bpx = () => {
  const [orderAddModalVisible, setOrderAddModalVisible] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [infoModal, setInfoModal] = useState([]);
  const history = useHistory();

  const data = useSelector((state) => state.pbx.data);

  const showModal = () => {
    setOrderAddModalVisible(true);
  };

  const handleOk = () => {
    setOrderAddModalVisible(false);
  };

  const handleCancel = () => {
    setOrderAddModalVisible(false);
    setIsModalVisible(false);
  };

  const orderIdHandler = (id) => {
    if (localStorage.getItem("token")) {
      setOrderId(id);
      showModal();
    } else {
      message.warning("გთხოვთ გაიაროთ ავტორიზაცია ან რეგისტრაცია");
      history.push("/login");
    }
  };

  const showInfoModal = (info) => {
    setIsModalVisible(true);
    setInfoModal(info);
  };


  return (
    <StyledContainer id="pbx">
      <OrderAddModal
        handleOk={handleOk}
        handleCancel={handleCancel}
        orderAddModalVisible={orderAddModalVisible}
        category="pbx"
        order_id={orderId}
      />
      <Modal
        title={`დეტალური ინფორმაცია`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <StyledInfoList>
          <img className="logo" alt="" src={lg} />
          {infoModal.map((info, index) => (
            <li key={index}>
              <span>{info.name}</span> <span>{info.value}</span>
            </li>
          ))}
        </StyledInfoList>
      </Modal>

      <div className="section__title">
        <h1>
      ვირტუალური PBX
        </h1>
      </div>
      <div className="tariff__container">
        {data &&
          data.map((item) => (
            <StyledTariff key={item.id}>
              <div className="head">
                <h3>{item.display_name}</h3>
              </div>

              <div className="price">
                <h3>
                  {item.base_price} <span>₾</span>
                </h3>
                <h4>30 დღე</h4>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StyledPacketInfoButton
                  onClick={() => showInfoModal(item.info)}
                >
                  <InfoCircleOutlined /> <p>დეტალური ინფორმაცია</p>
                </StyledPacketInfoButton>
              </div>

              <div className="order__container">
                {/* პაკეტის არჩევის ღილაკი */}
                <StyledOrderButton
                  onClick={() => {
                    orderIdHandler(item.id);
                  }}
                >
                  შეკვეთა
                </StyledOrderButton>
              </div>
            </StyledTariff>
          ))}
      </div>
    </StyledContainer>
  );
};

export default Bpx;
