import styled from 'styled-components'


export const StyledTableContainer = styled.div`
overflow:hidden;
overflow-y:scroll;
height:195px;
width:740px;
max-width:100%;
margin:0 auto;
border-radius:3px;
padding:10px;

margin-top:15px;
-webkit-box-shadow: 0px 0px 3px 0px #E2E2E2; 
box-shadow: 0px 0px 3px 0px #E2E2E2;
  table {
    border-collapse: collapse;
  width: 100%;
  }
  th {
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    color: #7f848a;
    height:30px;
    padding:5px;
  }
  td {
    height:35px;
    font-size:12px;
    border-bottom:1px solid #e8e8e8;
    font-family: "BOG 2018";
    padding:5px;
  }
  span {
    font-family: "BOG 2018";
    font-size:12px;
  }
  
`;

export const StyledTableHead = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:740px;
max-width:100%;
margin:0 auto;
-webkit-box-shadow: 0px 0px 3px 0px #E2E2E2; 
box-shadow: 0px 0px 3px 0px #E2E2E2;
padding:12px;
h4 {
  font-family: "BOG 2017 Headline";
    font-size: 12px;
    color: #7f848a;
    margin-top:5px;
}
.price {
  margin-left:50px;
}
`
export const StyledTableItems = styled.div`
    display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  flex-direction:column;
`

export const StyledItem = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:100%;
  margin-top:15px;
  -webkit-box-shadow: 0px 0px 3px 0px #E2E2E2; 
box-shadow: 0px 0px 3px 0px #E2E2E2;
padding:12px;
h5 {
  font-family: "BOG 2018";
  font-size:12px;
}
&:first-child {
  margin-top:0;
}
`