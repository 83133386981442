import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import {InfoCircleOutlined } from '@ant-design/icons'
import { useHistory } from "react-router-dom";
import lg from '../../assets/png/logo.png'
// fetch data
import { fetchInternetData } from "../../redux/reducers/landing/internetReducer";
// components
import OrderAddModal from "../OrderAddModal/OrderAddModal";
// styles
import {
  StyledTariff,
  StyledTarrifWrapper,
  StyledOrderButton,
  StyledPacketInfoButton,
  StyledInfoList,
} from "./style/PacketsStyle";

const Packets = () => {
  const [orderAddModalVisible, setOrderAddModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [infoModal,setInfoModal] = useState([])
  const [orderId, setOrderId] = useState(null);
  const history = useHistory();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.internet.data);

  const showModal = () => {
    setOrderAddModalVisible(true);
  };

  const showInfoModal = (info) => {
    setIsModalVisible(true);
    setInfoModal(info)
  };

  const handleOk = () => {
    setOrderAddModalVisible(false);
  };

  const handleCancel = () => {
    setOrderAddModalVisible(false);
    setIsModalVisible(false);
  };

  const orderIdHandler = (id) => {
    if (localStorage.getItem("token")) {
      setOrderId(id);
      showModal();
    } else {
      message.warning("გთხოვთ გაიაროთ ავტორიზაცია ან რეგისტრაცია");
      history.push("/login");
    }
  };

  useEffect(() => {
    dispatch(fetchInternetData());
  }, []);
  return (
    <StyledTarrifWrapper id="tariff">
      <OrderAddModal
        handleOk={handleOk}
        handleCancel={handleCancel}
        orderAddModalVisible={orderAddModalVisible}
        category="internet"
        order_id={orderId}
      />
                   <Modal
                title={`დეტალური ინფორმაცია`}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
              >
                <StyledInfoList>
                  <img className="logo" alt="" src={lg} />
                  {infoModal.map((info, index) => (
                    <li key={index}>
                      <span>{info.name}</span> <span>{info.value}</span>
                    </li>
                  ))}
                </StyledInfoList>
              </Modal>
      <div className="section__title">
        <h1>ინტერნეტი</h1>
      </div>
      <div className="tariff__container">
        {
          data?.map((item) => (
            <StyledTariff key={item.id}>
              <div className="head">
                <h3>{item.display_name}</h3>
              </div>
              
            <div className="price">
              <h3>
                {item.base_price} <span>₾</span>
              </h3>
              <h4>30 დღე</h4>
            </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StyledPacketInfoButton
                  onClick={() => showInfoModal(item.info)}
                  className="info__btn"
                >
             <InfoCircleOutlined /> <p>დეტალური ინფორმაცია</p>
                </StyledPacketInfoButton>
              </div>
  
              <div className="order__container">
                {/* პაკეტის არჩევის ღილაკი */}
                <StyledOrderButton
                  onClick={() => {
                    orderIdHandler(item.id);
                  }}
                  green={item.green}
                  red={item.red}
                >
                  შეკვეთა
                </StyledOrderButton>
              </div>
            </StyledTariff>
          ))}
      </div>
    </StyledTarrifWrapper>
  );
};

export default Packets;
