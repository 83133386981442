import styled from 'styled-components'

export const StyledContainer = styled.div`
    text-align:center;
    margin:0 auto;
    max-width:100%;
    background-color:#F8F9FD;
    width:100%;
    padding:10px;
    transition:300ms;   
    h3 {
        font-family: "BOG 2017 Headline";
        font-size:12px;
        color:#93a3a6;
    }
    span {
        color:#93a3a6;
    }
    p {
        width:1200px;
        max-width:100%;
        margin:0 auto;
        text-align:left;
        font-size:12px;
        display:${p => p.collapse ? "block" : "none"};
    }
`

export const StyledCollapse = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:1200px;
    margin:0 auto;
    max-width:100%;
    cursor:pointer;

`