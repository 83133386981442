import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 885px;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content:flex-start;
  align-items:flex-start;
  margin-top: 15px;
  @media (max-width:900px) {
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
  }
`;

export const StyledAvatar = styled.div`
  margin-top: 10px;
  border: 2px solid #f7f7f7;
  width: 220px;
  height:288px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
  .avatar {
    background: #fff;
    position: relative;
    width: 120px;
    margin-top:10px;
    .upload {
      position: absolute;
      bottom: 5px;
      right: 5px;
      background-color: #004592;
      border: 3px solid #fff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      padding: 1px;
      align-items: center;
      cursor:pointer;
    }
    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
  }
`;
export const StyledInfoContainer = styled.div`
  width: 100%;
  padding: 10px;
  @media (max-width:900px) {
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }
`;

export const StyledPersonInfo = styled.div`
  text-align: center;
  margin-top: 5px;
  h4 {
    margin: 0;
    padding: 0;
    font-family: "BOG 2017 Headline";
    color: #5f656c;
  }
  span {
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: #ccc;
  }
`;

export const StyledOrders = styled.ul`
  width: 100%;
  list-style: none;
  margin-top: 10px;
  .service {
    color:#18C729;
  }
  .order {
    color:#FFD5C9;
  }
  li {
    border-top: 2px solid #f7f7f7;
    width: 100%;
    padding: 10px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:nth-child(2) {
      border-bottom: 2px solid #f7f7f7;
    }
  }
`;


export const StyledInfo = styled.div`
  width:280px;
  display:flex;
  flex-direction:column;
  margin-left:20px;
  margin-bottom:10px;
  position:relative;
  span {
    font-size:12px;
    font-family: "BOG 2017 Headline";
    color: #5f656c;
  }
  input {
    border: 2px solid #f7f7f7;
    width:100%;
    padding:10px;
    border-radius:3px;
    margin-top:3px;
    font-size:13px;
    &::placeholder {
      font-size:13px;
    }
  }
`

export const StyledAccountInfo = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:flex-start;
  flex-wrap:wrap;
  width:600px;
  max-width:100%;
  @media (max-width:900px) {
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
  }
` 

export const StyledEditButton = styled.button`
  position:absolute;
  top:5px;
  right:-5px;
  border-radius:50%;
  width:30px;
  height:30px;
  background:#fff;
  border:1px solid #ccc;
  cursor:pointer;
`