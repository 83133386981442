import styled from 'styled-components'

export const StyledContainer = styled.div`
    padding-top:50px;
    margin-bottom:0px;
    overflow:hidden;
    .hero__title {
        text-align:center;
        font-family: "BOG 2017 Headline";
        padding-top:10px;
        color:#0e4c90;
        font-size:22px;
    }
`

export const StyledServicesWrp = styled.div`
    width:1000px;
    max-width:100%;
    margin:0 auto;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding-top:10px;
    padding-bottom:60px;

    @media (max-width:1022px) {
        flex-wrap:wrap;
        padding:10px;
    }
    @media (max-width:680px) {
        justify-content:center;
    }
`

export const StyledService = styled.div`
width:220px;
height:240px;
border-radius:3px;
-webkit-box-shadow: 0px 0px 5px 0px #C1C1C1; 
box-shadow: 0px 0px 5px 0px #C1C1C1;
text-align:center;
background-color:#fff;
transition:300ms;
cursor:pointer;
@media (max-width:680px) {
    margin:20px;
    }
&:hover {
    transform:scale(1.1);
    filter:brightness(100%);
}
span {
    margin-top:17px;
}   
    button {
        border:0;
        border-radius:3px;
        padding:6px;
        padding-bottom:5px;
        width:110px;
        height:35px;
        margin-top:15px;
        font-family: "BOG 2017 Headline";
        font-size:11px;
        cursor:pointer;
        transition:300ms;
        color:#0e4c90;
        background:#FAFBFF;
        box-shadow: 0px 0px 3px 0px #C1C1C1;
        &:hover {
            background-color:#0e4c90;
            color:#fff;

        }
    }
    .logo {
        width:100%;
        height:95px;
        display:flex;
        justify-content:center;
        align-items:center;
        padding-bottom:20px;
        padding-top:40px;

    }
    .title {
        font-size:20px;
        font-family: "BOG 2017 Headline";
        text-align:center;
        margin-top:25px;
        color:#2b75ae;
    }
    .price__container {
        position:relative;
        h1 {
            background-color:#0e4c90;
            width:100%;
            color:#fff;
            padding:2px;
            margin-top:15px;
        }
        i {
        font-family: "BOG 2018";
        text-align:center;
        display:flex;
        justify-content:center;
        align-items:center;
        color:#5C5866;
        position:absolute;
        left:75px;
        bottom:-25px;
        font-size:10px;
    }
    }
    .price {
        text-align:center;
        font-family: "BOG 2017 Headline";
        margin-top:-10px;
        color:#5C5866;
        .gel {
            font-size:18px;
            font-family: "BOG 2017 Headline";
            color:#fff;
        }
    }
`