import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  background-color: #f8f9fc;
  padding-top: 10px;
  padding-bottom: 20px;
  border-radius: 3px;
  margin-bottom: 25px;
  .title {
    font-family: "BOG 2017 Headline";
    color: #7f848a;
    font-size: 16px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  ul {
    margin: 15px;
  }
`;
