import React, {useState} from "react";
import {DownOutlined} from "@ant-design/icons"
import { StyledContainer,StyledCollapse } from "./styles/DescriptionStyles";
const Description = () => {
  const [collapse,setCollapse] = useState(false);

  const handleCollapse = () => {
    setCollapse(!collapse)
  }
  return (
    <StyledContainer collapse={collapse}>
      <StyledCollapse onClick={handleCollapse}>
        <h3>ზოგადი ინფორმაცია</h3>
        <span><DownOutlined /></span>
      </StyledCollapse>
      <p>
      ინტერაქტიული ტელევიზიის საშუალებით შესაძლებელია მსოფლიოს ნებისმიერი წერიტილიდან მაღალი გარჩევადობის (HD) არხების ყურება უკან გადახვევის ფუნქციით. ინტერაქტიული ტელევიზია მომხმარებელს საშუალებას აძლევს გამოიყენოს YouTube აპლიკაცია, გააზიაროს სასურველი ვიდეო და უყუროს ფილმებს, მოუსმინოს პოპულარულ რადიოსადგურებს, იხილოს ვალუტის გაცვლითი კურსები და ამინდის პროგნოზი      </p>
    </StyledContainer>
  );
};

export default Description;
