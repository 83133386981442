import React, {useState} from "react";
// packages
import { useDispatch } from "react-redux";
import { Input, Select, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// styles
import { StyledContainer } from "./styles/AddressCreate";
// redux action
import {
  createUserAddress,
  userCityAction,
  userAddressAction,
} from "../../../../../redux/reducers/dashboard/userCreateAddress";
// json
import city from "./georgiancity.json";
const { Option } = Select;

const AddressCreate = () => {
  const [state,setState] = useState({city: undefined, address: undefined})
  const dispatch = useDispatch();

  function onChange(value) {
   setState({...state, city:value})
    dispatch(userCityAction(value));
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }
  const userAddressHandler = (data) => {
   setState({...state, address:data})
    dispatch(userAddressAction(data));
  };
  const createAddressHandler = () => {
    if(!state.address || !state.city) {
      message.warning("შეავსეთ სავალდებულო ველი!")
      return null
    } else {
      dispatch(createUserAddress());

    }
  };
  return (
    <StyledContainer>
      <div className="column">
        {" "}
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="აირჩიეთ ქალაქი"
          optionFilterProp="children"
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onSearch={onSearch}
          value={state.city}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {city.map((item) => (
            <Option key={item.id} value={item.city}>
              {item.city}
            </Option>
          ))}
        </Select>
      </div>
      <div className="column">
        {" "}
        <Input
        value={state.address}
          onChange={(e) => userAddressHandler(e.target.value)}
          placeholder="შეიყვანეთ მისამართი"
        />
      </div>
      <div className="column">
        {" "}
        <Button
          onClick={createAddressHandler}
          type="primary"
          icon={<PlusOutlined />}
        >
          დამატება
        </Button>
      </div>
    </StyledContainer>
  );
};

export default AddressCreate;
