import axios from "axios";

const initialState = {
  data: [],
};

const internetDataAction = (data) => {
  return { type: "INTERNET_DATA", payload: data };
};

export const fetchInternetData = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "info",
        function: "list_pakets",
        pars: { product_category_name: "internet", user_can_add: 1 },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch(internetDataAction(res.data.products));
      });
  };
};

export const internetReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INTERNET_DATA":
      return { ...state, data: action.payload };
    default:
      return state
  }
};
