import React from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";

import { StyledContainer } from "./styles/RulesStyle";

const { Panel } = Collapse;

const Faq = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <div>
        <h3 className="title">ხშირად დასმული კითხვები</h3>
      </div>
      <Collapse defaultActiveKey={["1"]} ghost accordion>
        <Panel header={t("faq.quest.1.title")} key="1">
          <p>
          {t("faq.quest.1.1")}
          </p>
          <ul>
            <li>{t("faq.quest.1.2")}</li>
            <li>
            {t("faq.quest.1.3")}
            </li>
          </ul>
        </Panel>
        <Panel header={t("faq.quest.2.title")} key="2">
          <p>
          {t("faq.quest.2.1")}
          </p>
        </Panel>
        <Panel
          header={t("faq.quest.3.title")}
          key="3"
        >
          <p>
          {t("faq.quest.3.1")}
          </p>
        </Panel>
        <Panel header={t("faq.quest.4.title")} key="4">
          <p>
          {t("faq.quest.4.1")}
          </p>
          <ul>
            <li>{t("faq.quest.4.2")}</li>
            <li>{t("faq.quest.4.3")}</li>
            <li>{t("faq.quest.4.4")}</li>
            <li>
            {t("faq.quest.4.5")}
            </li>
            <li>{t("faq.quest.4.6")}</li>
            <li>{t("faq.quest.4.7")}</li>
            <li>
            {t("faq.quest.4.8")}
            </li>
          </ul>
        </Panel>
        <Panel header={t("faq.quest.5.title")} key="5">
          <p>
          {t("faq.quest.5.1")}
          </p>
        </Panel>
        <Panel
          header={t("faq.quest.6.title")}
          key="6"
        >
          <p>
          {t("faq.quest.6.1")}
          </p>
        </Panel>

        <Panel header={t("faq.quest.7.title")} key="8">
          <p>{t("faq.quest.7.1")}</p>
          <ul>
            <li>{t("faq.quest.7.2")}</li>
            <li>{t("faq.quest.7.3")}</li>
            <li>
            {t("faq.quest.7.4")}
            </li>
          </ul>
        </Panel>

        <Panel
          header={t("faq.quest.8.title")}
          key="9"
        >
          <p>
          {t("faq.quest.8.1")}
          </p>
        </Panel>

        <Panel
          header={t("faq.quest.9.title")}
          key="10"
        >
          <p>{t("faq.quest.9.1")}</p>
        </Panel>

        <Panel header={t("faq.quest.10.title")} key="11">
          <ul>
            <li>
            {t("faq.quest.10.1")}
            </li>
            <li>
            {t("faq.quest.10.2")}
            </li>
          </ul>
        </Panel>

        <Panel
          header={t("faq.quest.11.title")}
          key="12"
        >
          <p>
          {t("faq.quest.11.1")}
          </p>
          <ul>
            <li>{t("faq.quest.11.2")}</li>
            <li>{t("faq.quest.11.3")}</li>
            <li>{t("faq.quest.11.4")}</li>
            <li>{t("faq.quest.11.5")}</li>
            <li>{t("faq.quest.11.6")}</li>
          </ul>
        </Panel>


        <Panel
          header={t("faq.quest.12.title")}
          key="15"
        >
          <p>
          {t("faq.quest.12.1")}
          </p>
          <p>    {t("faq.quest.12.2")}</p>
          <ul>
            <li>
            {t("faq.quest.12.3")}
            </li>
            <li>
            {t("faq.quest.12.4")}
            </li>
          </ul>
        </Panel>

        <Panel header={t("faq.quest.13.title")} key="16">
          <p>
          {t("faq.quest.13.1")}
          </p>
        </Panel>
      </Collapse>
    </StyledContainer>
  );
};

export default Faq;
