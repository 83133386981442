import React, { useState, useEffect } from "react";

import axios from "axios";
import {useDispatch} from 'react-redux'
import {fetchUserSignIn} from '../../../redux/reducers/landing/userInfoReducer'
import { useTranslation } from "react-i18next";
import { useGeoField } from "react-geokbd";
import { ak, au } from "../../../ak";

import { Steps, Button, message, Checkbox } from "antd";


import {
  StyledContainer,
  StyledFormContainer,
  DisplayFlex,
  StyledFormTitle,
  StyledFormSwitch,
  StyledFormGroup,
  StyledFormWrapper,
  StyledButton,
  StyledInput,
  StyledLabel,
  StyledPasswordForgot,
  StyledPasswordForgotContainer,
  StyledShowPassword,
} from "./LoginFormStyles";

import { getSteps } from "./getSteps";
import ForgotPassword from "./ForgotPassword";

const { Step } = Steps;

message.config({
  duration: 2,
  maxCount: 1,
});

const LoginForm = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loginForm: {
      name: "",
      password: "",
    },
    registerForm: {
      personalNumber: "",
      firstname: "",
      lastname: "",
      user: "",
      currentUser: "",
      legalUser: "",
      userPassword: "",
      legalPassword: "",
      phoneNumber: "",
      email: "",
      identificationCode: "",
      lls: "",
      legalFormSelected: "",
      responsiblePerson: "",
      day: "",
      month: "",
      year: "",
    },
    forgotPasswordForm: {
      email: "",
      phoneNumber: "",
      password: "",
      repeatPassword: "",
    },
    error: {
      name: false,
      password: false,
      personalNumber: false,
      firstname: false,
      lastname: false,
      user: false,
      currentUser: false,
      legalUser: false,
      userPassword: false,
      legalPassword: false,
      phoneNumber: false,
      email: false,
      identificationCode: false,
      lls: false,
      legalFormSelected: false,
      responsiblePerson: false,
      forgotEmail: false,
      forgotPhoneNumber: false,
      forgotPassword: false,
      forgotRepeatPassword: false,
      day: false,
      month: false,
      year: false,
    },
    checkError: {
      forgotRepeatPassword: false,
    },
  });
  const [checkInfo, setCheckInfo] = useState({
    currentUser: false,
    phoneNumber: false,
    email: false,
    personalNumber: false,
    identificationCode: false,
  });
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = useState(0);
  const [apiPinCode, setApiPinCode] = useState(null);
  const [pinCode, setPinCode] = useState("");
  const [firstNameGeo, setFirstNameGeo] = useGeoField();
  const [nickNameGeo, setNickNameGeo] = useGeoField();
  const [lastNameGeo, setLastNameGeo] = useGeoField();

  // შესვლა ან რეგისტრაცია
  const [switchForm, setSwitchForm] = useState(false);

  // ფორმის არჩევა 1) ფიზიკური პირი 2) იურიდიული პირი
  const [radio, setRadio] = useState({
    value1: "1",
    value2: "2",
  });

  // ფიზიკური ან იურიდიული პირის რეგისტრაციის ფორმის სტეიტი
  const [legalForm, setLegalForm] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [pinNotification, setPinNotification] = useState(false);

  const [forgotPassword, setForgotPassword] = useState(false);


  const error = () => {
    if (forgotPassword === true) {
    } else {
      message.error("გთხოვთ აირჩიოთ ფიზიკური ან იურიდიული პირი!");
    }
  };

  const empty = () => {
    message.error("გთხოვთ შეავსოთ სავალდებულო ველი!");
  };

  // სტეპის ერთი ნაბიჯით წინ
  const next = () => {
    // თუ არჩეული არარის რეგისტრაციის ფორმა

    // state registerForm
    const {
      personalNumber,
      identificationCode,
      lls,
      legalFormSelected,
      currentUser,
      userPassword,
      legalUser,
      legalPassword,
      responsiblePerson,
      phoneNumber,
      email,
      day,
      month,
      year,
    } = state.registerForm;

    if (legalForm === false) {
      error();
    } else {
      if (current === 0) {
        const currentStep = current + 1;
        setCurrent(currentStep);
      } // current 1 ის 1 ფორმა
      else if (current === 1) {
        if (legalForm === 1) {
          if (
            personalNumber.length &&
            firstNameGeo.length &&
            lastNameGeo.length
          ) {
            const currentStep = current + 1;
            setCurrent(currentStep);
          } else {
            empty();
            setState({
              ...state,
              error: {
                ...state.error,
                personalNumber: !personalNumber.length,
                firstname: !firstNameGeo.length,
                lastname: !lastNameGeo.length,
              },
            });
          }
        } // current 1 ის 2 ფორმა
        else if (legalForm === 2) {
          if (identificationCode && lls && legalFormSelected) {
            const currentStep = current + 1;
            setCurrent(currentStep);
          } else {
            empty();
            setState({
              ...state,
              error: {
                ...state.error,
                identificationCode: !identificationCode.length,
                lls: !lls.length,
                legalFormSelected: !legalFormSelected.length,
              },
            });
          }
        }
      } else if (current === 2) {
        // current 2-ეს 1 ფორმა
        if (legalForm === 1) {
          if (currentUser.length && userPassword.length) {
            const currentStep = current + 1;
            setCurrent(currentStep);
          } else {
            empty();
            setState({
              ...state,
              error: {
                ...state.error,
                currentUser: !currentUser.length,
                userPassword: !userPassword.length,
              },
            });
          }
        } // current 2-ეს 2 ფორმა
        else if (legalForm === 2) {
          if (legalUser && legalPassword && responsiblePerson) {
            const currentStep = current + 1;
            setCurrent(currentStep);
          } else {
            empty();
            setState({
              ...state,
              error: {
                ...state.error,
                legalUser: !legalUser.length,
                legalPassword: !legalPassword.length,
                responsiblePerson: !responsiblePerson.length,
              },
            });
          }
        }
      } else if (current === 3) {
        // current 3-ეს 1 ფორმა
        if (legalForm === 1) {
          if (phoneNumber.length && email.length && day && month && year) {
            const currentStep = current + 1;
            setCurrent(currentStep);
          } else {
            empty();
            setState({
              ...state,
              error: {
                ...state.error,
                phoneNumber: !phoneNumber.length,
                email: !email.length,
                day: !day,
                month: !month,
                year: !year,
              },
            });
          }
        } // current 3-ეს 2 ფორმა
        else if (legalForm === 2) {
          if (phoneNumber && email) {
            const currentStep = current + 1;
            setCurrent(currentStep);
          } else {
            empty();
            setState({
              ...state,
              error: {
                ...state.error,
                phoneNumber: !phoneNumber.length,
                email: !email.length,
              },
            });
          }
        }
      }
    }
  };

  // სტეპის ერთი ნაბიჯით უკან
  const prev = () => {
    const currentStep = current - 1;
    setCurrent(currentStep);
    setState({
      ...state,
      error: {
        name: false,
        password: false,
        personalNumber: false,
        fullName: false,
        user: false,
        currentUser: false,
        legalUser: false,
        userPassword: false,
        legalPassword: false,
        phoneNumber: false,
        email: false,
        identificationCode: false,
        lls: false,
        responsiblePerson: false,
        day: false,
        month: false,
        year: false,
      },
    });
  };

  // შესვლის ფორმის საბმითი
  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, password } = state.loginForm;

    // ერორ სტეიტი
    if (name && password.length) {
      dispatch(fetchUserSignIn(name,password))
    } else {
      empty();
      setState({
        ...state,
        error: {
          ...state.error,
          name: !name.length,
          password: !password.length,
        },
      });
    }
  };

  // ავტორიზაციის ინპუტების ჩეინჯერი
  const handleChange = (e) => {
    setState({
      ...state,
      loginForm: {
        ...state.loginForm,
        [e.target.name]: e.target.value,
      },
      error: {
        ...state.error,
        [e.target.name]: false,
      },
    });
  };

  const pinCodeHandler = (e) => {
    setPinCode(e.target.value);
  };

  // ფიზიკური პირის საბმიტი
  const handleOneFormSubmit = (e) => {
    e.preventDefault();
    const {
      personalNumber,
      currentUser,
      userPassword,
      phoneNumber,
      email,
      day,
      month,
      year,
    } = state.registerForm;
    if (pinCode === apiPinCode) {
      axios.post(`${au}`, {
        lang: "ka",
        base: "users",
        function: "register2",
        pars: {
          personalNumber,
          firstname: firstNameGeo,
          lastname: lastNameGeo,
          login: currentUser,
          legalPassword: userPassword,
          phone: phoneNumber,
          email,
          birthday: `${year}-${month}-${day}`,
        },
        apikey: `${ak}`,
        islegal: 0,
      });
      message.success("თქვენ წარმატებით გაიარეთ რეგისტრაცია!");
      setSwitchForm(false);
      setLegalForm(false);
    }
  };

  // იურიდიული პირის საბმიტი
  const handleTwoFormSubmit = (e) => {
    e.preventDefault();
    const {
      identificationCode,
      legalFormSelected,
      lls,
      responsiblePerson,
      legalUser,
      legalPassword,
      phoneNumber,
      email,
    } = state.registerForm;
    if (pinCode === apiPinCode) {
      axios.post(`${au}`, {
        lang: "ka",
        base: "users",
        function: "register2",
        pars: {
          company: lls,
          company_id: identificationCode,
          legalFormSelected,
          firstname: nickNameGeo,
          responsiblePerson,
          login: legalUser,
          legalPassword,
          phone: phoneNumber,
          email,
          is_company: 1,
        },
        apikey: `${ak}`,
        islegal: 1,
      });
      message.success("თქვენ წარმატებით გაიარეთ რეგისტრაცია!");
      setSwitchForm(false);
      setLegalForm(false);
    }
  };

  // რეგისტრაციის ინპუტების ჩეინჯერი
  const handleRegisterChange = (e) => {
    setState({
      ...state,
      registerForm: {
        ...state.registerForm,
        [e.target.name]: e.target.value,
      },
      error: {
        ...state.error,
        [e.target.name]: false,
      },
    });


    function checkCode(func) {
      axios
        .post(au, {
          lang: "ka",
          base: "users",
          function: func,
          pars: { [e.target.name]: e.target.value },
          apikey: ak,
        })
        .then((res) => {
          if (res.data.response.code === 31) {
            setCheckInfo({
              ...checkInfo,
              currentUser: true,
            });
          } else {
            setCheckInfo({
              ...checkInfo,
              currentUser: false,
            });
          }
        });
    }

    if (e.target.name === "currentUser" || e.target.name === "legalUser") {
      checkCode("ch_login");
    }
  };

  // ForgotPassword პაროლის აღდგენის ინპუტების ჩეინჯერი
  const handleForgotPassword = (e) => {
    setState({
      ...state,
      forgotPasswordForm: {
        ...state.forgotPasswordForm,
        [e.target.name]: e.target.value,
      },
    });
  };

  // ForgotPassword პაროლის აღდგენის ფორმის საბმიტი
  const handleOnSubmitForgotPassword = (e) => {
    e.preventDefault();
    const {
      password,
      repeatPassword,
      phoneNumber,
      email,
    } = state.forgotPasswordForm;
    if (forgotPassword) {
      if (password && repeatPassword) {
        // დადასტურებული ინფორმაცია
      } else {
        empty();
        setState({
          ...state,
          error: {
            ...state.error,
            forgotPassword: !password.length,
            forgotRepeatPassword: !repeatPassword.length,
            forgotPhoneNumber:
              pinNotification === 1 ? !phoneNumber.length : false,
            forgotEmail: pinNotification === 2 ? !email.length : false,
          },
        });
      }
    }
    axios
      .post(au, {
        lang: "ka",
        base: "users",
        function: "pass_resset",
        pars: { email, phoneNumber, password },
        apikey: ak,
      })
      .then((res) => {
        res.data.response.code === 26
          ? message.error(res.data.response.reason)
          : message.success(res.data.response.reason);
      });
  };

  // შესვლა ან რეგისტრაცია
  const switchFormHandler = () => {
    setSwitchForm(!switchForm);
  };

  // ფიზიკური ან იურიდიული პირის რეგისტრაციის ფორმის არჩევა
  const radioHandler = (e) => {
    setRadio({
      value1: e.target.value,
    });
    if (e.target.value === "ფიზიკური პირი") {
      setLegalForm(1);
    } else if (e.target.value === "იურიდიული პირი") {
      setLegalForm(2);
    } else if (e.target.value === "მობილურის ნომერი") {
      setPinNotification(1);
    } else if (e.target.value === "ელ.ფოსტა") {
      setPinNotification(2);
    }
  };

  const radioForgotPasswordHandler = (e) => {
    if (e.target.value === "მობილურის ნომერი") {
      setPinNotification(1);
    } else if (e.target.value === "ელ.ფოსტა") {
      setPinNotification(2);
    } else if (e.target.value === "Email") {
      setPinNotification(2);
    } else if (e.target.value === "Mobile Number") {
      setPinNotification(1);
    }
  };

  function onChange(value) {
    setState({
      ...state,
      registerForm: {
        ...state.registerForm,
        legalFormSelected: value,
      },
    });
  }

  const showPasswordHandler = () => setShowPassword(!showPassword);

  // ციფრების ვალიდაცია, ჩეინჯერი მათთვის რომელიც მხოლოდ ციფრები შედის
  const onHandleNumberChange = (e) => {
    let number = e.target.value;
    let regexp = /^[0-9\b]+$/;
    if (number === "" || regexp.test(number)) {
      setState({
        ...state,
        registerForm: {
          ...state.registerForm,
          [e.target.name]: number,
        },
      });
    }

    // ნომრის შემოწმება არის თუ არა დაკავებული
    function checkCode(func) {
      axios
        .post(au, {
          lang: "ka",
          base: "users",
          function: func,
          pars: { [e.target.name]: e.target.value },
          apikey: ak,
        })
        .then((res) => {
          if (res.data.response.code === 30) {
            setCheckInfo({
              ...checkInfo,
              personalNumber: true,
            });
          } else if (res.data.response.code === 29) {
            setCheckInfo({
              ...checkInfo,
              personalNumber: false,
            });
          } else if (res.data.response.code === 26) {
            setCheckInfo({
              ...checkInfo,
              phoneNumber: true,
            });
          } else if (res.data.response.code === 27) {
            setCheckInfo({
              ...checkInfo,
              phoneNumber: false,
            });
          } else if (res.data.response.code === 29) {
            setCheckInfo({
              ...checkInfo,
              identificationCode: false,
            });
          } else if (res.data.response.code === 30) {
            setCheckInfo({
              ...checkInfo,
              identificationCode: true,
            });
          }
        });
    }
    if (e.target.name === "personalNumber" && number.length === 11) {
      const func = "ch_pn";
      checkCode(func);
    } else if (e.target.name === "identificationCode" && number.length === 9) {
      const func = "ch_id";
      checkCode(func);
    } else if (e.target.name === "phoneNumber" && number.length === 9) {
      const func = "ch_phone";
      checkCode(func);
    }
  };

  const forgotePassword = () => {
    setForgotPassword(!forgotPassword);
    setPinNotification(false);
  };

  const forgotPasswordPhoneNumber = state.forgotPasswordForm.phoneNumber;
  const registerPhoneNumber = state.registerForm.phoneNumber;
  // სტეპები
  const steps = getSteps({
    state,
    legalForm,
    radioHandler,
    radio,
    handleRegisterChange,
    onChange,
    showPasswordHandler,
    showPassword,
    onHandleNumberChange,
    pinNotification,
    forgotPassword,
    t,
    i18n,
    pinCodeHandler,
    firstNameGeo,
    setFirstNameGeo,
    setNickNameGeo,
    nickNameGeo,
    lastNameGeo,
    setLastNameGeo,
    checkInfo,
    forgotPasswordPhoneNumber,
    registerPhoneNumber,
    setApiPinCodeFnc
  });

  // timer callback user registration pincode
  function setApiPinCodeFnc(prop) {
    return setApiPinCode(prop)
  }
  useEffect(() => {
    let isMounted = true;
    const { password, repeatPassword } = state.forgotPasswordForm;
    if (password !== repeatPassword) {
      !isMounted && setState({ ...state, checkError: { forgotRepeatPassword: true } });
    } else {
      !isMounted &&  setState({ ...state, checkError: { forgotRepeatPassword: false } });
    }
    return () => {
      isMounted = false;
    }
    // სამართლებრივი ფორმა
  //  axios.post(au,{"lang":"ka","base":"info", "function": "company_form_lst", "pars": {}, "apikey": ak}).then((res) =>console.log(res) )
    
  }, [state.forgotPasswordForm.repeatPassword, pinNotification]);

  return (
    <StyledContainer formSwitch={switchForm} forgotPassword={forgotPassword}>
      <StyledFormContainer>
        <StyledFormTitle>
          {switchForm ? (
            legalForm === false ? (
              "რეგისტრაცია"
            ) : legalForm === 1 ? (
              "ფიზიკური პირის რეგისტრაცია"
            ) : (
              "იურიდიული პირის რეგისტრაცია"
            )
          ) : (
            <p>
              {forgotPassword ? "პაროლის აღდგენა" : t("SignInForm.SignInTitle")}
            </p>
          )}
        </StyledFormTitle>
        {switchForm ? (
          <div className="step__wrapper">
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {current > 0 && <Button onClick={() => prev()}>უკან</Button>}
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => next()}>
                  შემდეგი
                </Button>
              )}

              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={
                    legalForm === 1 ? handleOneFormSubmit : handleTwoFormSubmit
                  }
                >
                  რეგისტრაცია
                </Button>
              )}
            </div>
          </div>
        ) : (
          <form
            onSubmit={
              forgotPassword
                ? handleOnSubmitForgotPassword
                : legalForm === 1
                ? handleOneFormSubmit
                : legalForm === 2
                ? handleTwoFormSubmit
                : handleSubmit
            }
          >
            <StyledFormWrapper>
              {forgotPassword ? (
                <ForgotPassword
                  showPassword={showPassword}
                  showPasswordHandler={showPasswordHandler}
                  radioHandler={radioHandler}
                  radio={radio}
                  pinNotification={pinNotification}
                  state={state}
                  forgotPassword={forgotPassword}
                  radioForgotPasswordHandler={radioForgotPasswordHandler}
                  handleForgotPassword={handleForgotPassword}
                  handleOnSubmitForgotPassword={handleOnSubmitForgotPassword}
                  forgotRepeatPassword={state.checkError.forgotRepeatPassword}
                  registerPhoneNumber={registerPhoneNumber}
                  forgotPasswordPhoneNumber={forgotPasswordPhoneNumber}
                />
              ) : (
                <>
                  {" "}
                  <StyledFormGroup>
                    <StyledLabel>{t("SignInForm.User")}</StyledLabel>
                    <StyledInput
                      name="name"
                      onChange={handleChange}
                      isValid={state.error.name}
                      placeholder={t("SignInForm.UserInput")}
                    />
                  </StyledFormGroup>
                  <StyledFormGroup>
                    <StyledLabel>{t("SignInForm.Password")}</StyledLabel>
                    <StyledInput
                      name="password"
                      onChange={handleChange}
                      isValid={state.error.password}
                      placeholder={t("SignInForm.PasswordInput")}
                      type={showPassword ? "text" : "password"}
                    />
                    <StyledShowPassword
                      type="button"
                      onClick={showPasswordHandler}
                      showPassword={showPassword}
                    >
                      <i className="fas fa-eye"></i>
                    </StyledShowPassword>
                  </StyledFormGroup>
                  <StyledFormGroup>
                    <StyledPasswordForgotContainer>
                      <Checkbox>{t("SignInForm.Remember")}</Checkbox>
                      <StyledPasswordForgot onClick={forgotePassword}>
                        {t("SignInForm.PasswordRecovery")}
                      </StyledPasswordForgot>
                    </StyledPasswordForgotContainer>
                  </StyledFormGroup>
                </>
              )}
            </StyledFormWrapper>
            <StyledFormGroup>
              <DisplayFlex center={true}>
                <StyledButton type="submit">
                  {forgotPassword ? (
                    <>{t("SignInForm.Save")}</>
                  ) : (
                    <>{t("SignInForm.SignInButton")}</>
                  )}
                </StyledButton>
              </DisplayFlex>
            </StyledFormGroup>
          </form>
        )}
        <DisplayFlex center={true}>
          <StyledFormSwitch>
            {switchForm ? (
              <span onClick={switchFormHandler}>
                {t("SignInForm.SignInLink")}
              </span>
            ) : forgotPassword ? (
              <span onClick={forgotePassword}>
                {t("SignInForm.CancelLink")}
              </span>
            ) : (
              <span onClick={switchFormHandler}>
                {t("SignInForm.SignUpLink")}
              </span>
            )}
          </StyledFormSwitch>
        </DisplayFlex>
      </StyledFormContainer>
    </StyledContainer>
  );
};

export default LoginForm;
