import styled from 'styled-components'

export const StyledContainer = styled.div`
    background:#F2F2F2;
    width:1200px;
    margin:0 auto;
    max-width:100%;
    margin-top:50px;
    height:60px;
    padding:5px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    border-radius:3px;
`

export const StyledPageInfo = styled.div`
    padding-top:10px;
    li {
        list-style:none;
        font-family: "BOG 2017 Headline";
        font-size:12px;
        padding-left:5px;
    }
`
export const StyledFilter = styled.div`
    .ant-picker-input {
        input {
            font-size:12px !important;
            &::placeholder {
                font-size:12px !important;
            }
        }
    }
`
