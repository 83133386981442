import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #f8f9fc;
  padding: 10px;
  margin-top:20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    justify-content: center;
  }
  iframe {
    border: 1px solid #ccc;
    border-radius: 3px;

    @media (max-width: 768px) {
      margin-top: 20px;
      width: 100%;
    }
  }
  .ant-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    input {
      width: 300px;
      border-radius: 3px;
      border-color: #ccc;
      padding: 10px;
    }
    textarea {
      width: 500px;
      border-radius: 3px;
      border-color: #ccc;
    }
  }
  .ant-form-item-required {
    font-size: 12px;
    font-family: "BOG 2017 Headline";
    color: #7f848a;
  }
  form {
    margin-left: 30px;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
`;

export const StyledFormGroup = styled.div`
  display: flex;
  flex-direction: column;

  align-items: ${(p) => (p.center ? "center" : "flex-start")};
  margin-top: 25px;
  h2 {
    text-align: center;
    font-family: "BOG 2017 Headline";
    color: #7f848a;
    font-size: 16px;
  }
  &:first-child {
    margin-top: 0;
  }
  button {
    padding: 8px;
    border: 1px solid #0e4c90;
    border-radius: 3px;
    background: #0e4c90;
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    color: #fff;
    width: 100%;
    cursor: pointer;
  }
  label {
    font-size: 12px;
    font-family: "BOG 2017 Headline";
    color: #7f848a;
    padding-bottom: 5px;
  }
  input {
    width: 300px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
    &::placeholder {
      font-size: 12px;
      color: #ccc;
    }
  }
  textarea {
    width: 300px;
    padding: 8px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 3px;
    height: 180px;
    &::placeholder {
      font-size: 12px;
      color: #ccc;
    }
  }
`;
