import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  background: rgb(157,165,171);
background: linear-gradient(90deg, rgba(157,165,171,1) 0%, rgba(95,108,117,1) 100%);
  padding: 20px;
  position:relative;
  .reserved {
    color:#fff;
    font-size:12px;
  }
  .soci {
    background-color:#fff;
    transition:300ms;
    &:hover {
      background:#4267B2;
      i {
        color:#fff;
      }
    }
    i {
      transition:300ms;
      color:#4267B2
    }
  }
`;
export const StyledWrapper = styled.div`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    list-style:none;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:flex-start;
    height:80px;
    @media (max-width:768px) {
    justify-content:center;
    align-items:center;
    text-align:center;
  }
    li {
      color:#fff;
      margin-top:5px;
      cursor:pointer;
      &:hover {
          color:#fff;
        }
      a {
        color:#ededed;
        &:hover {
          color:#fff;
        }
      }
    }
  }
  @media (max-width: 561px) {
    flex-direction: column;
    text-align: center;
  }
  p {
    color: #fff;
    font-family: "BOG 2018";
    font-size: 12px;
    margin: 5px;
    a {
      color:#fff;
    }
  }

`;

export const StyledIconBoxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content:flex-start;
`;
export const StyledIconBox = styled.div`
  border: 1px solid #afbbbd;
  border-radius: ${(p) => (p.rocket ? "50px" : "50%")};
  width: ${(p) => (p.rocket ? "100px" : "35px")};
  height: 35px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
  transition: 300ms;
  background-color:#fff;
  color:#5F6C75;
  transition:300ms;
  p {
    transition:300ms;
  }
  &:hover {
    i {
      color:#fff;
    }
    p {
      color:#fff;
    }
    background-color:#eb0028;
  }
  i {
    font-size: 20px;
    transition:300ms;
  }
  &:hover {
    color: #004592;
  }
  p {
    color:#5F6C75;
  }
`;


export const StyledHotLine = styled.div`
  display:flex;
  align-items:center;
  padding-bottom:15px;
  a {
    color:#fff;
    margin-left:10px;
    margin-right:10px;
    &:first-child {
      margin-left:0;
    }
  }
`

export const StyledUpButton = styled.button`

    border: 1px solid #afbbbd;
  border-radius:50px;
  background:transparent;
  width:35px;
  height: 35px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
  transition: 300ms;
  font-size:12px;
`

export const StyledHotLineContainer = styled.div`
  width:1200px;
  max-width:100%;
  margin:0 auto;
  display:flex;
  justify-content:space-between;
  align-items:center;
  @media (max-width:768px) {
    justify-content:center;
    align-items:center;
    text-align:center;
  }
`