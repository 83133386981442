import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonNext,
  ButtonBack,
  Dot,
  DotGroup,
} from "pure-react-carousel";
import { StyledCarouselContainer, StyledItem } from "./SliderStyles";

const Sliderr = () => {
  const { t } = useTranslation();
  const slides = [
    {
      title: t("generalInformation.internet_title"),
      description:t("generalInformation.internet"),
      img: "https://www.teahub.io/photos/full/326-3268017_2880x1800-quads-id-2-data-id-42765-data.jpg",
      link: "internet",
    },
    {
      title: t("generalInformation.voip_title"),
      description:t("generalInformation.voip"),
      img: "https://mk0h360connecth0vd5d.kinstacdn.com/wp-content/uploads/2019/06/voip-service-and-phone-systems_hero_1.jpg",
      link: "voip",
    },
    {
      title: t("generalInformation.pbx_title"),
      description:t("generalInformation.pbx"),
      img: "https://wallpapercave.com/wp/wp2168806.jpg",
      link: "pbx",
    },
  ];
  return (
    <StyledCarouselContainer>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={500}
        totalSlides={slides.length}
        visibleSlides={1}
        isPlaying={true}
        infinite={true}
      >
        <Slider>
          {slides.map((item, index) => (
            <Slide key={item.title} index={index}>
              <StyledItem image={item.img}>
                <div className="description">
                  <h1>{item.title}</h1>
                  <h3>{item.description}</h3>
                  <button>
                    <Link to={`/${item.link}`}>{ t("generalInformation.see_more")}</Link>
                  </button>
                </div>
              </StyledItem>
            </Slide>
          ))}
        </Slider>
        <DotGroup></DotGroup>
      </CarouselProvider>
    </StyledCarouselContainer>
  );
};

export default Sliderr;
