import styled from 'styled-components'

export const StyledContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    width:1200px;
    max-width:100%;
    margin:0 auto;

`
export const StyledTarrifCalculator = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background: rgb(63,121,186);
background: linear-gradient(90deg, rgba(63,121,186,1) 0%, rgba(0,69,146,1) 100%);
    padding:40px;
    @media (max-width:768px) {
        flex-direction:column;
        text-align:center;
    }
    .ant-select-selection-placeholder {
        font-size:12px !important;
            font-family:"BOG 2018" !important;
        &::placeholder {
            font-size:12px !important;
            font-family:"BOG 2018" !important;
        }
    }
    a {
        font-size:30px;
        color:#fff;
    }
    h4 {
        color:#fff;
        span {
            color:#fff;
        }
    }
    span {
        font-family:"BOG 2017 Headline";
    }
    h4 {
        font-family:"BOG 2018";
        font-size:12px;
     
    }
    .tariff {
        margin-left:15px;
        margin-top:3px;
    }
`

export const StyledTitle = styled.h4`
    font-family: "BOG 2017 Headline";
    font-size: 16px;
    color: #0e4c90;
    padding-top: 20px;
    text-align: center;
    padding-bottom: 20px;
`
