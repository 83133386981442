import React from "react";
import {
  StyledFormButtonGroup,
  StyledContainer,
  StyledFormGroup,
  StyledDatabaseList,
  StyledDatabase,
  StyledHead,
} from "./Styles/DatabaseUploadStyle";
const DatabaseUpload = () => {
  return (
    <StyledContainer>
      <div className="information">
        <h4>
          შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული
          ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი
          წარუდგინონ შემფასებელს.
        </h4>
        <button className="download">ფორმის ჩამოტვირთვა</button>
      </div>
      <div style={{ display: "flex" }} className="bottom">
        <form>
          <div className="form__group">
            <label>ბაზის სახელწოდება</label>
            <input type="text" placeholder="შეიყვანეთ ბაზის სახელწოდება" />
          </div>
          <StyledFormButtonGroup>
            <div className="button-wrapper">
              <span className="label">ფაილის მიმაგრება</span>
              <input
                type="file"
                name="upload"
                id="upload"
                className="upload-box"
                placeholder="ფოტოს ატვირთვა"
                onChange={(e) => console.log(e.target.files[0])}
              />
            </div>
          </StyledFormButtonGroup>
          <div className="form__group">
            <button className="upload">ატვირთვა</button>
          </div>
        </form>
        <StyledDatabaseList>
          <StyledHead>
            <h4>ატვირთული ბაზები</h4>
          </StyledHead>
          <StyledDatabase>
            <h4>სახელწოდება: AQCIA</h4>
          </StyledDatabase>
          <StyledDatabase>
            <h4>სახელწოდება: AQCIA</h4>
          </StyledDatabase>
          <StyledDatabase>
            <h4>სახელწოდება: AQCIA</h4>
          </StyledDatabase>
          <StyledDatabase>
            <h4>სახელწოდება: AQCIA</h4>
          </StyledDatabase>
        </StyledDatabaseList>
      </div>
    </StyledContainer>
  );
};

export default DatabaseUpload;
