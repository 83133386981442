import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 1050px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  flex-wrap:wrap;
  @media (max-width:768px) {
    justify-content:center;
  }
`;

export const StyledItem = styled.div`
  box-shadow: 0 0 20px 0 rgb(100 100 100 / 10%);
  border: 1px solid #eff1f3;
  border-radius: 3px;
  width: 230px;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow:hidden;
  @media (max-width:768px) {
    margin:10px;
  }
  .divider {
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
    margin-top:20px;
    margin-bottom:20px;
    label {
      width:35px;
      height:35px;
      border-radius:50%;
      border:1px solid #e9e9e9;
      display:flex;
    justify-content:center;
    align-items:center;
    background-color:#fff;
    z-index:11;
    span {
      font-size:18px;
    }
    }
    &::before {
      content:"";
      width:230px;
      height:1px;
      background-color:#e9e9e9;
      position:absolute;

      right:-100px;
    }
  }
  .ant-select {
    width: 150px !important;
  }
  label {
    color: #485f71;
    font-family: "BOG 2017 Headline";
    font-size: 12px;
  }
  .sub {
    color: #eb0028;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 15px;
    cursor:pointer;
    animation: colors 3s infinite;
    @keyframes colors {
      0%{
        opacity:0;

      }
      100% {
        opacity:1;
      }
    }
  }
  .first_sub {
    color: #282828;
    font-size: 16px;
    padding-top: 5px;
  }
  .first__quotes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .last__quotes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      color: #485f71;
    font-family: "BOG 2018";
    font-size: 10px;
    }
  }
  .price {
    background-color: #0e4c90;
    width: 100%;
    height:50px;
    padding: 2px;
    padding-top:5px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    display:flex;
    justify-content:center;
    align-items:center;
    span {
      font-size:12px;
      }
    h3 {
      font-family: "BOG 2017 Headline";
      font-size: 26px;
      color:#fff;
      position:relative;
      span {
        position:absolute;
        right:-13px;
        top:7px;
        font-family: "BOG 2018";
      }
    }
    h4 {
      display:flex;
      flex-direction:column;
      margin-top:7px;
      margin-left:3px;
      color:#fff;
      font-size:12px;
    }
  }
  button {
    background-color: #fff;
    border-radius: 3px;
    font-family: "BOG 2017 Headline";
    border: 1px solid #0e4c90;
    color: #0e4c90;
    font-size: 12px;
    width: 100px;
    padding: 5px;
    cursor: pointer;
  }
`;
