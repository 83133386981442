import React, { useEffect } from "react";
// packages
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
// styles
import {
  StyledTitle,
  StyledContainer,
  StyledTarrifCalculator,
} from "./styles/AlphabetStyles";
// files
import pdf from "./uve.pdf";
import {
  fetchCountryItems,
  fetchCountryItemsFilter,
} from "../../redux/reducers/landing/alphabetReducer";

const { Option } = Select;

const Alphabet = () => {
  const dispatch = useDispatch();
  const alphabetItems = useSelector(state => state.alphabet.countryItems)
  const filteredCountryItems = useSelector(state => state.alphabet.filteredCountryItems[0])

  function onChange(id) {
    dispatch(fetchCountryItemsFilter(id));
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  useEffect(() => {
    dispatch(fetchCountryItems());
  }, []);

  return (
    <div style={{ paddingBottom: "30px", paddingTop: "30px" }}>
      <StyledTitle>საერთაშორისო მიმართულებები</StyledTitle>

      <StyledTarrifCalculator>
        <Select
          showSearch
          style={{ width: 250 }}
          placeholder="ძიება ქვეყნების მიხედვით"
          optionFilterProp="children"
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {alphabetItems?.map((item) => <Option key={item.id} value={item.id}>{item.country_ge}</Option>)}
        </Select>
        <div>
          <a>
            <FilePdfOutlined />
          </a>
        </div>
        <div className="tariff">
          <h4>
            <span>ფიქსირებული</span>
            <br /> {filteredCountryItems? filteredCountryItems.fix : 0} ₾ / წთ
          </h4>
        </div>
        <div className="tariff">
          <h4>
            <span>მობილური</span>
            <br /> {filteredCountryItems ? filteredCountryItems.mob : 0} ₾ / წთ
          </h4>
        </div>
        <div className="tariff">
          <h4>
            <span>SMS</span>
            <br /> {filteredCountryItems ? filteredCountryItems.sms : 0} ₾
          </h4>
        </div>
      </StyledTarrifCalculator>
    </div>
  );
};

export default Alphabet;
