export const setServiceContent = (number) => {
    return {type: "SET_CONTENT", payload: number }
}

export const resetServiceContent = () => {
    return {type: "RESET_SERVICE_CONTENT" }
}

const initialState = {
    number: 1
}

export const serviceContentReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_CONTENT":
            return {number: action.payload}
        case "RESET_SERVICE_CONTENT": 
            return {number: 1}
        default:
            return state
    }
}