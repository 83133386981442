import React from 'react'
import {StyledContainer,StyledServicesWrp,StyledService} from './styles/HeroStyles'
import internetImg from '../../assets/png/internet.png'
import phoneImg from '../../assets/png/telephone.png'
import smsImg from '../../assets/png/sms.png'
import televisionImg from '../../assets/png/television.png'
const serviceItems = [
    {
        id:1,
        img: internetImg,
        title: "ინტერნეტი",
        starterPrice: 30,
    },
    {
        id:2,
        img: phoneImg,
        title: "ტელეფონი",
        starterPrice: 45,
    },
    {
        id:3,
        img: televisionImg,
        title: "ტელევიზია",
        starterPrice: 25,
    },
    {
        id:4,
        img: smsImg,
        title: "A2P SMS",
        starterPrice: 55,
    }
]
const Hero = () => {
    return (
        <StyledContainer>
                <StyledServicesWrp>
                {serviceItems.map(item => <StyledService data-aos="fade-up" key={item.id}>
                    <h1 className="title">{item.title}</h1>
                    <div className="price__container">
                        <h1 className="price">{item.starterPrice} <span className="gel">₾</span></h1>
                        <i>საწყისი ფასი</i>
                    </div>
                    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                    <span>30 დღე</span>
                    <button>გაიგე მეტი</button>
                    </div>
                </StyledService>)}
            </StyledServicesWrp>
        </StyledContainer>
    )
}

export default Hero
