import React from "react";
import { useTranslation } from "react-i18next";

import { StyledUl } from "./styles/TermsStyled";
const TermsAndConditionsModal = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledUl>
        <h4>{t("terms.title")}</h4>
      </StyledUl>
      <StyledUl>
        <li>
          <strong>{t("terms.title_1.title")}</strong>
          <p>{t("terms.privacy_policy")}</p>
          <p>
          {t("terms.title_1.1_1")}
          </p>
        </li>
        <li>
          <strong>{t("terms.title_2.title")}</strong>
          <p>{t("terms.title_2.2_1")}</p>
          <p>{t("terms.title_2.2_2")}</p>
          <p>{t("terms.title_2.2_3")}</p>
          <p>{t("terms.title_2.2_4")}</p>
        </li>

        <li>
          <strong><p>{t("terms.title_3.title")}</p></strong>
          <p>{t("terms.title_3.3_1")}</p>
          <p>{t("terms.title_3.3_2")}</p>
          <p>{t("terms.title_3.3_3")}</p>
          <p>{t("terms.title_3.3_4")}</p>
        </li>

        <li>
        <strong><p>{t("terms.title_4.title")}</p></strong>
          <p>{t("terms.title_4.4_1")}</p>
          <p>{t("terms.title_4.4_2")}</p>
          <p>{t("terms.title_4.4_3")}</p>
          <p>{t("terms.title_4.4_4")}</p>
          <p>{t("terms.title_4.4_5")}</p>
        </li>

        <li>
        <strong>{t("terms.title_5.title")}</strong>
        <p>{t("terms.title_5.5_1")}</p>
        <p>{t("terms.title_5.5_2")}</p>
        <p>{t("terms.title_5.5_3")}</p>
        <p>{t("terms.title_5.5_4")}</p>
        <p>{t("terms.title_5.5_4_1")}</p>
        <p>{t("terms.title_5.5_4_2")}</p>
        <p>{t("terms.title_5.5_4_3")}</p>
        <p>{t("terms.title_5.5_4_4")}</p>
        <p>{t("terms.title_5.5_5")}</p>
        <p>{t("terms.title_5.5_5_1")}</p>
        <p>{t("terms.title_5.5_5_2")}</p>
        <p>{t("terms.title_5.5_6")}</p>
        <p>{t("terms.title_5.5_7")}</p>
        <p>{t("terms.title_5.5_8")}</p>
        <p>{t("terms.title_5.5_9")}</p>
        <p>{t("terms.title_5.5_10")}</p>
        <p>{t("terms.title_5.5_11")}</p>
        <p>{t("terms.title_5.5_12")}</p>
        <p>{t("terms.title_5.5_13")}</p>
        <p>{t("terms.title_5.5_14")}</p>
        <p>{t("terms.title_5.5_15")}</p>
        <p>{t("terms.title_5.5_16")}</p>
        <p>{t("terms.title_5.5_17")}</p>
        <p>{t("terms.title_5.5_17_1")}</p>
        <p>{t("terms.title_5.5_17_2")}</p>
        <p>{t("terms.title_5.5_17_3")}</p>
        <p>{t("terms.title_5.5_18")}</p>
        <p>{t("terms.title_5.5_19")}</p>
        <p>{t("terms.title_5.5_20")}</p>
        <p>{t("terms.title_5.5_21")}</p>
        <p>{t("terms.title_5.5_21_1")}</p>
        <p>{t("terms.title_5.5_21_2")}</p>
        <p>{t("terms.title_5.5_21_3")}</p>
        <p>{t("terms.title_5.5_21_4")}</p>
        <p>{t("terms.title_5.5_22")}</p>
        <p>{t("terms.title_5.5_23")}</p>
        <p>{t("terms.title_5.5_24")}</p>
        <p>{t("terms.title_5.5_24_1")}</p>
        <p>{t("terms.title_5.5_24_2")}</p>
        <p>{t("terms.title_5.5_24_3")}</p>
        <p>{t("terms.title_5.5_24_4")}</p>
        </li>
      </StyledUl>
    </div>
  );
};

export default TermsAndConditionsModal;
