import React from "react";
import { useTranslation } from "react-i18next";

import { CaretUpOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  StyledContainer,
  StyledWrapper,
  StyledIconBox,
  StyledIconBoxContainer,
  StyledHotLine,
  StyledUpButton,
  StyledHotLineContainer,
} from "./styles/FooterStyles";
import { ReactComponent as StyledPhoneCall } from "../../assets/svg/phone-call.svg";
const Footer = () => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <StyledWrapper>
        <ul>
          <li>
            <Link to="/about">{t("Footer.about")}</Link>
          </li>
          <li>
            <Link to="/authdescription">{t("Footer.authorization")}</Link>
          </li>
          {/**          <li>
            {" "}
            <Link to="/news">{t("Footer.news")}</Link>
          </li> */}
          <li>
            {" "}
            <Link to="/balance">{t("Footer.fillbalance")}</Link>
          </li>
          {/**          <li>
            <Link to="/vacancy">{t("Footer.vacancy")}</Link>
          </li> */}
        </ul>

        <ul>
          <li>
            {" "}
            <Link to="/terms">{t("Footer.terms&conditions")}</Link>
          </li>
          <li>
            <Link to="/contracts">{t("Footer.contracts")}</Link>
          </li>
        </ul>

        <ul>
          <li>
            {" "}
            <Link to="/contact">{t("Footer.contact")}</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
        </ul>

        <div>
          <StyledIconBoxContainer>
            <StyledIconBox rocket={false} className="soci">
              <a href="https://www.facebook.com/telecom1.ge/" target="_blank">
                {" "}
                <i className="fab fa-facebook-f"></i>
              </a>
            </StyledIconBox>
            <StyledIconBox rocket={false} className="soci">
              <a
                href="https://www.linkedin.com/company/telecom-1-%E1%83%A2%E1%83%94%E1%83%9A%E1%83%94%E1%83%99%E1%83%9D%E1%83%9B-1/"
                target="_blank"
              >
                {" "}
                <i className="fab fa-linkedin-in"></i>
              </a>
            </StyledIconBox>
            <StyledIconBox rocket={true}>
              <i className="fas fa-rocket"></i>
              <p>Speed Test</p>
            </StyledIconBox>
            <StyledUpButton onClick={scrollTop}>
              <CaretUpOutlined style={{ fontSize: "25px" }} />
            </StyledUpButton>
          </StyledIconBoxContainer>
        </div>
      </StyledWrapper>
      <StyledHotLineContainer>
        <div>
          <StyledHotLine>
            <a>{t("Footer.hotline")}</a>
            <StyledPhoneCall style={{ width: "30px", fill: "#fff" }} />

            <a href="tel:+(995 32) 3 100100">
              3 100 100
            </a>
          </StyledHotLine>
          <div>
            <p className="reserved">{t("FooterLabel")}</p>
          </div>
        </div>
      </StyledHotLineContainer>
    </StyledContainer>
  );
};

export default Footer;
