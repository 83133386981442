import React, { useState,useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { message } from "antd";
import {useHistory} from 'react-router-dom'

import {fetchBasicTv,fetchTvCategory} from '../../redux/reducers/landing/iptvReducer'
// components
import OrderAddModal from "../OrderAddModal/OrderAddModal";
import Drawer from "./Drawer";
// styles
import { StyledContainer, StyledItem } from "./styles/DoubleStyles";

const Double = React.memo(() => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [type,setType] = useState("")
  const [orderAddModalVisible, setOrderAddModalVisible] = useState(false);
  const history = useHistory();

  const data = useSelector((state) => state.iptv.data);

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  const showModal = () => {
    setOrderAddModalVisible(true);
  };

  const handleOk = () => {
    setOrderAddModalVisible(false);
  };

  const handleCancel = () => {
    setOrderAddModalVisible(false);
  };

  const orderIdHandler = (id) => {
    if (localStorage.getItem("token")) {
      setOrderId(id);
      showModal();
    } else {
      message.warning("გთხოვთ გაიაროთ ავტორიზაცია ან რეგისტრაცია");
      history.push("/login");
    }
  };
  useEffect(() => {
    dispatch(fetchBasicTv())
    dispatch(fetchTvCategory())
  }, [])
  return (
    <StyledContainer>
      <OrderAddModal
        handleOk={handleOk}
        handleCancel={handleCancel}
        orderAddModalVisible={orderAddModalVisible}
        category="iptv"
        order_id={orderId}
      />

      {data &&
        data.map((item) => (
          <StyledItem key={item.id}>
            <Drawer type={type} setVisible={() => setVisible(false)} visible={visible} />
            <div className="last__quotes">
              <h2>{item.display_name}</h2>
              <label>{item.c_display_name}</label>
              <span>ჯეო ჩენელზ</span>
              <label
                style={{ textAlign: "center" }}
                className="sub"
                onClick={() => {setVisible(true) 
                  setType(item.name)}}
              >
                არხების რაოდენობა
                <br />
                {item.info[1].value}
              </label>
            </div>

            <div className="price">
              <h3>
                {item.base_price} <span>₾</span>
              </h3>
              <h4>30 დღე</h4>
            </div>

            <button onClick={() => {
              orderIdHandler(item.id)
              }}>შეკვეთა</button>
          </StyledItem>
        ))}
    </StyledContainer>
  );
});

export default Double;
