import styled from 'styled-components'

export const StyledInputs = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    .ant-input {
        margin:5px !important;
        
    }
    .ant-select {
        margin:5px !important;
    }
`
export const StyledOrderButton = styled.button`
    background-color:#004592;
    border:1px solid #004592;
    border-radius:3px;
    padding:3px;
    margin:5px;
    width:130px;
    color:#fff;
    height:35px;
    font-family: "BOG 2017 Headline";
    font-size:12px;
    cursor:pointer;
`   

export const StyledContainer = styled.div`
    display:flex;
    align-items:center;
    width:100%;
    margin:5px;
    @media (max-width:1160px) {
        flex-direction:column;
        justify-content:center;
    }
    .column {
        margin-right:10px;
    }
    .ant-input {
        &::placeholder {
            font-size:12px;
        }
    }
    .ant-btn {
        span {
            font-size:12px;
        }
    }
    .ant-btn-primary {
    color: #fff;
    background: #004592 !important;
    border-color: #004592 !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
`