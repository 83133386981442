import styled from 'styled-components'

export const StyledContainer = styled.div`
    width:1200px;
    max-width:100%;
    margin:0 auto;
    padding-bottom:15px;
    .title {
        font-family: "BOG 2017 Headline";
        color:#004592;
        margin-top:15px;
        text-align:right;
        @media (max-width:768px) {
        text-align:center;
        margin:10px;
    }
            }
    ul {
        margin:30px;
        margin-top:0;
        margin-bottom:0;
        li {
            font-size:12px;
        }
    }
    .list {
        margin-top:20px;
        @media (max-width:768px) {
  
  margin:10px;
}
    }
`
export const Left = styled.div`
    text-align:right;
    h3 {
        font-size:14px;
    }

    h4 {
        font-size:14px;
    }
    @media (max-width:768px) {
        text-align:center;
        margin:10px;
    }
`
export const StyledTitle=  styled.h3`
    text-align:center;
    font-family: "BOG 2017 Headline";
    margin-top:15px;

    `