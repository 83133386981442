import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Modal } from "antd";
import {
  StyledServicesBox,
  StyledServicesTitle,
  StyledPacketList,
  StyledPacket,
  StyledBalanceButton,
  StyledPacketController,
  StyledPacketChange,
  StyledBalanceUp,
  StyledButtonDetails,
  StyledModalContainer,
  StyledDetailPacketList,
  StyledModalPacket
} from "./ServiceStyles";
import noserviceImage from '../../../../../assets/png/noservice.png'
import { fetchUserServices } from "../../../../../redux/reducers/dashboard/userServices";
import { fetchUser } from "../../../../../redux/reducers/landing/userInfoReducer";

const Service = () => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [service, setService] = useState("");

  const myservices = useSelector((state) => state.userServices.services);

  useEffect(() => {
    dispatch(fetchUser)
    dispatch(fetchUserServices());
  }, []);

  return (
    <>
      {myservices
        ? myservices.map((item) => (
            <StyledServicesBox key={item.id}>
              <StyledServicesTitle>
                <img
                  src={`https://my.telecom1.ge/img/icons/${item.c_icon}`}
                  alt="img"
                />
                <h3>{item.c_display_name}</h3>
              </StyledServicesTitle>

              <StyledPacketList>
                <StyledPacket>
                  <span>პაკეტი:</span> <span>{item.p_display_name}</span>
                </StyledPacket>
                {item.phone_number && (
                  <StyledPacket>
                    <span>ნომერი:</span>{" "}
                    <span>+{item.phone_number}</span>
                  </StyledPacket>
                )}

                <StyledPacket>
                  <span>ჩართვის თარიღი:</span>
                  <span>{item.start_time}</span>
                </StyledPacket>
                <StyledPacket>
                  <span>ბოლო გადახდა:</span>
                  <span>{item.last_refill}</span>
                </StyledPacket>
                <StyledPacket>
                  <span>გადახდის მეთოდი:</span>
                  <span>{item.post_pay ? "საკრედიტო" : "საავანსო" }</span>
                </StyledPacket>
                  {item.limit &&        <StyledPacket>
                  <span>ლიმიტი:</span>
                  <span>{item.post_limit}</span>
                </StyledPacket>}
                {item.limit && (
                  <StyledPacket>
                    <span>ლიმიტი:</span>
                    <span onClick={() => console.log(item.id)}>
                      {item.limit}
                    </span>
                  </StyledPacket>
                )}
                <StyledPacket>
                  <span>ბალანსი:</span>
                  <StyledBalanceButton>{item.balance} ₾</StyledBalanceButton>
                </StyledPacket>
                <StyledPacketController>
                  <StyledPacketChange>პაკეტის შეცვლა</StyledPacketChange>
                  <StyledBalanceUp pay={false}>ბალანსის შევსება</StyledBalanceUp>
                </StyledPacketController>
                <StyledButtonDetails
                  onClick={() => {
                    setModalVisible(true);
                    setService(item);
                  }}
                >
                  დეტალები
                </StyledButtonDetails>
              </StyledPacketList>
            </StyledServicesBox>
          ))
        : <img style={{width:"320px",marginLeft:"200px",marginTop:"50px"}} src={noserviceImage}  alt=""/>}
      <StyledModalContainer>
        <Modal
          title={<h4>დეტალები</h4>}
          centered
          visible={modalVisible}
          footer={null}
          onCancel={() => setModalVisible(false)}
          width={400}
        >
          <StyledServicesTitle>
            <img
              src={`https://my.telecom1.ge/img/icons/${service.c_icon}`}
              alt="img"
            />
            <h3>{service.c_display_name}</h3>
          </StyledServicesTitle>
          <StyledDetailPacketList>
            <div>
            {service.product_details?.map((detail) => (
              <StyledModalPacket>
                <span>{detail.name}</span>
                <span>{detail.value}</span>
              </StyledModalPacket>
            ))}
            </div>
          </StyledDetailPacketList>
        </Modal>
      </StyledModalContainer>
    </>
  );
};

export default Service;
