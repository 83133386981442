import React from "react";
import { StyledContainer } from "./styles/CategorysStyles";
const Categorys = () => {
  return (
    <StyledContainer>
      <ul>
        <li>ყველა ვაკანსია</li>
        <li>ვებ დეველოპერი</li>
        <li>მენეჯერი</li>
      </ul>
    </StyledContainer>
  );
};

export default Categorys;
