// types
const SET_PHONE = "SET_PHONE";
const SET_VISIBLE = "SET_VISIBLE";
const SET_BASKET = "SET_BASKET";
const REMOVE_ITEM_BASKET = "REMOVE_ITEM_BASKET";
const SET_BASKET_STORAGED = "SET_BASKET_STORAGED";
const SET_ITEM_INFO = "SET_ITEM_INFO";

// Initial State
const initialState = {
  basket: [],
  phone: {},
  visible: false,
};


// actions
export const setPhone = (prop) => {
  return { type: SET_PHONE, payload: prop };
};

export const setBasket = (prop) => (dispatch, getState) => {
    dispatch({
    type: SET_BASKET,
    payload: prop,
  })
};

export const setBasketStoraged = (prop) => {
  return { type: SET_BASKET_STORAGED, payload: prop };
};

export const removeItemBasket = (prop) => {
  return { type: REMOVE_ITEM_BASKET, payload: prop };
};

export const setVisible = (prop) => {
  return { type: SET_VISIBLE, payload: prop };
};

export const setItemInfo = (city, address) => {
  return {type: SET_ITEM_INFO, payload:{city,address}}
}

// reducer
export const basketReducer = (state = initialState, action) => {
  switch (action.type) {
    // ტელეფონის ობიექტი
    case SET_PHONE:
      return { ...state, phone: action.payload };
    // კალათაში ჩამატება (ავტომატურად მოყვება არჩეული ტელეფონის ობიექტი) + ორდერი
    case SET_BASKET:
      return { ...state, basket: [...state.basket, action.payload] };
    // LocalStorage Controll
    case SET_BASKET_STORAGED:
      return { ...state, basket: action.payload };
    // ნივთის წაშლა კალათიდან
    case REMOVE_ITEM_BASKET:
      return {
        ...state,
        basket: state.basket.filter((item) => item.phone.id !== action.payload),
      };
      case SET_ITEM_INFO:
        return {...state, basket: state.basket.filter((item) => console.log(item))}
    case SET_VISIBLE:
      return { ...state, visible: action.payload };
    default:
      return state;
  }
};


